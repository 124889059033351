import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { MAX_WORDS_BACKGROUND_QUESTIONS } from '../../../Constants/profile';
import { useUpdateBackgroundAnswer } from '../../../Hooks/Profile/useProfile';
import { getWordCount } from '../../../Utils/common';
import AutoSave from '../../../lib/UI-Components/AutoSave';

interface IFirstQuestionProps {
  question: any;
  answer: any;
}

export const FirstQuestion: React.FC<IFirstQuestionProps> = ({ question, answer }) => {
  const [heading = '', text = ''] = (question?.question ?? '').split(':');
  const [inputValue, setInputValue] = useState(answer?.answer || '');
  const [isError, setIsError] = useState(false);
  const [wordCount, setWordCount] = useState(getWordCount(answer?.answer || ''));

  const { mutate, isPending } = useUpdateBackgroundAnswer();

  const debouncedSave = useMemo(
    () =>
      debounce((value) => {
        mutate({
          payload: {
            answers: [
              {
                content: value,
                question_id: question?.id,
              },
            ],
          },
        });
      }, 1000),
    [mutate, question?.id]
  );

  const handleInputChange = (e) => {
    const numOfWords = getWordCount(e.target.value);
    const isLimitCrossed = numOfWords > MAX_WORDS_BACKGROUND_QUESTIONS;

    if (isLimitCrossed) {
      setIsError(true);
    } else {
      setWordCount(numOfWords);
      setInputValue(e.target.value);

      if (isError) {
        setIsError(false);
      }

      debouncedSave(e.target.value);
    }
  };

  useEffect(() => {
    setInputValue(answer?.answer);
    setWordCount(getWordCount(answer?.answer));
  }, [answer]);

  return (
    <VStack width={'100%'}>
      <Text alignSelf='start' fontWeight='bold' color='primary'>
        {heading}
      </Text>
      <Text alignSelf='start' fontWeight='regular' color='gray5'>
        {text}
      </Text>
      <Input
        autoComplete='off'
        name='first-question'
        placeholder={question?.placeholder}
        resize='none'
        bg='foreground'
        type='text'
        focusBorderColor='primary'
        value={inputValue}
        onChange={handleInputChange}
        sx={{
          '::placeholder': {
            color: 'gray2',
          },
        }}
      />
      <HStack width={'100%'} justifyContent={'space-between'} mt={-2} paddingX={1}>
        <Text w='fit-content' fontSize='12px' fontStyle='italic' color={isError ? 'red' : 'gray5'} textAlign='start'>
          {isError || (!inputValue && !inputValue?.trim().replace(/\s+/g, ''))
            ? `Maximum ${MAX_WORDS_BACKGROUND_QUESTIONS} words`
            : `${wordCount} words`}
        </Text>
        <AutoSave isSaving={isPending} />
      </HStack>
    </VStack>
  );
};

export default FirstQuestion;
