import { HStack, Text, Tooltip, chakra } from '@chakra-ui/react';
import React from 'react'; // @ts-ignore
import { ReactComponent as _BranchIcon } from '../../../Assets/icons/branch-icon.svg';
const BranchIcon = chakra(_BranchIcon);

interface ISimilarPromptProps {
  count: number;
}

const SimilarPrompt: React.FC<ISimilarPromptProps> = ({ count }) => (
  <Tooltip label='View similar essay prompts' hasArrow>
    <HStack backgroundColor={'#E0ECE9'} borderRadius={'39px'} paddingX={2} paddingY={1} spacing={1}>
      <Text color={'primary'} fontSize={['xs', 'xs', 'xs', 'md']} fontWeight={400}>
        {count}
      </Text>
      <BranchIcon boxSize={['12px', '12px', '12px', '20px']} />
    </HStack>
  </Tooltip>
);

export default SimilarPrompt;
