import { Button, Image, Text, VStack } from '@chakra-ui/react'; // @ts-ignore
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../Constants/constants';
import EmptyPlannerViewImage from './../../../Assets/images/empty-planner-view.svg';

const EmptyPlannerView: React.FC = () => {
  const navigate = useNavigate();
  return (
    <VStack
      width={'100%'}
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={10}
      className='planner-smart-plan'
    >
      <VStack spacing={2}>
        <Image src={EmptyPlannerViewImage} alt='Empty Planner' />
        <Text color={'gray6'} fontWeight={400}>
          Add in a few colleges to your list to generate a plan
        </Text>
      </VStack>
      <Button variant={'primaryLight'} fontSize={'lg'} padding={6} onClick={() => navigate(Routes.Colleges)}>
        Go To Colleges
      </Button>
    </VStack>
  );
};

export default EmptyPlannerView;
