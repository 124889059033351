/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import axios from 'axios';

class Essays {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async createNewEssay(file: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/essay/new`, file);

      return response.data;
    } catch (error) {
      console.error('Error creating new essay:', error);
    }
  }

  async getEssays(id: any) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/essay/get/${id}`);

      return response.data?.essays;
    } catch (error: any) {
      console.error('Error fetching essays:', error.message);
      throw error;
    }
  }

  async getUserCollegesWithSupplements() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/essay/user/colleges`);

      return response.data?.colleges;
    } catch (error: any) {
      console.error('Error fetching user colleges with supplements:', error.message);
      throw error;
    }
  }

  async saveEssayDraft(content: any, essayId: any) {
    try {
      const response = await fetch(`${this.apiBaseUrl}/essay/save-draft/${essayId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          draft: content,
        }),
      });

      await response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async saveEssay(file: any, content: any) {
    await axios.post(`${this.apiBaseUrl}/essay/save/${file?.id}`, {
      essay: content,
    });
  }

  async renameEssay(file: any, editValue: any) {
    return await axios.post(`${this.apiBaseUrl}/essay/rename/${file?.id}`, {
      name: editValue,
    });
  }

  async deleteEssay(file: any) {
    return await axios.post(`${this.apiBaseUrl}/essay/delete/${file?.id}`);
  }

  async reviewEssay(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/essay/score`, data, {
        withCredentials: true,
      });

      return response?.data;
    } catch (error) {
      console.error('Error reviewing essay:', error);
    }
  }

  async scoreEssay(vals: any, setEssayReviewsUsed: any) {
    return fetch(`${this.apiBaseUrl}/essay/score`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        console.error(err);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return res.json();
      })
      .then((data) => {
        setEssayReviewsUsed(data.reviews_used);
        return data;
      });
  }

  async reviewEssayOverall(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/essay/overallReview`, data, {
        withCredentials: true,
      });

      return response?.data;
    } catch (error) {
      console.error('Error reviewing essay:', error);
    }
  }

  async scoreEssayOverall(vals: any, setEssayReviewsUsed: any) {
    return fetch(`${this.apiBaseUrl}/essay/overallReview`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        console.error(err);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }

        return res.json();
      })
      .then((data) => {
        setEssayReviewsUsed(data.reviews_used);
        return data;
      });
  }

  async scoreEssaySentences(vals: any, setEssayReviewsUsed: any) {
    return fetch(`${this.apiBaseUrl}/essay/sentenceReview`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        console.error('not good', err);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }

        return res.json();
      })
      .then((data) => {
        setEssayReviewsUsed(data.reviews_used);
        return data;
      });
  }

  async reviewSentences(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/essay/sentenceReview`, data, {
        withCredentials: true,
      });

      return response?.data;
    } catch (error) {
      console.error('Error reviewing essay:', error);
    }
  }

  async updatePlan(fileId: number, data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/essay/updateplan/${fileId}`, data, {
        withCredentials: true,
      });

      return response?.data;
    } catch (error) {
      console.error('Error updating plan:', error);
    }
  }

  async updateTopic(fileId: number, topic: string) {
    try {
      const response = await axios.patch(
        `${this.apiBaseUrl}/essay/updatetopic/${fileId}`,
        { topic },
        { withCredentials: true }
      );

      return response?.data;
    } catch (error) {
      console.error('Error updating plan:', error);
    }
  }

  async FreeWritePromptEdit(editedPrompt: any, id: number) {
    const response = await axios.post(`${this.apiBaseUrl}/essay/prompt/`, {
      essay_id: id,
      prompt: editedPrompt,
    });

    return response;
  }

  async chatSession(userResponse: string, userId: any) {
    const url = `${this.apiBaseUrl}/essay/topicSession`;

    const response = await axios.post(url, {
      user_id: userId,
      message: userResponse,
    });

    return response;
  }

  async backgroundChatSession(userResponse: string, userId: any) {
    const url = `${this.apiBaseUrl}/essay/backgroundTopicSession`;

    const response = await axios.post(url, {
      user_id: userId,
      message: userResponse,
    });

    return response;
  }

  async chatTopic(userResponse: string, userId: any) {
    const url = `${this.apiBaseUrl}/essay/topicChat`;

    const response = await axios.post(url, {
      user_id: userId,
      message: userResponse,
    });

    return response;
  }

  async backgroundChatTopic(userResponse: string, userId: any) {
    const url = `${this.apiBaseUrl}/essay/backgroundTopicChat`;

    const response = await axios.post(url, {
      user_id: userId,
      message: userResponse,
    });

    return response;
  }

  async sendChatHistory(history: any) {
    const url = `${this.apiBaseUrl}/essay/getChatHistory`;

    const response = await axios
      .post(url, {
        historyArray: history,
      })
      .catch((err) => {
        console.error('Error sending chat history', err);
      });

    return response;
  }

  async useTool(toolName: string, data: any) {
    const url = `${this.apiBaseUrl}/essay/edit/${toolName}`;

    try {
      const response = await axios.post(url, data);

      return response.data;
    } catch (err) {
      console.error('Error sending text to AI', err);
    }
  }

  async sendUserInput(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/essay/askKollegio`, {
        essay_object: data?.essay_object,
        ask_conversation: data?.ask_conversation,
      });

      return response.data;
    } catch (err) {
      console.error('Error sending user input', err);
      throw err;
    }
  }
}

const essaysService = new Essays(`${process.env.REACT_APP_BASE_URL}`);
export default essaysService;
