import axios from 'axios';

class FirstUX {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async addIntakeYear(year: string) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/user/updateIntakeYear`, { intakeYear: year || 'other' });

      return response.data;
    } catch (error: any) {
      console.error('Error updating intake year:', error.message);
      return error;
    }
  }

  async addFieldOfStudy(field: string) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/questions/fieldOfStudy`, { fieldOfStudy: field });

      return response.data;
    } catch (error: any) {
      console.error('Error updating field of study:', error.message);
      return error;
    }
  }
}
const firstUXService = new FirstUX(`${process.env.REACT_APP_BASE_URL}`);
export default firstUXService;
