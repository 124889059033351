export enum TASK_COMPLETION_STATES {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export enum ESSAY_DRAFT_DROPDOWN_OPTIONS {
  NOT_STARTED_YET = 'Not Started Yet',
  DRAFT_1_COMPLETED = 'Draft 1 Completed',
  DRAFT_2_COMPLETED = 'Draft 2 Completed',
  COMPLETED = 'Completed',
}

export enum ESSAY_DRAFT_OPTIONS {
  NOT_STARTED_YET = 'NOT_STARTED_YET',
  DRAFT_1_COMPLETED = 'DRAFT_1_COMPLETED',
  DRAFT_2_COMPLETED = 'DRAFT_2_COMPLETED',
  COMPLETED = 'COMPLETED',
}
export enum PREFERRED_DEADLINE_OPTIONS {
  ed = 'Early Decision',
  rd = 'Regular Decision',
  rea = 'Early Restrictive Action',
}

export enum PREFERRED_DEADLINE {
  ed = 'ed_deadline',
  rd = 'rd_deadline',
  rea = 'rea_deadline',
}

export enum ESSAY_DRAFT_OPTIONS_COLOR {
  NOT_STARTED_YET = 'gray6',
  DRAFT_1_COMPLETED = '#FBAA33',
  DRAFT_2_COMPLETED = '#FBAA33',
  COMPLETED = 'primary',
}

export enum MULTI_PROMPT_TYPES {
  PREFACE = 'PREFACE',
  PROMPT = 'PROMPT',
}

export enum TASK_TYPES {
  COLLEGE = 'COLLEGE',
  ESSAY = 'ESSAY',
}
