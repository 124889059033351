import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { INTAKE_PROMPTS } from '../../Constants/first_ux';

interface Section1Props {
  intake: string;
  setIntake: any;
}

const Section1 = ({ intake, setIntake }: Section1Props) => (
  <VStack width='100%' alignItems='start' spacing={4}>
    <Text color='primary' textAlign='start' fontSize='xl' fontWeight='semibold'>
      When are you planning on starting college
    </Text>
    <HStack width='100%' justifyContent='center'>
      {INTAKE_PROMPTS?.map((year, index) => (
        <Button
          key={index}
          onClick={() => setIntake(year?.value)}
          isActive={intake === year?.value}
          display='flex'
          flex='1 0 0'
          fontSize={['xs', 'xs', 'md', 'md', 'md']}
          variant={'first_ux'}
        >
          {year?.name}
        </Button>
      ))}
    </HStack>
  </VStack>
);

export default Section1;
