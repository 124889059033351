import { Button, Hide, HStack, Show, Spinner, Text, Textarea, VStack } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { alertAtom } from '../../../Atoms/alertAtom';
import { ALERT_TYPES } from '../../../Constants/constants';
import { MAX_WORDS_BACKGROUND_QUESTIONS } from '../../../Constants/profile';
import { useUpdateBackgroundAnswer } from '../../../Hooks/Profile/useProfile';
import DrawerComponent from '../../../lib/UI-Components/Drawer';
import CustomModal from '../../../lib/UI-Components/Modal';
import { getWordCount } from './../../../Utils/common';

interface Question {
  answer: any;
  id: any;
  question?: string;
  placeholder?: string;
}

interface Group {
  heading?: string;
  questions?: Question[];
}

interface BackgroundInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  group?: Group;
}

const BackgroundInfoModal: React.FC<BackgroundInfoModalProps> = ({ isOpen, onClose, group }) => {
  const [updatedAnswers, setUpdatedAnswers] = useState<any>([]);

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const setAlert = useSetAtom(alertAtom);

  const handleOnClose = () => {
    setIsFormDirty(false);
    onClose();
  };

  const onSuccessCallback = () => {
    setIsFormDirty(false);
    setAlert({
      isVisible: true,
      message: 'Background answers saved',
      type: ALERT_TYPES.SUCCESS,
    });
    // handleOnClose();
  };

  const onErrorCallback = () => {
    setAlert({
      isVisible: true,
      message: 'Background answers not saved',
      type: ALERT_TYPES.ERROR,
    });
  };

  const { mutate, isPending } = useUpdateBackgroundAnswer(onSuccessCallback, onErrorCallback);

  const resetAnswers = useCallback(() => {
    if (group?.questions && group?.questions?.length > 0) {
      const questions = group?.questions;

      const answersFromAPI = questions?.map((question) => ({
        content: question?.answer?.answer,
        question_id: question?.id,
      }));

      setUpdatedAnswers(answersFromAPI);
    }
  }, [group]);

  useEffect(() => {
    resetAnswers();
  }, [group]);

  const saveAnswers = () => {
    if (updatedAnswers?.length > 0) {
      mutate({
        payload: {
          answers: updatedAnswers,
        },
      });
    }
  };

  const onInputChange = (event: any, questionId) => {
    const newContent = event.target.value;
    setIsFormDirty(true);
    const existingAnswerIndex = updatedAnswers?.findIndex((answer) => answer.question_id === questionId);
    const isLimitCrossed = getWordCount(newContent) > MAX_WORDS_BACKGROUND_QUESTIONS;

    if (existingAnswerIndex !== -1) {
      const updated = updatedAnswers?.map((answer) => {
        let updatedAnswer;

        if (answer.question_id === questionId && !isLimitCrossed) {
          updatedAnswer = { ...answer, content: newContent, isLimitCrossed: isLimitCrossed };
        } else {
          updatedAnswer = { ...answer, isLimitCrossed: isLimitCrossed };
        }

        return answer.question_id === questionId ? updatedAnswer : answer;
      });

      setUpdatedAnswers(updated);
    } else {
      const newAnswer = {
        content: !isLimitCrossed ? newContent : '',
        question_id: questionId,
        isLimitCrossed: isLimitCrossed,
      };

      setUpdatedAnswers([...updatedAnswers, newAnswer]);
    }
  };

  const renderQuestion = (question, i) => {
    const [questionHeading = '', questionText = ''] = (question.question ?? '').split(':');
    const answer = updatedAnswers?.find((ans) => ans?.question_id === question?.id);

    return (
      <VStack alignItems={'flex-start'} key={`question-${i}`} width={'100%'}>
        <Text color={'primary'} fontWeight={'bold'}>
          {i + 1}. {questionHeading}
        </Text>
        <Text fontSize={'sm'} color={'gray4'}>
          {questionText}
        </Text>
        <Textarea
          backgroundColor={'gray1'}
          focusBorderColor='primary'
          borderRadius={'xl'}
          height={'96px'}
          border={'1px solid #D0D4DB'}
          value={answer?.content || ''}
          placeholder={question?.placeholder}
          onChange={(e) => onInputChange(e, question?.id)}
          sx={{
            '::placeholder': {
              color: 'gray2',
              fontsize: '14px',
            },
          }}
        />
        <Text
          color={answer?.isLimitCrossed ? 'red' : 'gray5'}
          fontStyle={'italic'}
          fontSize={'xs'}
          mt={-2}
          paddingX={1}
        >
          {answer?.isLimitCrossed || (!answer?.content && !answer?.content?.trim().replace(/\s+/g, ''))
            ? `Maximum ${MAX_WORDS_BACKGROUND_QUESTIONS} words`
            : `${getWordCount(answer?.content)} words`}
        </Text>
      </VStack>
    );
  };

  const renderHeader = () => (
    <VStack alignItems={'flex-start'}>
      <Text color={'gray10'} fontWeight={600} fontSize={['md', 'xl']}>
        {group?.heading}
      </Text>
      <Text color={'gray4'} fontWeight={500} fontSize={['sm', 'lg']}>
        You can add just a few words for each, this will help our AI give you specific feedback
      </Text>
    </VStack>
  );

  const renderBody = () => (
    <VStack paddingX={2} spacing={3}>
      {group?.questions?.map((question, i) => renderQuestion(question, i))}
    </VStack>
  );

  const renderFooter = () => (
    <HStack w={'100%'} justifyContent={'flex-end'}>
      <Button
        backgroundColor='primaryLight'
        borderRadius='xl'
        color='foreground'
        _hover={{
          bg: 'primary',
        }}
        onClick={saveAnswers}
        isDisabled={!isFormDirty}
      >
        {isPending ? <Spinner /> : ' Save Details'}
      </Button>
    </HStack>
  );

  return (
    <>
      <Show below='md'>
        <DrawerComponent isOpen={isOpen} onClose={handleOnClose} placement='bottom'>
          <VStack>
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </VStack>
        </DrawerComponent>
      </Show>
      <Hide below='md'>
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          header={renderHeader()}
          body={renderBody()}
          footer={renderFooter()}
          contentProps={{
            style: {
              width: '70vw',
              maxWidth: '90vw',
            },
          }}
        />
      </Hide>
    </>
  );
};

export default BackgroundInfoModal;
