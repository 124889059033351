import {
  Box,
  Button,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  chakra,
} from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { AnalyticsScreenName, HEADER_LINKS_KOLLEGIO, Routes } from '../../../Constants/constants';
import { getImage } from '../../../Utils/common';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import NavLink from '../../../lib/UI-Components/NavLink';
import AddonButton from '../Common/AddonButton';
// @ts-ignore
import { useSetAtom } from 'jotai';
import logo from '../../../Assets/icons/logoemblem.svg'; // @ts-ignore
import { ReactComponent as _hamburger } from '../../../Assets/icons/text-long.svg';
import {
  activityForm,
  activityScore,
  currentActivityDescription,
  currentActivityTitle,
} from '../../../Atoms/Activities/activityAtom';

const HamburgerIcon = chakra(_hamburger);

interface LogoSectionProps {
  isUserOnPublicRoute: any;
  location: any;
  setLocation: any;
  setAreNotesSelected: any;
  setIsGuideSelected: any;
}

const LogoSection = ({
  isUserOnPublicRoute,
  location,
  setLocation,
  setAreNotesSelected,
  setIsGuideSelected,
}: LogoSectionProps) => {
  const { trackEvent } = useMixpanel();
  const setActivityTitle = useSetAtom(currentActivityTitle);
  const setActivityDescription = useSetAtom(currentActivityDescription);
  const setActivityScore = useSetAtom(activityScore);
  const setActivityData = useSetAtom(activityForm);

  return (
    <HStack spacing={1} width={'100%'} h={'100%'}>
      {!isUserOnPublicRoute && (
        <Box display={['flex', 'flex', 'flex', 'none', 'none']}>
          <Menu>
            <MenuButton
              as={Button}
              rounded={'md'}
              variant={'link'}
              cursor={'pointer'}
              color={'primaryDark'}
              p={0}
              alignContent={'center'}
              alignSelf={'center'}
              _hover={{ color: 'foreground' }}
            >
              <AddonButton
                tooltipContent='Add notes to stay organized and jot down important information.'
                icon={<HamburgerIcon boxSize={6} />}
                display={['flex', 'flex', 'flex', 'none', 'none']}
              />
            </MenuButton>
            <MenuList zIndex={'100'}>
              <MenuGroup id='header-menu'>
                {Object.entries(HEADER_LINKS_KOLLEGIO).map(([key, value]) => (
                  <Box key={key}>
                    {key === location ? (
                      <MenuItem>
                        <Image
                          src={
                            location === key ? getImage('green' + value.toLowerCase()) : getImage(value.toLowerCase())
                          }
                          boxSize={4}
                          mr={1}
                        />
                        <Text fontSize={'medium'} fontWeight={'semibold'} key={key} color='primaryLight'>
                          {value}
                        </Text>
                      </MenuItem>
                    ) : (
                      <NavLink
                        paddingInline={0}
                        onClick={() => {
                          trackEvent(`Open ${key}`, {
                            screenName: AnalyticsScreenName.ScreenHeader,
                            label: `Click on ${key} link in Header`,
                            isLink: true,
                            query: '#menu-header a',
                          });

                          setLocation(value);
                          setAreNotesSelected(false);
                          setIsGuideSelected(false);
                          setActivityTitle(null);
                          setActivityDescription(null);
                          setActivityScore(0);
                          setActivityData({
                            isAddActivityForm: false,
                            position: null,
                            activityId: null,
                            formData: {},
                            leadership: 0,
                          });
                          return true;
                        }}
                        key={key}
                        to={`/${key.toLowerCase()}`}
                      >
                        <MenuItem fontSize={['sm', 'sm', 'sm', 'md', 'md']} color={'primaryLight'}>
                          <Image
                            src={
                              location === key ? getImage('green' + value.toLowerCase()) : getImage(value.toLowerCase())
                            }
                            boxSize={4}
                            mr={1}
                          />
                          <Text color='gray5' fontSize='medium'>
                            {value}
                          </Text>
                        </MenuItem>
                      </NavLink>
                    )}
                  </Box>
                ))}
              </MenuGroup>
            </MenuList>
          </Menu>
        </Box>
      )}
      <LinkBox flexShrink={0} display={'flex'} flexDirection={'row'} cursor={'pointer'} alignItems={'center'}>
        {!isUserOnPublicRoute ? (
          <LinkOverlay as={ReactRouterLink} to={Routes.Home}>
            <Image src={logo} alt='logo' maxW={['108px', '112px', '112px', '112px', '112px']} />
          </LinkOverlay>
        ) : (
          <Image src={logo} alt='logo' maxW={['108px', '112px', '112px', '112px', '112px']} />
        )}
      </LinkBox>
    </HStack>
  );
};

export default LogoSection;
