import { useEffect, useState } from 'react';
import { isMobile } from '../../Utils/common';

interface UseLottieAnimationResult {
  animationData: any | null;
  loading: boolean;
  error: boolean;
}

const useLottieAnimation = (url: string): UseLottieAnimationResult => {
  const [animationData, setAnimationData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isMobile()) {
      setLoading(false);
      return;
    }

    const fetchAnimationData = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        setAnimationData(data);
      } catch (e) {
        setError(true);
        console.error('Error fetching Lottie animation data:', e);
      } finally {
        setLoading(false);
      }
    };

    fetchAnimationData();
  }, [url, isMobile]);

  return { animationData, loading, error };
};

export default useLottieAnimation;
