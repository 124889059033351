import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAtom } from '../../Atoms/App/user';
import { essayEditStateAtom } from '../../Atoms/Essays/editorAtom';
import { AnalyticsScreenName } from '../../Constants/constants';
import essaysService from '../../Services/essay';
import firstUXService from '../../Services/first-ux';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';

const useUpdateFirstUXQuestions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const setEssayEditAtom = useSetAtom(essayEditStateAtom);
  const { trackEvent } = useMixpanel(AnalyticsScreenName.ScreenFirstUX);

  async function handleSubmission(intake: string, study: string, route: string, interest: string) {
    setLoading(true);

    try {
      await updateIntakeYear(intake);

      if (study !== 'I am not sure') {
        await updateFieldOfStudy(study);
      }
    } catch (error) {
      console.error('Failed to update user data:', error);
    } finally {
      setLoading(false);

      trackEvent(`User Interest Selection - ${interest}`, {
        screenName: AnalyticsScreenName.ScreenFirstUX,
        label: 'First Time User Experience Interests',
      });

      if (interest === 'Getting feedback on my college essay') {
        setEssayEditAtom(1);

        const emptyFile = {
          prompt: '',
          wordcount: '',
          user_id: user?.id,
          college_id: -1,
          essay: '',
          plan: '',
          name: 'New Essay',
          id: null,
          active: true,
        };

        const response = await essaysService.createNewEssay(emptyFile);

        navigate(route, {
          state: { isRoutingFromIntro: true, id: response?.essay_id, file: { ...emptyFile, id: response?.essay_id } },
        });
      } else {
        navigate(route);
      }
    }
  }

  async function updateIntakeYear(year: string) {
    try {
      await firstUXService.addIntakeYear(year);
    } catch (error) {
      console.error('Failed to Update Intake Year:', error);
    }
  }

  async function updateFieldOfStudy(study: string) {
    try {
      await firstUXService.addFieldOfStudy(study);
    } catch (error) {
      console.error('Failed to Update Field of Study:', error);
    }
  }

  return {
    loading,
    handleSubmission,
  };
};

export default useUpdateFirstUXQuestions;
