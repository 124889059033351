import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import Report from "./Report";
import { UserCollege } from "../../Models/colleges";
import { ModalProps } from "../Modals/WritingToolsModal";
import CustomModal from "../../lib/UI-Components/Modal";

interface ReportModalProps extends ModalProps {
  college?: UserCollege | null;
}

const ReportModal = ({ isOpen, onClose, college }: ReportModalProps) => (
  <>
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      size={["xs", "md", "lg"]}
      backdropFilter="blur(5px)"
      header={
        <HStack justifyContent={"space-between"}>
          <Text
            color={"darkBlue"}
            fontSize={["md", "xl"]}
            fontWeight={"semibold"}
          >
            Report a Problem
          </Text>
        </HStack>
      }
      body={<Report college={college} onClose={onClose} />}
    />
  </>
);

export default ReportModal;
