import { useMutation, useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { userAtom, userIdAtom } from '../../Atoms/App/user';
import { isProfileSavingAtom } from '../../Atoms/Profile/profile';
import { alertAtom } from '../../Atoms/alertAtom';
import { ALERT_TYPES } from '../../Constants/constants';
import profileService from './../../Services/profile';

const useUpdateProfileInfo = () => {
  const setUser = useSetAtom(userAtom);
  const setIsProfileSaving = useSetAtom(isProfileSavingAtom);

  return useMutation({
    mutationFn: (payload: any) => profileService.updateProfile(payload),
    onSuccess: (_, variables) => {
      setUser((prev) => {
        localStorage.setItem('user', JSON.stringify({ ...prev, ...variables }));
        return { ...prev, ...variables };
      });
      setIsProfileSaving(false);
    },
    onError: () => {
      setIsProfileSaving(false);
    },
  });
};

const useUpdateProfileName = () => {
  const setUser = useSetAtom(userAtom);
  const setAlert = useSetAtom(alertAtom);

  return useMutation({
    mutationFn: (payload: any) => profileService.updateUserName(payload),
    onSuccess: (_, variables) => {
      setUser((prev) => ({ ...prev, ...variables }));
      setAlert({
        isVisible: true,
        message: 'Name Updated Successfully',
        type: ALERT_TYPES.SUCCESS,
      });
    },
    onError: () => {
      setAlert({
        isVisible: true,
        message: 'Failed to Update Name',
        type: ALERT_TYPES.ERROR,
      });
    },
  });
};

const useGetBackgroundQuestions = () =>
  useQuery({
    queryKey: ['background-questions'],
    queryFn: () => profileService.getQuestions(),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

const useGetBackgroundAnswers = () => {
  const userId = useAtomValue(userIdAtom);

  return useQuery({
    queryKey: ['background-answers'],
    queryFn: () => profileService.getAnswers(userId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!userId,
  });
};

const useUpdateBackgroundAnswer = (onSuccessCallback?: () => void, onErrorCallback?: () => void) => {
  const userId = useAtomValue(userIdAtom);
  const { refetch } = useGetBackgroundAnswers();

  const updateBackgroundAnswer = useMutation({
    mutationFn: ({ payload }: any) => profileService.updateBackgroundAnswer(payload, userId),
    onSuccess: () => {
      refetch();

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: () => {
      if (onErrorCallback) {
        onErrorCallback();
      }
    },
  });

  return updateBackgroundAnswer;
};

export {
  useGetBackgroundAnswers,
  useGetBackgroundQuestions,
  useUpdateBackgroundAnswer,
  useUpdateProfileInfo,
  useUpdateProfileName,
};
