import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Image,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom'; // @ts-ignore
import { ReactComponent as _FileIcon } from '../../../../Assets/icons/solar_document-broken.svg';
import { PlannerAtom } from '../../../../Atoms/Planner/planner';
import { HEADER_ESSAY_SELECTION, Routes } from '../../../../Constants/constants';
import {
  ESSAY_DRAFT_DROPDOWN_OPTIONS,
  ESSAY_DRAFT_OPTIONS,
  ESSAY_DRAFT_OPTIONS_COLOR,
} from '../../../../Constants/planner';
import useMixpanel from '../../../../lib/Analytics/Mixpanel/useMixpanel'; // @ts-ignore
import bean from './../../../../Assets/icons/bean.svg';

const FileIcon = chakra(_FileIcon);
interface ISimilarEssayPrompt {
  prompt: any;
}

const SimilarEssayPrompt: React.FC<ISimilarEssayPrompt> = ({ prompt }) => {
  const { trackEvent } = useMixpanel();
  const navigate = useNavigate();
  const [plannerAtom, setPlannerAtom] = useAtom(PlannerAtom);

  const onClickHandler = () => {
    trackEvent('Similar Essay Opened from Task Drawer');
    setPlannerAtom({ ...plannerAtom, isTaskDrawerOpen: false });
    navigate(Routes.Essays, {
      state: {
        headerValue:
          prompt?.completionStatus !== ESSAY_DRAFT_OPTIONS.NOT_STARTED_YET
            ? HEADER_ESSAY_SELECTION.SavedEssays
            : HEADER_ESSAY_SELECTION.NewEssays,
      },
    });
  };

  return (
    <Accordion allowToggle width={'100%'} defaultIndex={[0]} borderRadius={'12px'}>
      <AccordionItem
        width={'100%'}
        backgroundColor={'white'}
        border={'1px solid'}
        borderColor={'gray2'}
        borderRadius={'xl'}
      >
        <AccordionButton>
          <HStack width={'100%'}>
            <Image
              src={prompt?.college?.image_url ? prompt?.college?.image_url : bean}
              alt={prompt?.college?.name}
              boxSize='25px'
              objectFit='scale-down'
              rounded='full'
              flexShrink={0}
            />
            <Text color={'gray6'} fontSize={'md'} fontWeight={700}>
              {prompt?.college?.name}
            </Text>
          </HStack>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <VStack
            width={'100%'}
            backgroundColor={'gray1'}
            padding={3}
            border={'1px solid #EFF1F3'}
            cursor={'pointer'}
            _hover={{
              backgroundColor: 'gray9',
            }}
            borderRadius={'lg'}
            onClick={onClickHandler}
          >
            <HStack width='100%' alignItems={'flex-start'}>
              <Box width='5%'>
                <FileIcon boxSize={6} pt={1} color='primaryLight' />
              </Box>
              <Text width='95%' fontSize='sm' color='#667085' fontWeight={400}>
                {prompt?.prompt}
              </Text>
            </HStack>
            <HStack width={'100%'} justifyContent={'flex-end'}>
              <Text color={ESSAY_DRAFT_OPTIONS_COLOR[prompt?.completionStatus]} fontWeight={600} fontSize={'xs'}>
                {ESSAY_DRAFT_DROPDOWN_OPTIONS[prompt?.completionStatus]}:
              </Text>
              <Text color={'#737373'} fontWeight={500} fontSize={'xs'}>
                {new Date(prompt?.essayUpdatedAt).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </Text>
            </HStack>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default SimilarEssayPrompt;
