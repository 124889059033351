import { Button, HStack, Spinner, Text, VStack, chakra } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; // @ts-ignore
import { ReactComponent as _GoogleLogo } from '../../Assets/images/google_logo.svg';
import { AnalyticsScreenName } from '../../Constants/constants.ts';
import useAuth from '../../Hooks/Auth/useAuth.tsx';
import useGoogleOAuthCode from '../../Hooks/Auth/useGoogleOAuthCode.tsx';
import { getGoogleOAuthURL, getStoredQueryParams, storeAndFilterQueryParams } from '../../Utils/auth.ts';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel.tsx';
import CustomDivider from '../../lib/UI-Components/CustomDivider.tsx';
import TwoColumnLayout from './../../lib/UI-Components/TwoColumnLayout.tsx';
import SignUpForm from './SignUpForm.tsx';

const SignUp: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const GoogleLogo = chakra(_GoogleLogo);
  const { trackEvent } = useMixpanel(AnalyticsScreenName.ScreenJoin);
  const navigate = useNavigate();

  const { error, setError } = useAuth();

  const { isEmbeddedBrowser } = useGoogleOAuthCode('signup', setError, setIsLoading);

  const [searchParams] = useSearchParams();
  const { referralParams } = getStoredQueryParams();

  useEffect(() => {
    storeAndFilterQueryParams(searchParams);
  }, [searchParams]);

  return (
    <TwoColumnLayout textBelowAnimation='Continue To Your Personalized Dashboard'>
      <VStack
        height={'full'}
        width={'100%'}
        padding={6}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={[4, 4, 4, 4, 4]}
      >
        {!isEmbeddedBrowser && (
          <>
            <Button
              bg={'foreground'}
              color='primaryDark'
              borderRadius={'xl'}
              padding={[6, 8, 8, 6]}
              fontSize={['sm', 'md', 'md', 'sm']}
              display={'flex'}
              width={['90%', '90%', '60%', '54%']}
              justifyContent={'center'}
              alignItems={'center'}
              border='1px solid var(--Primary-Color-Primary-Dark, #0C391D)'
              _hover={{
                border: '1px solid var(--Primary-Color-Primary, #035A41)',
                background: 'var(--Grays-Gray-1, #F9FAFB)',
                boxShadow: '0px 4px 24px -16px rgba(105, 108, 112, 0.50)',
              }}
              onClick={async () => {
                await trackEvent(`Create Account with Google`, {
                  screenName: AnalyticsScreenName.ScreenJoin,
                  label: 'Click on Create Account with Google on Join',
                });
                window.location.href = getGoogleOAuthURL(true, searchParams.get('referrer'));
              }}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <GoogleLogo boxSize={10} />
                  Create Account with&nbsp;<b> Google </b>
                </>
              )}
            </Button>
            <Text as='p' color='red.500'>
              {error}
            </Text>
          </>
        )}
        <HStack width={'100%'} justifyContent={'center'}>
          <CustomDivider width={['20%', '20%', '30%', '30%']} />
          <Text fontSize={['sm', 'md', 'md', 'md']} textAlign={'center'}>
            {' '}
            or create account with
          </Text>
          <CustomDivider width={['20%', '20%', '30%', '30%']} />
        </HStack>
        <SignUpForm
          referrer={searchParams.get('referrer')}
          referralName={searchParams.get('referral_name') || referralParams?.referral_name}
        />
        <HStack mt={2}>
          <Text color={'gray5'} fontSize={['xs', 'md', 'md', 'md']}>
            Already have an account?
          </Text>
          <Text
            color={'primaryLight'}
            onClick={() => navigate('/login')}
            textDecoration={'underline'}
            cursor={'pointer'}
            fontSize={['xs', 'md', 'md', 'md']}
          >
            Sign in
          </Text>
        </HStack>
      </VStack>
    </TwoColumnLayout>
  );
};

export default SignUp;
