/* eslint-disable max-len */
import React from 'react';
import { Step } from 'react-joyride';
import { Routes } from '../Constants/constants';
import { DEMO_LOTTIE_LINKS, DEMO_SVG_LINKS } from '../Constants/demo';
import { ESSAY_DEMO } from '../Constants/essay';
import { isMobile, isTablet } from '../Utils/common';
import DemoTooltipContent from '../lib/UI-Components/Demo/DemoTooltipContent';

export function getDashboardSteps(user: any): Step[] {
  return [
    {
      disableBeacon: true,
      target: '#header',
      placement: 'center',
      content: (
        <DemoTooltipContent
          imageSrc={DEMO_SVG_LINKS.demoDashboardMain}
          title={`Hi ${user}, welcome to Kollegio! 👋`}
          content={`This is your personalized dashboard, from here you
          can access all of our features and build your best possible 
          college application (how exciting!) 🥳`}
        />
      ),
    },
    {
      target: '.dashboard-college-list-card',
      disableBeacon: true,

      placement: 'right-end',
      content: (
        <DemoTooltipContent
          imageSrc={DEMO_SVG_LINKS.demoUpcomingDealines}
          title={'Upcoming Deadlines'}
          content={`Upcoming deadlines will appear here. Head to the college tab to make your personalized college list!`}
        />
      ),
    },
    {
      target: '.dashboard-essay-editor-card',
      disableBeacon: true,

      placement: 'top-end',
      content: (
        <DemoTooltipContent
          imageSrc={DEMO_SVG_LINKS.demoEssaysCard}
          title={'Organize and write your perfect essays'}
          content={`The essay editor allows you to see all the
          required essays for colleges on your list. 
          Make use of our AI writing tools as you plan, edit and revise your essays.`}
        />
      ),
    },
    {
      target: '.dashboard-activities-card',
      disableBeacon: true,

      placement: 'auto',
      content: (
        <DemoTooltipContent
          imageSrc={DEMO_SVG_LINKS.demoActivitiesCard}
          title={'Plan your activities'}
          content={`The activity planner will teach you how to portray your extracurriculars effectively 
          and give you detailed feedback on your activity list.`}
        />
      ),
    },
    {
      target: '.dashboard-planner-card',
      placement: 'auto',
      disableBeacon: true,
      content: (
        <DemoTooltipContent
          isAnimation
          animationUrl={DEMO_LOTTIE_LINKS.plannerSmartPlanAnimation}
          title={'Smart Planning'}
          content={`Write your essays in a way that meets your goals and engages similar topics together.`}
        />
      ),
    },
    {
      target: '.header-resources',
      placement: 'bottom',
      disableBeacon: true,
      content: (
        <DemoTooltipContent
          isAnimation
          animationUrl={DEMO_LOTTIE_LINKS.notesAnimation}
          title={'Check out our resources'}
          content={`Use notes to keep track of what’s on your mind.
          Resources are great to consult whenever your questions need more in depth answers!`}
        />
      ),
    },
  ];
}

export const collegeSearchSteps = [
  {
    target: '#header',
    placement: 'center',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoCollegesMain}
        title={`College Search`}
        content={`This version of Kollegio has 1600+ colleges 
        for you to pick from - that's a lot of colleges!`}
      />
    ),
  },
  {
    target: '.college-list-table',
    placement: 'top-left',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.collegeTableAnimation}
        title={`Colleges`}
        content={`You can click on a college to access more information. 
        If you like a college, click the plus button 
        to add it to your list. You can always remove it later.`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.college-profile-mobile' : '.college-profile',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.profileTagAnimation}
        title={`Scores`}
        content={` Each school is categorized as a reach,
          safety or target based on your personal info (add these in for
          more personal recommendations!). If you’re curious about what 
          these tags mean, click on Guide in the navigation bar to access the resource section.`}
      />
    ),
  },
];

export const essayManagerSteps = [
  {
    target: '#header',
    placement: 'center',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoEssaySelectionMain}
        title={'Organize your essays'}
        content={`This is your essay manager. The Common App and 
        many universities require supplemental essays. This is 
        for schools to get an idea of who you are and whether
        or not you would be a good fit for their university.`}
      />
    ),
  },
  {
    target: '.essay-create-section',
    placement: 'top-end',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.essayPromptsAnimation}
        title={'Create your essay drafts'}
        content={`Schools you add to your college list will appear here, 
          as will as their respective supplemental essay prompts. Click on a school
          and select a prompt to get started. 
          `}
      />
    ),
  },
  {
    target: '#brainstormer-modal-section',
    placement: 'right',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.brainstormFinal}
        title={ESSAY_DEMO.brainstormTitle}
        content={ESSAY_DEMO.brainstormContent}
      />
    ),
  },
  {
    target: '.essay-free-write',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.freeWriteAnimation}
        title={'Free write'}
        content={`If you're in the mood to free write, click here to dive right in!
        You can always add in an essay prompt later.`}
      />
    ),
  },
];

export const essayWriterSteps = [
  {
    target: '#header',
    placement: 'center',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoEssayEditorMain}
        title={'Essay Editor'}
        content={`This is your Essay Editor, from here you
        can access Kollegio's suite of AI tools that help you ethically level up your essay.`}
      />
    ),
  },
  {
    target: '.essay-writer-writing-tools',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.aiToolsAnimation}
        title={'Writing Tools'}
        content={`We have an array of writing tools to help make your writing seamless,
        highlight some text to get started.`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.essay-writer-review-button-mobile' : '.essay-writer-review-button',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.essayReviewAnimation}
        title={'Essay Review'}
        content={`Whenever you want feedback on something you’ve written, 
        you can use our essay review function. You can highlight text for
        partial review, or choose the type of feedback you want for your whole essay.`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.essay-writer-review-plan-mobile' : '.essay-writer-review-button',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoEssayPlan}
        title={'Essay Plan'}
        content={`You can access and view your essay plan here and 
        toggle back to your essay feedback as well.`}
      />
    ),
  },
];

export const activityManagerSteps = [
  {
    target: '.activities-main',
    placement: 'center',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoActivitiesMain}
        title={'Activities'}
        content={`This is your activity overview screen. 
          From here, you can learn how to best build up and portray your extracurriculars. 
          The common app and many universities require extra-curricular activities.`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.activity-category-breakdown-mobile' : '.activity-category-breakdown',
    placement: 'top-end',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.categoryBreakdownAnimation}
        title={'Category Breakdown'}
        content={`Your activities will be put into different buckets
            so you can see where your peaks form. For more info on peaks, check out our guide!`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.activity-time-summary-mobile' : '.activity-time-summary',
    placement: 'top-end',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.activityTimeSummaryAnimation}
        title={'Activity Time Summary'}
        content={`You can track how much time you are 
        spending on extracurriculars and understand what is 
        considered competitive at certain schools.`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.activity-ai-comment-mobile' : '.activity-ai-comment',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoActivitiesAiSuggestions}
        title={'AI Suggestions'}
        content={`We also give you feedback on your activity 
                list as a whole. If you have any more questions about 
                activities, make sure to check out our resources for more tips and ideas - good luck!`}
      />
    ),
  },
  {
    target: isMobile() || isTablet() ? '.add-activity-button-mobile' : '.add-activity-button',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoActivitiesList}
        title={'Add an activity'}
        content={`Once you click add activity, you can input all 
        the information that you can just copy over later on.`}
      />
    ),
  },
  {
    target: '.activity-list',
    placement: 'center',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoActivitiesList}
        title={'Activity List'}
        content={`This is your list view. Click on an activity card to 
        receive specific feedback on your individual activities and to input information directly.`}
      />
    ),
  },
  // {
  //   target: ".activity-individual-stats",
  //   placement: "auto",
  //   content: (
  //     <DemoTooltipContent
  //       isAnimation={true}
  //       animationUrl={DEMO_LOTTIE_LINKS.activityFormFillingAnimation}
  //       title={"Individual Activity Summary"}
  //       content={`On the right side we give you some descriptors,
  //       we will help you portray yourself in the best light possible.`}
  //     />
  //   ),
  // },
  // {
  //   target: ".activity-ask-ai",
  //   placement: "right",
  //   content: (
  //     <DemoTooltipContent
  //       isAnimation={true}
  //       animationUrl={DEMO_LOTTIE_LINKS.activityAISuggestionAnimation}
  //       title={"Edit Activity"}
  //       content={`You can input all the information in a
  //        common app-esque style that you can just copy over later on.
  //       `}
  //     />
  //   ),
  // },
];

export const profileSteps = [
  {
    target: '#header',
    placement: 'center',
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoProfile}
        title={'Profile'}
        content={`This is the student profile section, the more
        information you enter here - the better we can personalize your 
        Kollegio experience.
        `}
      />
    ),
  },
  {
    target: '#header',
    placement: 'center',
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoBackground}
        title={'Applicant Background'}
        content={`This is your applicant background section. 
        The more insights you provide, the more accurate suggestions 
        we can offer. Feel free to answer the questions below at your own pace. 
        Don't hesitate to share your achievements and highlights!
        `}
      />
    ),
  },
];

export const navbarSteps = [
  {
    disableBeacon: true,
    target: '.navbar-dropdown',
    placement: 'bottom-start',
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.openDemoAnimation}
        title={'Access Demo'}
        content={`You can come back to the demo any time by accessing it
        through the dropdown.
        `}
      />
    ),
  },
];

export const plannerSteps = [
  {
    target: '.planner-main',
    placement: 'center',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoPlannerMain}
        title={'Welcome to your planner!'}
        content={`Here we help you create your perfect plan so you can stay on track and execute to the best of your ability!
        `}
      />
    ),
  },
  {
    target: '.planner-generate-plan',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        imageSrc={DEMO_SVG_LINKS.demoPlannerGenerateButton}
        title={'Generate Plan'}
        content={`Generate a personalized plan based on your priorities`}
      />
    ),
  },
  {
    target: '.planner-smart-plan',
    placement: 'top-left',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.plannerSmartPlanAnimation}
        title={'Smart Planning'}
        content={`Write your essays in a way that meets your goals and engages similar topics together.`}
      />
    ),
  },
  {
    target: '.planner-shortlisted-colleges',
    placement: 'auto',
    disableBeacon: true,
    content: (
      <DemoTooltipContent
        isAnimation
        animationUrl={DEMO_LOTTIE_LINKS.plannerShortlistCollegesAnimation}
        title={'Priority and Completion'}
        content={`Organize your priorities and mark your progress throughout the application season.`}
      />
    ),
  },
];
export const getDemoSteps = (userFirstName: string, isEssayWriting: boolean) => ({
  [Routes.Home]: {
    steps: getDashboardSteps(userFirstName),
    continuous: true,
  },
  [Routes.Essays]: {
    steps: isEssayWriting ? essayWriterSteps : essayManagerSteps,
    continuous: false,
  },
  [Routes.Colleges]: {
    steps: collegeSearchSteps,
    continuous: true,
  },
  [Routes.Plan]: {
    steps: plannerSteps,
    continuous: false,
  },
  [Routes.Profile]: {
    steps: profileSteps,
    continuous: false,
  },
  [Routes.Activities]: {
    steps: activityManagerSteps,
    continuous: false,
  },
});
