import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  VStack,
  chakra,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // @ts-ignore
import { ReactComponent as _CalendarIcon } from '../../../../Assets/icons/calendar-icon.svg'; // @ts-ignore
import { ReactComponent as _FileIcon } from '../../../../Assets/icons/solar_document-broken.svg';
import { PlannerAtom, SelectedTaskDetails } from '../../../../Atoms/Planner/planner'; // @ts-ignore
import { HEADER_ESSAY_SELECTION, Routes } from '../../../../Constants/constants';
import { TASK_COMPLETION_STATES } from '../../../../Constants/planner';
import usePlanner from '../../../../Hooks/Planner/usePlanner';
import useMixpanel from '../../../../lib/Analytics/Mixpanel/useMixpanel';
import CustomDrawer from '../../../../lib/UI-Components/CustomDrawer';
import CustomDatePicker from '../../../../lib/UI-Components/DatePicker/DatePicker';
import DrawerComponent from '../../../../lib/UI-Components/Drawer';
import SimilarPrompt from '../SimilarPrompt';
import MultipleEssayPrompt from './MultipleEssayPrompt';
import SimilarEssayPrompt from './SimilarEssayPrompt';
import TaskDrawerSkeleton from './TaskDrawerSkeleton';

const CalendarIcon = chakra(_CalendarIcon);
const FileIcon = chakra(_FileIcon);

const TaskDrawer: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trackEvent } = useMixpanel();
  const { updateDeadline } = usePlanner();
  const [plannerAtom, setPlannerAtom] = useAtom(PlannerAtom);
  const [selectedTask] = useAtom(SelectedTaskDetails);
  const [deadline, setDeadline] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isMobileView = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  useEffect(() => {
    if (plannerAtom?.isTaskDrawerOpen) {
      onOpen();
    }

    setIsLoading(plannerAtom?.isTaskDrawerLoading);

    if (plannerAtom?.selectedTask?.deadline) {
      setDeadline(new Date(plannerAtom?.selectedTask?.deadline));
    }
  }, [plannerAtom]);

  const onClickCross = () => {
    onClose();
    setPlannerAtom({ ...plannerAtom, isTaskDrawerOpen: false, selectedTask: null });
  };

  const handleDeadlineChange = (value, event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
    trackEvent('Task Deadline Changed in Calendar', {
      taskName: plannerAtom?.selectedTask?.title,
    });
    setDeadline(value);
    updateDeadline(plannerAtom?.selectedTask, value);
  };

  const deadlineField = () => (
    <Stack width={'100%'}>
      <Text color={'gray6'} fontSize={'xs'}>
        Change Deadline
      </Text>
      <CustomDatePicker
        onChange={handleDeadlineChange}
        title='Change Deadline'
        selected={deadline}
        customInput={
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <CalendarIcon color={'primary'} />
            </InputLeftElement>
            <Input
              value={deadline?.toLocaleDateString('en-US')}
              borderRadius={'lg'}
              color={'gray5'}
              focusBorderColor='primary'
              onClick={() => {
                trackEvent('Task Calendar Opened', {
                  taskName: plannerAtom?.selectedTask?.title,
                });
              }}
            />
          </InputGroup>
        }
      />
    </Stack>
  );

  const header = (
    <Text fontSize={'md'} color={'#344054'} fontWeight={600} paddingRight={8}>
      {plannerAtom?.selectedTask?.title}
    </Text>
  );

  const onClickPrompt = () => {
    trackEvent('Essay Opened from Task Drawer', {
      taskName: plannerAtom?.selectedTask?.title,
    });

    setPlannerAtom({ ...plannerAtom, isTaskDrawerOpen: false });
    navigate(Routes.Essays, {
      state: {
        headerValue:
          plannerAtom?.selectedTask?.completion_state === TASK_COMPLETION_STATES.COMPLETED
            ? HEADER_ESSAY_SELECTION.SavedEssays
            : HEADER_ESSAY_SELECTION.NewEssays,
      },
    });
  };

  const body = (
    <VStack width={'100%'} alignItems={'flex-start'} spacing={4}>
      <VStack width={'100%'} spacing={1}>
        <Text color={'primary'} w='100%' fontSize={'md'} fontWeight={700}>
          Essay Prompt
        </Text>
        <Box
          width={'100%'}
          backgroundColor={'white'}
          border={'1px solid'}
          borderColor={'gray2'}
          borderRadius={'12px'}
          padding={4}
          cursor={'pointer'}
          onClick={onClickPrompt}
          _hover={{
            backgroundColor: 'gray9',
          }}
        >
          <VStack width={'100%'} alignItems={'flex-start'}>
            <HStack width='100%' alignItems={'flex-start'} spacing={2}>
              <Box width='5%'>
                <FileIcon boxSize={6} pt={1} color='primaryLight' />
              </Box>
              <Text width='95%' fontSize='sm' color='#667085' fontWeight={400}>
                {selectedTask?.currentPrompt}
              </Text>
            </HStack>
            {selectedTask.wordCount && (
              <Text fontSize={'xs'} color='#667085' fontWeight={400} pl={2}>
                {`Word Count: ${selectedTask?.wordCount}`}
              </Text>
            )}
          </VStack>
        </Box>
      </VStack>
      {isMobileView && deadlineField()}
      {selectedTask?.similarSupplements?.length > 0 && (
        <VStack width={'100%'}>
          <HStack width={'100%'} justifyContent={'space-between'}>
            <Text color={'primary'} fontSize={'md'} fontWeight={700}>
              Similar Essay Prompts
            </Text>
            <SimilarPrompt count={selectedTask?.similarSupplements?.length} />
          </HStack>
          <VStack width={'100%'} alignItems={'flex-start'} spacing={3}>
            {selectedTask?.similarSupplements?.map((prompt) => (
              <SimilarEssayPrompt key={`similar-prompt-${prompt?.id}`} prompt={prompt} />
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );

  const multiPromptBody = (
    <VStack width={'100%'} alignItems={'flex-start'} spacing={4}>
      <Text color={'primary'} w='100%' fontSize={'md'} fontWeight={700}>
        Essay Prompts
      </Text>
      {selectedTask?.choices?.map((prompt) => (
        <MultipleEssayPrompt key={`multi-prompt-${prompt?.id}`} prompt={prompt} />
      ))}
    </VStack>
  );

  const loadedState = () => (selectedTask?.multiPrompt ? multiPromptBody : body);

  return (
    <>
      {isMobileView ? (
        <DrawerComponent isSwipable heading={header} isOpen={isOpen} onClose={onClickCross} placement={'bottom'}>
          {isLoading ? <TaskDrawerSkeleton /> : loadedState()}
        </DrawerComponent>
      ) : (
        <CustomDrawer
          isOpen={isOpen}
          onClose={onClickCross}
          showDrawerCloseButton
          showDrawerOverlay
          closeOnOverlayClick
          placement='right'
          header={header}
          footer={null}
          body={isLoading ? <TaskDrawerSkeleton /> : loadedState()}
          size='sm'
        />
      )}
    </>
  );
};

export default TaskDrawer;
