import { Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userAtom } from '../Atoms/App/user';
import { bannerAtom } from '../Atoms/bannerAtom';
import ChatBotButton from '../Components/ChatBot/ChatBotButton';
import Header from '../Components/Header/Header';
import Notes from '../Components/Notes/Notes';
import { PublicRoutes, Routes } from '../Constants/constants';
import useMixpanel from '../lib/Analytics/Mixpanel/useMixpanel';
import { Banner } from '../lib/UI-Components/Banner';
import RoutesComponent from './Routes';

const Views = () => {
  const user = useAtomValue(userAtom);
  const location = useLocation();
  const [banner] = useAtom(bannerAtom);

  const { initializeMixpanel, trackEvent } = useMixpanel();

  const handleUnload = useCallback(() => {
    trackEvent('App Closed');
  }, [trackEvent]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  useEffect(() => {
    initializeMixpanel();

    trackEvent('pageview', {
      page: location.pathname,
      title: location.pathname.substring(1),
    });
  }, []);

  const isUserOnPublicRoute =
    (PublicRoutes as string[]).includes(location?.pathname) ||
    location?.pathname.startsWith('/reset') ||
    location?.pathname.startsWith('/verify');

  const isChatBotVisible = location?.pathname === Routes.Resources || location?.pathname === Routes.Home;

  return user?.loggedIn === null ? (
    <Text>Loading...</Text>
  ) : (
    <Box>
      {location.pathname === banner?.screen && <Banner />}
      <Header />
      {!isUserOnPublicRoute && <Notes />}
      <RoutesComponent />
      {isChatBotVisible && <ChatBotButton />}
    </Box>
  );
};

export default Views;
