import { Box, Button, CloseButton, HStack, Text } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { essayEditStateAtom } from '../../../Atoms/Essays/editorAtom';
import { demoAtom } from '../../../Atoms/demoAtom';
import { getNextScreenLabel } from '../../../Constants/demo';
import useJoyRide from '../../../Hooks/App/useJoyRide';

const DemoTooltip = ({
  continuous,
  index,
  primaryProps,
  closeProps,
  skipProps,
  size,
  step,
  isLastStep,
  tooltipProps,
}: TooltipRenderProps) => {
  const [demo, setDemo] = useAtom(demoAtom);
  const essayEditState = useAtomValue(essayEditStateAtom);
  const location = useLocation();
  const { nextStep, reset, transitionToOtherScreen } = useJoyRide();

  return (
    <Box
      {...tooltipProps}
      bg='white'
      maxWidth={[350, 400, 500]}
      minWidth={290}
      overflow='hidden'
      borderRadius='xl'
      position={'relative'}
    >
      <CloseButton {...skipProps} position={'absolute'} right={1} top={1} color={'gray6'} id='skip' zIndex={100} />
      <Box padding='md'>{step.content && <Box>{step.content}</Box>}</Box>
      {index === 0 && !isLastStep ? (
        <HStack w={'full'} justifyContent={'space-between'} p={4}>
          <Button
            {...closeProps}
            onClick={(event) => {
              if (!continuous) {
                closeProps.onClick(event);
                setDemo((prev) => ({ ...prev, isFirstSkip: true }));
              } else {
                reset(!demo?.manualTrigger);
              }
            }}
            variant={'ghost'}
            fontSize={['xs', 'sm', 'md']}
            id={'close'}
          >
            I&apos;ll Explore On My Own
          </Button>
          <Button
            onClick={continuous ? primaryProps.onClick : () => nextStep(step)}
            variant={'primaryWithoutShadow'}
            fontSize={['xs', 'sm', 'md']}
            id={'next'}
          >
            Start Demo
          </Button>
        </HStack>
      ) : (
        <HStack w={'full'} justifyContent={'space-between'} p={4}>
          {size !== 1 ? ( // total number of steps
            <Text fontSize={['xs', 'xs', 'sm']} fontWeight={'medium'} color='black'>
              {index}/{size - 1}
            </Text>
          ) : null}
          <Button
            onClick={continuous ? primaryProps.onClick : () => nextStep(step)}
            variant={isLastStep && !demo?.manualTrigger && size !== 1 ? 'ghost' : 'primaryWithoutShadow'}
            ml={'auto'}
            fontSize={['xs', 'sm', 'md']}
            id={continuous ? 'next' : 'close'}
          >
            {isLastStep ? (!demo?.manualTrigger && size !== 1 ? 'Explore This Screen' : 'Got it') : 'Next'}
          </Button>
          {isLastStep && !demo?.manualTrigger && size !== 1 && (
            <Button
              onClick={(event) => {
                if (!continuous) {
                  closeProps.onClick(event);
                  transitionToOtherScreen();
                } else {
                  transitionToOtherScreen();
                }
              }}
              fontSize={['xs', 'sm', 'md']}
              variant={'primaryWithoutShadow'}
            >
              {`Explore ${getNextScreenLabel(essayEditState === 1)[location?.pathname]}`}
            </Button>
          )}
        </HStack>
      )}
    </Box>
  );
};

export default DemoTooltip;
