import { Box, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, VStack, chakra } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { demoAtom } from '../../Atoms/demoAtom'; // @ts-ignore
import { ReactComponent as _CalendarIcon } from './../../Assets/icons/calendar-icon.svg'; // @ts-ignore
import { ReactComponent as _FileIcon } from './../../Assets/images/file-icon.svg'; // @ts-ignore
import GeneratePlanButton from './SidePanel/GeneratePlanButton';
import SidePanel from './SidePanel/SidePanel';
import PlannerTaskList from './TaskList/ListView'; // @ts-ignore

const FileIcon = chakra(_FileIcon);
const CalendarIcon = chakra(_CalendarIcon);

const PlannerTabs: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [demo] = useAtom(demoAtom);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (demo.isPlannerCollegesListOpen) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [demo.isPlannerCollegesListOpen]);

  useEffect(() => {
    if (demo.showJoyride) {
      setTabIndex(0);
    }
  }, [demo?.showJoyride]);

  return (
    <Tabs position='relative' variant='unstyled' width={'100%'} index={tabIndex} onChange={handleTabChange}>
      <TabList>
        <Tab width={'50%'} onClick={() => handleTabChange(0)}>
          <CalendarIcon color={tabIndex === 0 ? 'primary' : 'gray4'} />
          <Text ml={1} color={tabIndex === 0 ? 'primary' : 'gray4'} fontWeight={600} fontSize={'xs'}>
            Planner
          </Text>
        </Tab>
        <Tab width={'50%'} onClick={() => handleTabChange(1)}>
          <FileIcon color={tabIndex === 1 ? 'primary' : 'gray4'} />
          <Text ml={1} color={tabIndex === 1 ? 'primary' : 'gray4'} fontSize={'xs'} fontWeight={600}>
            Shortlisted Colleges
          </Text>
        </Tab>
      </TabList>
      <TabIndicator mt='-1.5px' height='2px' bg='primary' borderRadius='1px' />
      <TabPanels width={'100%'}>
        <TabPanel>
          <VStack spacing={2} width={'100%'}>
            <GeneratePlanButton />
            <PlannerTaskList />
          </VStack>
        </TabPanel>
        <TabPanel>
          <Box width='100%'>
            <SidePanel />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PlannerTabs;
