import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { ACTIONS, EVENTS } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAtom, userFirstNameAtom } from '../../Atoms/App/user';
import { essayEditStateAtom } from '../../Atoms/Essays/editorAtom';
import { essayAtom, isCreateNewEssayModalOpenObject } from '../../Atoms/Essays/essayAtom';
import { isAccordionOpenAtom } from '../../Atoms/Essays/essaySelectionAtom';
import { demoAtom } from '../../Atoms/demoAtom';
import { getDemoSteps, navbarSteps } from '../../Components/TourSteps';
import { PrivateRoutes, Routes } from '../../Constants/constants';
import { getLocalStorageKeys, nextScreen } from '../../Constants/demo';
import { ESSAY_FEATURES_TAB } from '../../Constants/essay';
import { delay } from '../../Utils/common';

const useJoyRide = () => {
  const [demo, setDemo] = useAtom(demoAtom);
  const setIsAccordionOpen = useSetAtom(isAccordionOpenAtom);
  const setBrainstormerModal = useSetAtom(isCreateNewEssayModalOpenObject);
  const [essayStore, setEssayStore] = useAtom(essayAtom);
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const userFirstName = useAtomValue(userFirstNameAtom);
  const [essayEditState, setEssayEditState] = useAtom(essayEditStateAtom);
  const location = useLocation();

  useEffect(() => {
    const visitedPages: any = localStorage.getItem('visited') as string;
    const storedPageVisitedValues = JSON.parse(visitedPages);
    const isSameUser = storedPageVisitedValues?.user?.id === user?.id;
    const newPageVisitedValues = { ...storedPageVisitedValues };
    const isEssayWriting = essayEditState === 1;
    const localStorageKeys = getLocalStorageKeys(isEssayWriting);
    const isUserOnDemoRoute = PrivateRoutes.includes(location?.pathname as Routes);

    if (
      storedPageVisitedValues?.pageVisits &&
      isSameUser &&
      !storedPageVisitedValues.pageVisits[localStorageKeys[location?.pathname]] &&
      isUserOnDemoRoute
    ) {
      const demoSteps = getDemoSteps(userFirstName || '', isEssayWriting);
      const demoInfo = demoSteps[location?.pathname];
      const isUserOnActivities = location?.pathname === Routes.Activities;

      if (location?.pathname !== Routes.Essays) {
        setEssayEditState(0);
      }

      newPageVisitedValues.pageVisits[localStorageKeys[location?.pathname]] = true;
      localStorage.setItem('visited', JSON.stringify(newPageVisitedValues));
      setTimeout(
        () => {
          setDemo((prev) => ({
            ...prev,
            showJoyride: true,
            index: 0,
            screen: location?.pathname,
            steps: demoInfo?.steps || [],
            isControlled: !demoInfo?.continuous,
          }));
        },
        isUserOnActivities ? 1000 : 500
      );
    } else if (demo?.isFirstSkip && !demo?.isNavDemoShown) {
      setTimeout(() => {
        setDemo((prev) => ({
          ...prev,
          showJoyride: true,
          index: 0,
          screen: location?.pathname,
          steps: navbarSteps,
          isControlled: true,
          isFirstSkip: false,
          isNavDemoShown: true,
        }));
      }, 500);
    }
  }, [location, essayEditState, demo?.isFirstSkip]);

  const reset = useCallback((isFirstTimeSkip = false) => {
    setDemo((prev) => ({
      ...prev,
      showJoyride: false,
      manualTrigger: false,
      isApplicationBackgroundOpen: false,
      isActivitiesListOpen: false,
      isPlannerCollegesListOpen: false,
      index: 0,
      steps: [],
      isFirstSkip: isFirstTimeSkip,
    }));
  }, []);

  const verifyDelay = async (step = { target: '' } as any) => {
    if (step?.target === '.essay-create-section') {
      setIsAccordionOpen(false);
      setBrainstormerModal((prev) => ({
        ...prev,
        isCreateNewEssayModalOpen: true,
      }));
      await delay(100);
    } else if (step.target === '.planner-smart-plan') {
      setDemo((prev) => ({
        ...prev,
        isPlannerCollegesListOpen: true,
      }));
      await delay(100);
    }
  };

  const nextStep = useCallback(
    async (step = { target: '' } as any) => {
      await verifyDelay(step);
      setDemo((prev) => ({ ...prev, index: prev.index + 1 }));
    },
    [demo?.steps.length]
  );

  const transitionToOtherScreen = () => {
    reset();

    if (essayEditState === 0 && location?.pathname === Routes.Essays) {
      setEssayEditState(1);
    } else {
      if (essayEditState === 1 && location?.pathname === Routes.Essays) {
        setEssayEditState(0);
      }

      navigate(nextScreen[location?.pathname]);
    }
  };

  const handleCallback = async (data) => {
    const { action, type, step } = data;

    if ([ACTIONS.RESET, ACTIONS.CLOSE].includes(action)) {
      reset();
    }

    if ([EVENTS.STEP_BEFORE].includes(type)) {
      if (step.target === '.essay-create-section') {
        setIsAccordionOpen(true);
      }
    }

    if ([EVENTS.STEP_AFTER].includes(type)) {
      if (step.target === '#brainstormer-modal-section') {
        setBrainstormerModal((prev) => ({
          ...prev,
          isCreateNewEssayModalOpen: false,
        }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (step.target === '#header') {
        setDemo((prev) => ({
          ...prev,
          isApplicationBackgroundOpen: true,
        }));
      } else if (step.target === '.essay-writer-writing-tools') {
        setEssayStore((prev) => ({ ...prev, selectedTab: ESSAY_FEATURES_TAB.EssayReview }));
      } else if (step.target === '.essay-writer-review-button') {
        setEssayStore({ ...essayStore, selectedTab: ESSAY_FEATURES_TAB.EssayPlan });
      } else if (step.target === '.add-activity-button' || step.target === '.add-activity-button-mobile') {
        setDemo((prev) => ({
          ...prev,
          isActivitiesListOpen: true,
        }));
      }
    }
  };

  return {
    handleCallback,
    nextStep,
    reset,
    transitionToOtherScreen,
  };
};

export default useJoyRide;
