import { Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

const PromptsSkeleton: React.FC = () => (
  <VStack width={'100%'} height={'100%'} spacing={4}>
    {Array(5)
      .fill(0)
      .map((_, index) => (
        <Skeleton borderRadius={'lg'} width={'100%'} height={'20%'} key={index} startColor='gray2' endColor='gray1' />
      ))}
  </VStack>
);

export default PromptsSkeleton;
