import mixpanel, { Query } from 'mixpanel-browser';
import { useEffect } from 'react';
import { AnalyticsScreenName } from '../../../Constants/constants';
import { AnalyticEventName } from '../../../Models/analytics';
import { EnvId } from '../../../Models/config';
import { User } from '../../../Models/user';

const useMixpanel = (screenName?: AnalyticsScreenName) => {
  const MIXPANEL_TOKEN_DEV = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN_DEV;
  const MIXPANEL_TOKEN_PROD = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
  let MIXPANEL_TOKEN: string;

  if (MIXPANEL_TOKEN_DEV === undefined || MIXPANEL_TOKEN_PROD === undefined) {
    console.error('Token not defined.');
  } else {
    if (process.env.REACT_APP_NODE_ENV === EnvId.DEV || process.env.REACT_APP_VERCEL_ENV === EnvId.STAGING) {
      MIXPANEL_TOKEN = MIXPANEL_TOKEN_DEV;
    } else if (process.env.REACT_APP_NODE_ENV === EnvId.PROD || process.env.REACT_APP_VERCEL_ENV === EnvId.PROD) {
      MIXPANEL_TOKEN = MIXPANEL_TOKEN_PROD;
    }
  }

  const initializeMixpanel = () => {
    if (!MIXPANEL_TOKEN) {
      console.error('Mixpanel token is missing.');
      return;
    }

    mixpanel.init(MIXPANEL_TOKEN, { debug: true, track_pageview: true });
  };

  const trackEvent = async (
    eventName: AnalyticEventName,
    props?: { [key: string]: any },
    isLink?: boolean,
    query?: Query
  ): Promise<void> => {
    try {
      if (isLink && query) {
        mixpanel?.track_links(query, eventName, props);
      } else {
        mixpanel?.track(eventName, props);
      }
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  const trackScreen = async (screen: AnalyticsScreenName): Promise<void> => {
    mixpanel?.track(screen);
  };

  const identifyUser = async (user: User) => {
    const userId: string | undefined = user?.id?.toString();
    mixpanel?.identify(userId);
    mixpanel?.people.set_once({
      name: user?.firstname + ' ' + user?.lastname,
      $email: user?.email,
      verified: user?.email_verified,
    });
  };

  const resetUser = async () => {
    mixpanel?.reset();
  };

  useEffect(() => {
    if (screenName) trackScreen(screenName);
  }, []);

  return {
    initializeMixpanel,
    trackEvent,
    trackScreen,
    identifyUser,
    resetUser,
  };
};

export default useMixpanel;
