import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

const useSize = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
    window.innerWidth / window.innerHeight,
  ]);

  useEffect(() => {
    const windowSizeHandler = debounce(() => {
      setWindowSize([window.innerWidth, window.innerHeight, window.innerWidth / window.innerHeight]);
    }, 300);

    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
      windowSizeHandler.cancel();
    };
  }, []);

  return windowSize;
};

export default useSize;
