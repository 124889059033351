import React from 'react';
import DropdownSelect, { CustomSelectProps } from '../../../lib/UI-Components/Select';

interface ProfileDropdownSelectProps extends CustomSelectProps {
  showLabel?: boolean;
  name: string;
  height?: string;
  backgroundColor?: string;
  fontSize?: string;
}

const ProfileDropdownSelect: React.FC<ProfileDropdownSelectProps> = ({
  showLabel = true,
  name,
  height = '48px',
  backgroundColor = 'gray1',
  fontSize = '16px',
  ...props
}) => (
  <DropdownSelect
    showLabel={showLabel}
    name={name}
    h={height}
    backgroundColor={backgroundColor}
    fontSize={fontSize}
    borderRadius={'xl'}
    paddingBottom={0}
    paddingTopInStyle={'8px'}
    isPlaceholderDisabled={false}
    {...props}
  />
);

export default ProfileDropdownSelect;
