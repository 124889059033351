import { TASK_TYPES } from 'Constants/planner';
import { GroupedTaskByDate, ResultGroupedTaskByDate, Task } from 'planner';
import countryList from 'react-select-country-list';
import { UsaStates } from 'usa-states';
import { formatDate } from './colleges';

const usStates = new UsaStates();
export const STATE_NAMES = usStates.arrayOf('names');

const countries = countryList().getLabels(); // Retrieve country labels

const unitedStatesIndex = countries.findIndex((label: any) => label === 'United States');

if (unitedStatesIndex !== -1) {
  const [unitedStatesLabel] = countries.splice(unitedStatesIndex, 1); // Remove the United States label
  countries.unshift(unitedStatesLabel); // Place United States at the beginning of the array
}

export const COUNTRIES = countries;

export function isEmpty(value: any) {
  return value === null || value === undefined;
}

export function isMobile() {
  let hasTouchScreen = false;
  const isSmallScreen = window.innerWidth <= 480;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  if (hasTouchScreen || isSmallScreen) {
    // Device is likely mobile, so do stuff for mobile devices here.
    return true;
  }

  return false;
}

export function isMiniTablet() {
  const isTabletScreen = window.innerWidth > 480 && window.innerWidth <= 991;

  return isTabletScreen;
}

export function isLargertoMidTablet() {
  const isTabletScreen = window.innerWidth > 991 && window.innerWidth <= 1024;

  return isTabletScreen;
}

export function isTablet() {
  let hasTouchScreen = false;
  const isSmallScreen = window.innerWidth <= 1000;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  if (hasTouchScreen || isSmallScreen) {
    return true;
  }

  return false;
}

export function detectDarkMode() {
  const lightSchemeIcon = document.querySelector('link#light-scheme-icon');
  const darkSchemeIcon = document.querySelector('link#dark-scheme-icon');
  const matcher = window.matchMedia('(prefers-color-scheme: dark)');

  function onUpdate() {
    if (lightSchemeIcon && darkSchemeIcon) {
      if (matcher.matches) {
        lightSchemeIcon.remove();
      } else {
        document.head.append(lightSchemeIcon);
        darkSchemeIcon.remove();
      }
    }
  }

  onUpdate();
  matcher.addEventListener('change', onUpdate);

  return () => {
    matcher.removeEventListener('change', onUpdate);
  };
}

export const demoStyles = {
  spotlight: {
    borderRadius: 12,
  },
  options: {
    arrowColor: '#f6f6f6',
    primaryColor: '#035A41',
    textColor: '#49505F',
    backgroundColor: 'white',
    zIndex: 2000,
  },
};

export const convertToHexWithTransparency = (hexValue: string, transparency: string) => hexValue + transparency;

export const checkEmbeddedBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Check for specific strings commonly found in embedded browser user agents
  const embeddedBrowserStrings = [
    'instagram',
    'facebook',
    'fbav',
    'twitter',
    'whatsapp',
    'linkedin',
    'pinterest',
    'snapchat',
    // Add more strings as needed
  ];

  return embeddedBrowserStrings.some((str) => userAgent.includes(str));
};

export const getImage = (image?: string) => require(`../Assets/icons/${image}.svg`);

export const getLetterFromIndex = (letterIndex: number) => String.fromCharCode(letterIndex + 'A'.charCodeAt(0));

export const COUNTRIES_SUPPORTED_BY_JVECTORMAP = [
  'AF',
  'AL',
  'DZ',
  'AO',
  'AR',
  'AM',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BD',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BT',
  'BO',
  'BA',
  'BW',
  'BR',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CF',
  'TD',
  'CL',
  'CN',
  'CO',
  'CG',
  'CR',
  'HR',
  'CU',
  'CY',
  'CZ',
  'CI',
  'CD',
  'KP',
  'DK',
  'DJ',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FJ',
  'FI',
  'TF',
  'FR',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GR',
  'GL',
  'GT',
  'GN',
  'GW',
  'GY',
  'HT',
  'HN',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LT',
  'LU',
  'MK',
  'MG',
  'MW',
  'MY',
  'ML',
  'MR',
  'MX',
  'MD',
  'MN',
  'ME',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NO',
  'OM',
  'PK',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RU',
  'RW',
  'SS',
  'SA',
  'SN',
  'RS',
  'SL',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'ES',
  'LK',
  'SD',
  'SR',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TT',
  'TN',
  'TR',
  'TM',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'EH',
  'YE',
  'ZM',
  'ZW',
];

export const STATES_SUPPORTED_BY_JVECTORMAP = [
  'US-VA',
  'US-PA',
  'US-TN',
  'US-WV',
  'US-NV',
  'US-TX',
  'US-NH',
  'US-NY',
  'US-HI',
  'US-VT',
  'US-NM',
  'US-NC',
  'US-ND',
  'US-NE',
  'US-LA',
  'US-SD',
  'US-DC',
  'US-DE',
  'US-FL',
  'US-CT',
  'US-WA',
  'US-KS',
  'US-WI',
  'US-OR',
  'US-KY',
  'US-ME',
  'US-OH',
  'US-OK',
  'US-ID',
  'US-WY',
  'US-UT',
  'US-IN',
  'US-IL',
  'US-AK',
  'US-NJ',
  'US-CO',
  'US-MD',
  'US-MA',
  'US-AL',
  'US-MO',
  'US-MN',
  'US-CA',
  'US-IA',
  'US-MI',
  'US-GA',
  'US-AZ',
  'US-MT',
  'US-MS',
  'US-SC',
  'US-RI',
  'US-AR',
];

export const enum USER_TYPES {
  PARENT = 'PARENT',
  HIGH_SCHOOLER = 'HIGH_SCHOOLER',
  COLLEGE_STUDENT = 'COLLEGE_STUDENT',
  TEACHER = 'TEACHER',
  OTHER = 'OTHER',
}

export const isInteger = (str: string) => /^\d+$/.test(str);

export const getCookie = (name: string): string | undefined => {
  const value = `; ${document?.cookie}`;
  const parts = value?.split(`; ${name}=`);
  if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
  return undefined;
};

export const getWordCount = (text: string): number =>
  text
    ?.trim()
    .split(/\s+/)
    .filter((word) => word?.length > 0 && /\w/.test(word))?.length;

export const stripHtmlTags = (str: string) => {
  if (!str) return '';
  return str.replace(/<[^>]*>/g, '');
};

export const deltaToText = (delta: { ops: any[] }) => {
  let text = '';
  const indexMap: number[] = []; // Array to keep track of character indices

  delta.ops.forEach((op: any) => {
    if (op.insert) {
      if (typeof op.insert === 'string') {
        for (let i = 0; i < op.insert?.length; i++) {
          indexMap.push(text?.length);
          text += op.insert[i];
        }
      } else if (op.insert.image || op.insert.video) {
        indexMap.push(text?.length);
        text += ' ';
      }
    }
  });

  return { text, indexMap };
};

export const getSentenceStartIndices = (delta: any) => {
  const { text, indexMap } = deltaToText(delta);

  const sentenceRegex = /([^.!?]+[.!?])/g;
  const sentences = text.match(sentenceRegex) || [];

  const sentenceIndices: number[] = [];
  let currentIndex = 0;

  sentences.forEach((sentence) => {
    const sentenceStart = text.indexOf(sentence, currentIndex);
    sentenceIndices.push(indexMap[sentenceStart]);
    currentIndex = sentenceStart + sentence?.length;
  });

  return sentenceIndices;
};

export const getSentenceIndex = (delta: any, targetSentence: string) => {
  const { text, indexMap } = deltaToText(delta);
  const sentenceStartIndices = getSentenceStartIndices(delta);

  // Find the start index of the target sentence
  const startIndex = text.indexOf(targetSentence);

  if (startIndex === -1) {
    return -1; // Sentence not found
  }

  // Find the sentence index based on the start index
  for (let i = 0; i < sentenceStartIndices?.length; i++) {
    if (indexMap[startIndex] === sentenceStartIndices[i]) {
      return i;
    }
  }

  return -1; // Sentence not found in the mapped indices
};

export function groupTasksByDate(tasks: Task[]): GroupedTaskByDate[] {
  if (tasks?.length === 0) {
    return [];
  }

  const groupedTasks = tasks.reduce<Record<string, Task[]>>((acc, task) => {
    const localDate = new Date(task.deadline);
    let dateString: string;

    if (task?.type === TASK_TYPES.COLLEGE) {
      const formattedDate = formatDate(task?.deadline);
      const formattedDateParts = formattedDate.split(', ');
      const [monthAndDay, year] = formattedDateParts;
      const [monthName, day] = monthAndDay.split(' ');

      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      const monthIndex = monthNames.indexOf(monthName) + 1;
      const month = String(monthIndex).padStart(2, '0');
      const formattedDay = String(day).padStart(2, '0');
      dateString = `${year}-${month}-${formattedDay}`;
    } else {
      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, '0');
      const day = String(localDate.getDate()).padStart(2, '0');
      dateString = `${year}-${month}-${day}`;
    }

    if (!acc[dateString]) {
      acc[dateString] = [];
    }

    acc[dateString].push(task);
    return acc;
  }, {});

  // eslint-disable-next-line @typescript-eslint/no-shadow
  return Object.entries(groupedTasks).map(([date, tasks]) => ({
    date,
    tasks,
  }));
}

export function findClosestEvent(groupedTasksByDate: GroupedTaskByDate[]): ResultGroupedTaskByDate[] {
  if (groupedTasksByDate?.length === 0) {
    return [];
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of the day
  let closestEvent: string | null = null;
  let closestDateDiff = Infinity;
  let todayFound = false;

  groupedTasksByDate.forEach((groupedTaskByDate) => {
    const deadline = new Date(groupedTaskByDate?.tasks[0]?.deadline);
    deadline.setHours(0, 0, 0, 0);

    if (deadline.getTime() === today.getTime()) {
      closestEvent = groupedTaskByDate.date;
      todayFound = true;
    }

    const dateDiff = deadline.getTime() - today.getTime();

    if (!todayFound && dateDiff > 0 && dateDiff < closestDateDiff) {
      closestDateDiff = dateDiff;
      closestEvent = groupedTaskByDate.date;
    }
  });

  const result = groupedTasksByDate?.map((groupedTask) => ({
    ...groupedTask,
    isTodayOrClosestPossible: groupedTask.date === closestEvent,
  }));

  return result;
}

export function getNextClosestTasks(tasks: Task[]) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const filteredTasks = tasks.filter((task: Task) => new Date(task.deadline) >= today);

  filteredTasks.sort((a, b) => {
    const dateA = new Date(a?.deadline);
    const dateB = new Date(b?.deadline);
    return dateA.getTime() - dateB.getTime();
  });

  const resultTasks = [];
  let taskCount = 0;

  for (const task of filteredTasks) {
    resultTasks.push(task);
    taskCount += 1;

    if (taskCount >= 5) {
      break;
    }
  }

  return resultTasks;
}

export const convert = (values: any[]) => {
  const result = values.reduce((acc, value) => {
    acc[value] = value;
    return acc;
  }, {});

  return result;
};

export const getWeekday = (dateValue: string | Date): string => {
  const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;

  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  return date.toLocaleString('en-US', { weekday: 'long', timeZone: 'UTC' });
};

export const getMonthName = (dateValue: string | Date): string => {
  const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;

  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  return date.toLocaleString('en-US', { month: 'long' });
};

export const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
