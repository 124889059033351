import { VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import useUpdateFirstUXQuestions from '../../Hooks/FirstUX/useUpdateFirstUX.tsx';
import TwoColumnLayout from '../../lib/UI-Components/TwoColumnLayout.tsx';
import Section1 from './Section1.tsx';
import Section2 from './Section2.tsx';
import Section3 from './Section3.tsx';

const Questions = () => {
  const [intake, setIntake] = useState('');
  const [interest, setInterest] = useState('');
  const [study, setStudy] = useState('');
  const [route, setRoute] = useState('');

  const { handleSubmission, loading } = useUpdateFirstUXQuestions();

  return (
    <TwoColumnLayout textBelowAnimation='Building Your Personalized Experience...' reverse>
      <VStack
        height='full'
        width='100%'
        paddingX={[5, 5, 16, 16, 16]}
        paddingY={[5, 5, 10, 10, 10]}
        alignItems='center'
        justifyContent='start'
        spacing={[8, 8, 8, 10, 10]}
        overflow={['initial', 'initial', 'initial', 'hidden']}
        position='relative'
      >
        <Section1 intake={intake} setIntake={setIntake} />

        <Section2 interest={interest} setInterest={setInterest} setRoute={setRoute} />

        <Section3
          handleSubmission={() => handleSubmission(intake, study, route, interest)}
          isDisabled={!(intake && interest && study)}
          setStudy={setStudy}
          study={study}
          isLoading={loading}
        />
      </VStack>
    </TwoColumnLayout>
  );
};

export default Questions;
