import axios from 'axios';

class Profile {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async getQuestions() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/questions/`);
      return response.data?.questions;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async getAnswers(userId: number | null) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/questions/answers/${userId}`);
      return response.data?.answers;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async updateBackgroundAnswer(data: any, userId: number | null) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/questions/answers/${userId}`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async updateProfile(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/user/update`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async updateUserName(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/user/updateName`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
const profileService = new Profile(`${process.env.REACT_APP_BASE_URL}`);
export default profileService;
