import { useAtom, useSetAtom } from 'jotai';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAtom } from '../../Atoms/App/user';
import { dashboardAtom } from '../../Atoms/dashboardAtom';
import { Routes } from '../../Constants/constants';
import { getStoredQueryParams } from '../../Utils/auth';
import { checkEmbeddedBrowser } from '../../Utils/common';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import usePixel from '../App/usePixel';

const useGoogleOAuthCode = (
  method: 'login' | 'signup',
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const setUser = useSetAtom(userAtom);
  const { identifyUser, trackEvent } = useMixpanel();
  const [isEmbeddedBrowser, setIsEmbeddedBrowser] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useAtom(dashboardAtom);
  const { trackCustomEvent } = usePixel();

  const pageVisits = {
    home: false,
    profile: false,
    colleges: false,
    essay_selection: false,
    essay_editor: false,
    activities: false,
    access_demo: false,
    planner: false,
  };

  const checkCodeValid = async (code: any, referrerCode: string | null) => {
    const fetchURL =
      method === 'login'
        ? `${BASE_URL}/auth/api/sessions/oauth/google/login`
        : `${BASE_URL}/auth/api/sessions/oauth/google/signup`;

    try {
      const res = await fetch(fetchURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          code,
          redirect_uri:
            method === 'login' ? process.env.REACT_APP_URL + Routes.Login : process.env.REACT_APP_URL + Routes.Join,
          ...(referrerCode?.trim() && { referrerCode }),
        }),
      });

      if (!res || !res.ok || res.status >= 400) {
        return false;
      }

      const data = await res.json();

      if (!data) {
        return false;
      }

      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const code = urlParams.get('code');
    const state = urlParams.get('state'); // Retrieve the state parameter

    const referrer = decodeURIComponent(state || '');

    window.history.replaceState({}, document.title, method === 'login' ? Routes.Login : Routes.Join);

    if (code) {
      setIsLoading(true);
      checkCodeValid(code, referrer).then((data) => {
        setIsLoading(false);

        if (!data) {
          setUser({ loggedIn: false });
          return;
        }

        if (data.loggedIn === false) {
          setError(data?.status);

          return;
        }

        const { utmParams, referralParams, variant } = getStoredQueryParams();

        if (method === 'signup') {
          posthog.capture('Sign Up Success, Google auth', {
            mail: data?.email,
            userId: data?.id,
            variant,
            ...utmParams,
            ...referralParams,
          });

          trackEvent('Sign Up Success, Google auth', {
            email: data?.email,
            userId: data?.id,
            variant,
            ...utmParams,
            ...referralParams,
          });

          trackCustomEvent('Sign Up Success', {
            email: data?.email,
            contents: ['google'],
          });
        }

        setUser({ ...data });
        localStorage.setItem('user', JSON.stringify(data));

        if (data?.first_time_login) {
          posthog.capture('Routing to First Time User', {
            mail: data?.email,
            userId: data?.id,
            variant,
            ...utmParams,
            ...referralParams,
          });
          navigate(Routes.FirstTimeUX);
        } else {
          navigate(Routes.Home);
        }

        if (method === 'signup') {
          const user = {
            id: data?.id,
          };

          localStorage.setItem('visited', JSON.stringify({ user, pageVisits }));
        }

        if (data.loggedIn) {
          identifyUser(data);
          trackEvent('Sign In Success', {
            email: data?.email,
            userId: data?.id,
          });
          setDashboard({ ...dashboard, isMobilePopUpVisible: true });
        }
      });
    }

    if (checkEmbeddedBrowser()) {
      setIsEmbeddedBrowser(true);
    }
  }, []);

  return {
    isEmbeddedBrowser,
  };
};

export default useGoogleOAuthCode;
