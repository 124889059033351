import axios from "axios";


class NotesService {
    apiBaseUrl: any;
    constructor(apiBaseUrl: any) {
        this.apiBaseUrl = apiBaseUrl;
    }

    async getNotes(userId: number) {
        try {
            const response = await axios.get(`${this.apiBaseUrl}/notes/${userId}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching notes:", error);
            throw error;
        }
    }

    async addNote(data: any) {
        try {
            const response = await axios.post(`${this.apiBaseUrl}/notes/add`, data);
            return response.data;
        } catch (error) {
            console.error("Error adding note:", error);
            throw error;
        }
    }
    async deleteNote(noteId: number) {
        try {
            const response = await axios.delete(`${this.apiBaseUrl}/notes/${noteId}`);
            return response.data;
        } catch (error) {
            console.error("Error deleting note:", error);
            throw error;
        }
    }
    async updateNote(noteId: number | null, data: any) {
        try {
            const response = await axios.put(`${this.apiBaseUrl}/notes/${noteId}`, data);
            return response.data;
        } catch (error) {
            console.error("Error updating note:", error);
            throw error;
        }
    }

    async updateNotesOrder(userId: number, data: any) {
        try {
            await axios.put(`${this.apiBaseUrl}/notes/order/${userId}`, data);
        } catch (error) {
            console.error("Error updating notes order:", error);
            throw error;
        }
    }
}

const notesService = new NotesService(`${process.env.REACT_APP_BASE_URL}`);
export default notesService;
