import React from 'react';
import { Image, VStack, Text } from '@chakra-ui/react'; // @ts-ignore
import EmptyPlannerViewImage from './../../../Assets/images/empty-planner-view.svg';

const NoTasksView: React.FC = () => (
  <VStack
    width={'100%'}
    height={'100%'}
    justifyContent={'center'}
    alignItems={'center'}
    spacing={10}
    className='planner-smart-plan'
  >
    <VStack spacing={2}>
      <Image src={EmptyPlannerViewImage} alt='Empty Planner' />
      <Text color={'gray6'} fontWeight={400}>
        Click on Generate Plan to create tasks for your planner.
      </Text>
    </VStack>
  </VStack>
);

export default NoTasksView;
