import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import BeatLoader from 'react-spinners/BeatLoader';
import { customRenderers } from '../Essay/Editor/RightSection/Comments'; // @ts-ignore
import RoboThumbnail from './../../Assets/icons/robo-thumbnail.svg';
import { MESSAGES_TYPE } from './../../Constants/chatbots';

interface MessageBoxProps {
  message: string;
  timestamp: Date;
  type: string;
  isLoading: boolean;
}

const MessageBox: React.FC<MessageBoxProps> = ({ message, timestamp, type, isLoading }) => {
  const backgroundColor = type === MESSAGES_TYPE.USER ? 'rgba(62, 138, 117, 0.15)' : 'gray9';
  const borderRadius = type === MESSAGES_TYPE.USER ? '12px 0px 12px 12px' : '0px 12px 12px 12px';
  const alignItems = type === MESSAGES_TYPE.USER ? 'flex-end' : 'flex-start';
  return (
    <VStack width={'100%'} alignItems={alignItems} spacing={1}>
      <HStack spacing={1}>
        {type === MESSAGES_TYPE.USER ? (
          <Text fontSize={'sm'} color={'gray10'}>
            Me
          </Text>
        ) : (
          <HStack>
            <Image src={RoboThumbnail} />
            <Text>Kollegio</Text>
          </HStack>
        )}
        <Text fontSize={'xs'} color={'gray4'}>
          {timestamp
            ?.toLocaleString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
            })
            ?.toLowerCase()}
        </Text>
      </HStack>
      <Box borderRadius={borderRadius} backgroundColor={backgroundColor} padding={2}>
        {isLoading ? (
          <BeatLoader color='#98A2B3' size={'8px'} />
        ) : (
          <ReactMarkdown components={customRenderers('gray10')}>{message}</ReactMarkdown>
        )}
      </Box>
    </VStack>
  );
};

export default MessageBox;
