import { Box, HStack, Text, VStack, chakra } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react'; // @ts-ignore
import { ReactComponent as _FileIcon } from '../../../../Assets/icons/solar_document-broken.svg';
import { PlannerAtom } from '../../../../Atoms/Planner/planner';
import { MULTI_PROMPT_TYPES } from '../../../../Constants/planner';
import usePlanner from '../../../../Hooks/Planner/usePlanner';

const FileIcon = chakra(_FileIcon);
interface MultipleEssayPromptProps {
  prompt: any;
}

const MultipleEssayPrompt: React.FC<MultipleEssayPromptProps> = ({ prompt }) => {
  const { getSimilarPromptsByPromptId } = usePlanner();
  const [plannerAtom, setPlannerAtom] = useAtom(PlannerAtom);

  const onClickPrompt = () => {
    setPlannerAtom({
      ...plannerAtom,
      isTaskDrawerLoading: true,
    });
    getSimilarPromptsByPromptId(prompt);
  };

  return (
    <>
      {prompt?.type === MULTI_PROMPT_TYPES.PREFACE ? (
        <HStack width={'100%'} alignItems={'flex-start'}>
          <Text fontSize={'sm'} color='#667085' fontWeight={400}>
            {prompt?.text}
          </Text>
        </HStack>
      ) : (
        <Box
          width={'100%'}
          backgroundColor={'white'}
          border={'1px solid'}
          borderColor={'gray2'}
          borderRadius={'12px'}
          padding={4}
          cursor={'pointer'}
          onClick={onClickPrompt}
          _hover={{
            backgroundColor: 'gray9',
          }}
        >
          <VStack width={'100%'} alignItems={'flex-start'}>
            <HStack width='100%' alignItems={'flex-start'}>
              <Box width='5%'>
                <FileIcon boxSize={6} pt={1} color='primartLight' />
              </Box>
              <Text width='95%' fontSize='sm' color='#667085' fontWeight={400}>
                {prompt?.text}
              </Text>
            </HStack>
            {prompt?.wordCount && (
              <Text fontSize={'xs'} color='#667085' fontWeight={400}>
                {`Word Count: ${prompt?.wordCount}`}
              </Text>
            )}
          </VStack>
        </Box>
      )}
    </>
  );
};

export default MultipleEssayPrompt;
