import { IconButton, VStack, chakra } from '@chakra-ui/react';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { ReactComponent as _HidePasswordIcon } from './../../Assets/images/hide-password.svg';
// @ts-ignore
import { ReactComponent as _ShowPasswordIcon } from './../../Assets/icons/show-password.svg';

const HidePasswordIcon = chakra(_HidePasswordIcon);
const ShowPasswordIcon = chakra(_ShowPasswordIcon);

const labelStyle = (isError) => ({
  position: 'absolute',
  color: isError ? 'red' : '#98A2B3',
  zIndex: 1000,
  fontSize: '10px',
  alignSelf: 'flex-start',
  marginLeft: '16px',
  marginTop: '4px',
});

const fieldInputStyle: React.CSSProperties = {
  width: '100%',
  borderRadius: '12px',
  border: '1px solid rgb(226, 232, 240)',
  backgroundColor: '#F9FAFB',
  paddingTop: '14px',
  paddingLeft: '18px',
  paddingRight: '18px',
  paddingBottom: '10px',
};

interface ICustomFormikFieldPropTypes {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  errors: any;
  isFormDirty: boolean;
  value: string;
  inputStyle?: React.CSSProperties;
}

const CustomFormikField: React.FC<ICustomFormikFieldPropTypes> = ({
  label,
  name,
  type,
  errors,
  value,
  isFormDirty,
  placeholder,
  inputStyle = fieldInputStyle,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formLabel, setFormLabel] = useState('');

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (errors[name] && isFormDirty) {
      setFormLabel(errors[name]);
    } else if (value !== '') {
      setFormLabel(label);
    } else {
      setFormLabel('');
    }
  }, [value, label, errors, name, isFormDirty]);

  return (
    <VStack width='100%'>
      <span style={labelStyle(errors[name] && isFormDirty) as React.CSSProperties}>{formLabel}</span>
      <Field style={inputStyle} placeholder={placeholder} type={showPassword ? 'text' : type} name={name} {...rest} />
      {type === 'password' && (
        <IconButton
          style={{
            position: 'absolute',
            display: 'flex',
            alignSelf: 'flex-end',
            marginTop: '0.5%',
            marginRight: '1%',
          }}
          background={'transparent'}
          onClick={toggleShowPassword}
          type='button'
          aria-label={'show-hide-password'}
          icon={showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
          _hover={{ background: 'transparent' }}
        />
      )}
    </VStack>
  );
};

export default CustomFormikField;
