import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import CircularProgressBar from './CircularProgressBar';

interface ProgressDonutProps {
  value: number;
  label: string;
}

const ProgressDonut: React.FC<ProgressDonutProps> = ({ value, label }) => {
  const adjustedValue = value > 100 ? 100 : value;
  const color = adjustedValue > 75 ? 'green' : adjustedValue > 35 ? 'yellow' : 'orange';

  return (
    <>
      <CircularProgressBar max={100} value={adjustedValue} size={'32px'} label={''} color={color} />
      <Stack
        flexDirection={['column', 'column', 'row', 'column']}
        color={'gray.500'}
        fontWeight={['semibold', 'semibold']}
        alignItems={['flex-start', 'flex-start', 'flex-end', 'flex-start']}
        gap={['0', '0', '2', '0']}
      >
        <Text fontSize={['xs', 'xs', 'sm', 'md']}>{`${adjustedValue}%`}</Text>
        <Text fontSize={['xs', 'xs', 'xs', 'xs']}>{label}</Text>
      </Stack>
    </>
  );
};

export default ProgressDonut;
