import { array, boolean, number, object, ref, string } from 'yup';

const formSchema = object({
  email: string().required('Email required'),
  password: string().required('Password required').min(6, 'Password too short').max(28, 'Password too long!'),
});

const forgotPasswordFormSchema = object({
  email: string().required('Email required'),
});

const resetPasswordSchema = object({
  password: string().required('Password required').min(6, 'Password too short').max(28, 'Password too long!'),
  confirm_password: string()
    .required('Confirm Password is required')
    .oneOf([ref('password')], 'Passwords must match'),
});

const registerFormSchema = object({
  email: string().required('Email required'),
  firstName: string().required('First name required'),
  lastName: string().required('Last name required'),
  password: string().required('Password required').min(6, 'Password too short').max(28, 'Password too long!'),
  confirmPassword: string()
    .required('Confirm Password is required')
    .oneOf([ref('password')], 'Passwords must match'),
});

const changePasswordFormSchema = object({
  id: number().required('ID required'),
  oldPassword: string().required('Password required'),
  newPassword: string().required('Must enter a new password'),
});

const settingsSchema = object({
  country: string(),
  state: string(),
  income: string(),
  gender: string(),
  ethnicity: string(),
  gpa: number().min(0, 'GPA too low').max(5, 'GPA too high'),
  satmath: number().min(200, 'Invalid SAT Math score').max(800, 'Invalid SAT Math score'),
  satenglish: number().min(200, 'Invalid SAT English score').max(800, 'Invalid SAT English score'),
  act: number().min(0, 'Invalid ACT score').max(36, 'Invalid ACT score'),
  highschool: string(),
  classrank: number().min(1, 'Invalid class rank'),
  gradyear: number(),
});

const updateUserFormSchema = object({
  id: number().required('ID required'),
  country: string(),
  state: string(),
  income: string(),
  gpa: number(),
  satmath: number(),
  satenglish: number(),
  act: number(),
  highschool: string(),
  classrank: number(),
  gradyear: number(),
  application_ids: array(),
  activity_ids: array(),
});

const activitySchema = object({
  title: string().required('Title required').max(50, 'Title too long!'),
  organization: string().max(100, 'Organization too long!'),
  description: string().required('Description required'),
  nine: boolean().required('Year 9 required'),
  ten: boolean().required('Year 10 required'),
  eleven: boolean().required('Year 11 required'),
  twelve: boolean().required('Year 12 required'),
  pg: boolean().required('Year PG required'),
  hoursperweek: number()
    .required('HPW Required')
    .max(168, 'Cannot enter more hours than in a week!')
    .min(0, 'Cannot enter less than 0 hours!'),
  weeksperyear: number()
    .required('WPY Required')
    .max(52, 'Cannot enter more weeks than in a year!')
    .min(0, 'Cannot enter less than 0 weeks!'),
  continued: boolean().required('Continue required'),
  category: string().required('Category required'),
});

const activityFormSchema = object({
  user_id: number().required('ID required'),
  title: string().required('Title required').max(50, 'Title too long!'),
  organization: string().max(100, 'Organization too long!'),
  description: string().required('Description required').max(150, 'Description too long!'),
  nine: boolean().required('Year 9 required'),
  ten: boolean().required('Year 10 required'),
  eleven: boolean().required('Year 11 required'),
  twelve: boolean().required('Year 12 required'),
  pg: boolean().required('Year PG required'),
  hoursperweek: number().required('Hours weekly required').max(168, 'Hours weekly too long!'),
  weeksperyear: number().required('Weeks yearly required').max(52, 'Weeks yearly too long!'),
  continued: boolean().required('Continue required'),
  category: string().required('Category required'),
  position: number().required('Position required'),
});

const updateActivityFormSchema = object({
  activity_id: number().required('ID required'),
  title: string().required('Title required').max(50, 'Title too long!'),
  organization: string().max(100, 'Organization too long!'),
  description: string().required('Description required'),
  nine: boolean().required('Year 9 required'),
  ten: boolean().required('Year 10 required'),
  eleven: boolean().required('Year 11 required'),
  twelve: boolean().required('Year 12 required'),
  pg: boolean().required('Year PG required'),
  hoursperweek: number().required('Hours weekly required').max(168, 'Hours weekly too long!'),
  weeksperyear: number().required('Weeks yearly required').max(52, 'Weeks yearly too long!'),
  continued: boolean().required('Continue required'),
  category: string().required('Category required'),
  position: number().required('Position required'),
});

const updateActivityPositionFormSchema = object({
  activity_id: number().required('ID required'),
  position: number().required('Position required'),
});

const getActivityFormSchema = object({
  user_id: number().required('ID required'),
});

const deleteActivityFormSchema = object({
  activity_id: number().required('ID required'),
});

const essayScoreFormSchema = object({
  essay: string().required('Essay required'),
});

const getApplicationFormSchema = object({
  ids: array().required('IDs required'),
});

const updateApplicationFormSchema = object({
  id: number().required('ID required'),
  essay: string().required('Essay required'),
});

const handleNumandDotPress = (e: any) => {
  if (
    (e.key >= '0' && e.key <= '9') ||
    e.key === 'Backspace' ||
    e.key === 'Delete' ||
    e.key === 'Tab' ||
    e.key === 'Escape' ||
    e.key === 'Enter' ||
    e.key === '.'
  ) {
    return;
  }

  e.preventDefault();
};

const handleNumPress = (e: any) => {
  if (
    (e.key >= '0' && e.key <= '9') ||
    e.key === 'Backspace' ||
    e.key === 'Delete' ||
    e.key === 'Tab' ||
    e.key === 'Escape' ||
    e.key === 'Enter'
  ) {
    return;
  }

  e.preventDefault();
};

export {
  activityFormSchema,
  activitySchema,
  changePasswordFormSchema,
  deleteActivityFormSchema,
  essayScoreFormSchema,
  forgotPasswordFormSchema,
  formSchema,
  getActivityFormSchema,
  getApplicationFormSchema,
  handleNumPress,
  handleNumandDotPress,
  registerFormSchema,
  resetPasswordSchema,
  settingsSchema,
  updateActivityFormSchema,
  updateActivityPositionFormSchema,
  updateApplicationFormSchema,
  updateUserFormSchema,
};
