import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useAtom, useSetAtom } from 'jotai';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  activityForm,
  activityScore,
  currentActivityDescription,
  currentActivityTitle,
  isFormDirty,
  isUpdateModalOpen,
  openActivityModal,
  openUploadModal,
} from '../../../Atoms/Activities/activityAtom';
import { essayEditStateAtom } from '../../../Atoms/Essays/editorAtom';
import { AnalyticsScreenName, HEADER_LINKS_KOLLEGIO } from '../../../Constants/constants';
import { getImage } from '../../../Utils/common';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import NavLink from '../../../lib/UI-Components/NavLink';
import UpdateModal from '../../../lib/UI-Components/UpdateModal';

interface LogoSectionProps {
  isUserOnPublicRoute: any;
  location: any;
  setLocation: any;
  setAreNotesSelected: any;
  setIsGuideSelected: any;
}

const LinksSection = ({
  isUserOnPublicRoute,
  location,
  setLocation,
  setAreNotesSelected,
  setIsGuideSelected,
}: LogoSectionProps) => {
  const { trackEvent } = useMixpanel();
  const setEssayEditState = useSetAtom(essayEditStateAtom);
  const setIsOpen = useSetAtom(openActivityModal);
  const setIsUploadModalOpen = useSetAtom(openUploadModal);
  const [isDirtyForm, setIsDirtyForm] = useAtom(isFormDirty);
  const setIsUpdateModalOpen = useSetAtom(isUpdateModalOpen);
  const setActivityTitle = useSetAtom(currentActivityTitle);
  const setActivityDescription = useSetAtom(currentActivityDescription);
  const setActivityScore = useSetAtom(activityScore);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const navigate = useNavigate();
  const setActivityData = useSetAtom(activityForm);

  const proceedWithNavigation = (key: string) => {
    setLocation(key);
    setAreNotesSelected(false);
    setIsGuideSelected(false);
    setEssayEditState(0);
    setIsOpen(false);
    setIsUploadModalOpen(false);
    setActivityTitle(null);
    setActivityDescription(null);
    setActivityScore(0);
    setActivityData({
      isAddActivityForm: false,
      position: null,
      activityId: null,
      formData: {},
      leadership: 0,
    });
    trackEvent(`Open ${key}`, {
      screenName: AnalyticsScreenName.ScreenHeader,
      label: `Click on ${key} link in Header`,
      isLink: true,
      query: '#menu-header a',
    });
    navigate(`/${key.toLowerCase()}`); // Programmatic navigation
  };

  const handleNavigation = useCallback(
    (key: string) => {
      if (isDirtyForm) {
        setPendingNavigation(key);
      } else {
        proceedWithNavigation(key);
      }
    },
    [isDirtyForm, setIsUpdateModalOpen]
  );

  const handleModalClose = () => {
    setPendingNavigation(null);
  };

  const handleLeavePage = () => {
    if (pendingNavigation) {
      proceedWithNavigation(pendingNavigation);
    }

    setIsDirtyForm(false);
    handleModalClose();
  };

  return (
    <>
      {!isUserOnPublicRoute && (
        <HStack
          id='menu-header'
          h='full'
          as={'nav'}
          justifyContent={'center'}
          display={['none', 'none', 'none', 'flex', 'flex']}
        >
          {Object.entries(HEADER_LINKS_KOLLEGIO).map(([key, value]) => (
            <NavLink
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation behavior
                handleNavigation(key);
              }}
              key={key}
              to={`/${key.toLowerCase()}`}
              borderRadius={0}
              borderBottom={location === key ? '3px solid #3E8A75' : 'none'}
            >
              <Box key={key} px={3} h='full'>
                <VStack spacing={0} h={'100%'}>
                  <Image
                    src={location === key ? getImage('green' + value.toLowerCase()) : getImage(value.toLowerCase())}
                    w={4}
                    h={4}
                  />
                  <Text
                    color={location === key ? 'primaryLight' : 'gray4'}
                    fontWeight={location === key ? 'semibold' : 'normal'}
                    fontSize={'sm'}
                  >
                    {value}
                  </Text>
                </VStack>
              </Box>
            </NavLink>
          ))}
        </HStack>
      )}
      <UpdateModal
        isOpen={!!pendingNavigation}
        onClose={handleModalClose} // Corrected to properly close the modal
        onConfirm={handleLeavePage} // Navigate and close modal on confirmation
        modalHeaderText='Are you sure?'
        bodyText='Leaving this page will result in your unsaved changes not being saved.'
        btnText1='Leave Page'
        btnText2='Back to Page'
      />
    </>
  );
};

export default LinksSection;
