import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import './../../../Styles/pop-over.css';

const GPAConversionInfoTip = () => {
  const placement = useBreakpointValue({ base: 'bottom', sm: 'bottom', md: 'bottom', lg: 'right' }) || 'right';
  const fontSize = useBreakpointValue({ base: '10px', sm: '10px', md: '10px', lg: '14px' });
  return (
    <Popover trigger='hover' placement={placement as PlacementWithLogical}>
      <PopoverTrigger>
        <InfoOutlineIcon boxSize={4} color='primary' />
      </PopoverTrigger>
      <PopoverContent
        width={['300px', '600px']}
        backgroundColor={'gray6'}
        boxShadow={'0px 0px 4px 0px rgba(73, 80, 95, 0.25)'}
        color='white'
        borderRadius={'3xl'}
      >
        <PopoverBody>
          <p
            style={{
              textAlign: 'left',
              padding: '10px',
              fontSize: fontSize,
            }}
          >
            <strong>Converting Your Grades to a 5.0 GPA:</strong> <br />
            If you&apos;re using IB, A-Level, or another grading system, here&apos;s how you can convert your grades to
            match the 5.0 GPA scale used in this app: <br />
            <strong>IB Students:</strong> <br />
            <ul>
              <li>Sum your predicted or internal grades (out of 45)</li>
              <li>Divide the sum by 45 and multiply the result by 5</li>
              <li>Example: A score of (36/45) x 5 = 4.0 GPA on a 5.0 scale</li>
            </ul>
            <strong>A-Level Students:</strong> <br />
            <ul>
              <li>
                Convert grades to the following scale: A* = 5.0, A = 4.5, B = 4.0, C = 3.5, D = 3.0, E = 2.0, U = 0.0
              </li>
              <li>Average your grades across all subjects to get your GPA on a 5.0 scale</li>
              <li>
                So If you have 4 subjects, with A*, C, C, D as your grades, you then have (5.0 + 3.5 + 3.5 + 3)/4 = 3.75
                as the converted GPA on a 5.0 scale
              </li>
            </ul>
            <strong>Indian System:</strong> <br />
            <ul>
              <li>Divide your average score (on a 10-point scale) by 2 to get your GPA out of 5.0</li>
              <li>Example: 8.0/10 = 4.0/5.0.</li>
            </ul>
            <strong>4.0 to 5.0 Conversion Tip:</strong> <br />
            <ul>
              <li>Multiply your GPA by 1.25 to convert from a 4.0 scale to a 5.0 scale.</li>
              <li>Example: (3.6/4.0) * 1.25 = 4.5/5.0</li>
            </ul>
          </p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default GPAConversionInfoTip;
