import { Spinner } from '@chakra-ui/react';
import { userAtom } from 'Atoms/App/user';
import { useSetAtom } from 'jotai';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import authService from '../Services/auth';
import { isPublicRoute } from '../Utils/auth'; // Adjust path if necessary

const UserManager: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const setUserAtom = useSetAtom(userAtom);
  const location = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL || '';

  useEffect(() => {
    const fetchUser = async () => {
      if (!isPublicRoute(location.pathname)) {
        try {
          authService
            .loginWithCookies()
            .then((response: any) => {
              if (response.status === 401) {
                throw new Error('Unauthorized');
              }

              const { data } = response;

              if (data && data.loggedIn) {
                setUserAtom(data);
                localStorage.setItem('user', JSON.stringify(data));
              } else {
                setUserAtom(data);
                localStorage.removeItem('user');
              }
            })
            .catch((error) => {
              console.error('Login failed:', error);
            });
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserAtom({ loggedIn: false });
          localStorage.removeItem('user');
        }
      }

      setIsLoading(false);
    };

    fetchUser();
  }, [location.pathname, BASE_URL]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      setUserAtom(JSON.parse(storedUser));
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Spinner />; // Provide a loading indicator or similar
  }

  return <>{children}</>;
};

export default UserManager;
