import axios, { AxiosError } from "axios";

class Report {
    apiBaseUrl: any;
    constructor(apiBaseUrl: any) {
        this.apiBaseUrl = apiBaseUrl;
    }

    async reportProblem(data: any) {
        try {
            const response = await axios.post(`${this.apiBaseUrl}/report/new`, data, {
                withCredentials: true,
            });

            return response.data;
        } catch (error) {
            console.error("Error reporting a problem", error);
            return (error as AxiosError)?.response?.data;
        }
    }
}
const reportService = new Report(`${process.env.REACT_APP_BASE_URL}`);
export default reportService;
