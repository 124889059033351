import { Card, Divider, HStack, Image, Skeleton, Text, VStack, useDisclosure } from '@chakra-ui/react'; // @ts-ignore
import { useAtom } from 'jotai'; // @ts-ignore
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { UserCollegesAtom } from '../../../Atoms/Planner/planner';
import { ESSAY_DRAFT_OPTIONS, PREFERRED_DEADLINE, PREFERRED_DEADLINE_OPTIONS } from '../../../Constants/planner';
import usePlanner from '../../../Hooks/Planner/usePlanner';
import { formatDate } from '../../../Utils/colleges';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import CollegeDraftsDetails from '../CollegeModal/CollegeDraftsDetails'; // @ts-ignore
import bean from './../../../Assets/icons/bean.svg'; // @ts-ignore
import DragHandles from './../../../Assets/icons/drag-handles.svg'; // @ts-ignore
import EmptyPlaceholder from './../../../Assets/images/empty-placeholder.svg';
import PromptsIndicator from './PromptsIndicator';

const ShortListedColleges: React.FC = () => {
  const { trackEvent } = useMixpanel();
  const [userCollegesAtom] = useAtom(UserCollegesAtom);
  const [userColleges, setUserColleges] = useState<any>([]);
  const [areUserCollegesLoading, setAreUserCollegesLoading] = useState(true);

  const selectedCollegeRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { updatePriority } = usePlanner();

  useEffect(() => {
    setUserColleges(userCollegesAtom?.userColleges);
    setAreUserCollegesLoading(userCollegesAtom?.areUserCollegesLoading);
  }, [userCollegesAtom]);

  const noCollegesList = () => (
    <>
      <Image src={EmptyPlaceholder} alt='No shortlisted colleges' />
      <Text color={'#667085'} fontStyle={'italic'} fontWeight={500} fontSize={'lg'} textAlign={'center'}>
        You have no shortlisted colleges
      </Text>
    </>
  );

  const collegeCard = (college: any, index) => (
    <Draggable draggableId={String(index)} index={index} key={`college-card-${index}`}>
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          backgroundColor={snapshot.isDragging ? 'gray1' : 'white'}
          key={college?.id}
          width={'100%'}
          paddingX={2}
          paddingTop={3}
          paddingBottom={2}
          display={'flex'}
          flexDirection={'row'}
          borderRadius={'md'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          border='1px solid'
          gap={2}
          borderColor={snapshot.isDragging ? 'primaryLight' : 'white'}
          _hover={{
            backgroundColor: 'gray1',
          }}
          onClick={() => {
            trackEvent('University Pop-up opened', {
              universityName: college?.name,
            });
            selectedCollegeRef.current = college;
            onOpen();
          }}
        >
          <Image src={DragHandles} width={'13px'} height={'22px'} />
          <Divider orientation='vertical' borderColor='#EFF1F3' borderWidth={'thin'} sx={{ height: '100%' }} />
          <VStack width={'100%'} spacing={0.5} paddingX={1}>
            <HStack width={'100%'} justifyContent={'space-between'} paddingX={0.5}>
              {college[PREFERRED_DEADLINE[college?.deadline_preference]] ? (
                <Text fontSize={'sm'} fontWeight={600} color='#737373' noOfLines={1}>
                  {formatDate(college[PREFERRED_DEADLINE[college?.deadline_preference]])}
                </Text>
              ) : (
                <Text fontSize={'sm'} fontWeight={600} color='#737373' />
              )}

              <Text fontSize={'xs'} fontWeight={500} color='#737373' noOfLines={1}>
                {PREFERRED_DEADLINE_OPTIONS[college?.deadline_preference]}
              </Text>
            </HStack>
            <HStack width={'100%'} justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
              <Image
                src={college?.image_url ? college?.image_url : bean}
                alt={college.name}
                boxSize='14px'
                objectFit='scale-down'
                rounded='full'
                flexShrink={0}
              />
              <Text fontSize={'xs'} fontWeight={600} color='#737373' noOfLines={1}>
                {college.name}
              </Text>
            </HStack>
            <HStack width={'100%'} justifyContent={'flex-start'} paddingX={1} paddingY={2}>
              <PromptsIndicator
                totalDots={college?.supplements?.length}
                filledDots={
                  college?.supplements?.reduce(
                    (count, supplement) =>
                      supplement.completionStatus === ESSAY_DRAFT_OPTIONS.COMPLETED ? count + 1 : count,
                    0
                  ) || 0
                }
              />
            </HStack>
          </VStack>
        </Card>
      )}
    </Draggable>
  );

  const collegeCardSkeleton = (index) => (
    <Skeleton
      key={`college-card-skeleton-${index}`}
      startColor='gray2'
      endColor='gray9'
      border='1px solid'
      borderRadius={'md'}
      width={'100%'}
      height={'10vh'}
    />
  );

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newCards = Array.from(userColleges);
    const [movedCard]: any = newCards.splice(result.source.index, 1);
    newCards.splice(result.destination.index, 0, movedCard);

    const updatedCards = newCards.map((userCollege, index) => {
      if (typeof userCollege === 'object' && userCollege !== null) {
        return { ...userCollege, priority: index + 1 };
      }

      return userCollege;
    });

    trackEvent('Shortlist Priority Adjusted', {
      universityName: movedCard?.name,
    });
    updatePriority(updatedCards);

    setUserColleges(updatedCards);
  };

  const collegesList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='cards'>
        {(provided) => (
          <VStack
            ref={provided.innerRef}
            {...provided.droppableProps}
            spacing={4}
            width={'100%'}
            height={'100%'}
            maxHeight={'65vh'}
            paddingX={2}
            paddingY={1}
            justifyContent={'flex-start'}
            overflowY={'auto'}
          >
            {userColleges?.map((college, index) => collegeCard(college, index))}
            {provided.placeholder}
          </VStack>
        )}
      </Droppable>
    </DragDropContext>
  );

  const loadedState = () => <>{userColleges?.length > 0 ? collegesList() : noCollegesList()}</>;

  return (
    <VStack
      spacing={2}
      width={'100%'}
      height={'100%'}
      alignItems={'flex-start'}
      padding={4}
      backgroundColor={'#EBF0EF'}
      borderRadius={'xl'}
      className='planner-shortlisted-colleges'
    >
      <Text color={'primary'} fontWeight={700} fontSize={'lg'}>
        Shortlisted Colleges List
      </Text>
      <Text color={'primary'} fontSize={'sm'} fontWeight={500}>
        Drag colleges to prioritize top to bottom
      </Text>
      <VStack width={'100%'} height={'full'} justifyContent={'center'} alignItems={'center'} spacing={8}>
        {areUserCollegesLoading
          ? Array.from({ length: 5 }).map((_, index) => collegeCardSkeleton(index))
          : loadedState()}
      </VStack>
      {isOpen && (
        <CollegeDraftsDetails isOpen={isOpen} onClose={onClose} selectedCollege={selectedCollegeRef.current} />
      )}
    </VStack>
  );
};

export default ShortListedColleges;
