import {
  Box,
  HStack,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react'; // @ts-ignore
import { ReactComponent as _Background } from '../../Assets/icons/background.svg'; // @ts-ignore
import { ReactComponent as _ChangePassword } from '../../Assets/icons/changepassword.svg'; // @ts-ignore
import { ReactComponent as _Profile } from '../../Assets/images/profile.svg';
import { isApplicationBackgroundOpenAtom } from '../../Atoms/demoAtom';
import { AnalyticsScreenName } from '../../Constants/constants';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import BackgroundInfo from './ApplicantBackground/BackgroundInfo';
import ProfileInfo from './ApplicantProfile/ProfileInfo';
import UpdatePassword from './ChangePassword/UpdatePassword';
import ProfileSummary from './ProfileSummary/ProfileSummary';

const ProfileIcon = chakra(_Profile);
const BackgroundIcon = chakra(_Background);
const ChangePasswordIcon = chakra(_ChangePassword);

export const Profile = () => {
  useMixpanel(AnalyticsScreenName.ScreenProfile);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isApplicationBackgroundOpen = useAtomValue(isApplicationBackgroundOpenAtom);

  const profileTabText = useBreakpointValue({
    base: 'Profile',
    sm: 'Profile',
    md: 'Application Profile',
    lg: 'Application Profile',
  });

  const backgroundTabText = useBreakpointValue({
    base: 'Background',
    sm: 'Background',
    md: 'Applicant Background',
    lg: 'Applicant Background',
  });

  const passwordTabText = useBreakpointValue({
    base: 'Password',
    sm: 'Password',
    md: 'Change Password',
    lg: 'Change Password',
  });

  const renderTabButton = (Icon, text, index) => (
    <HStack>
      <Icon color={index === selectedIndex ? 'primary' : 'gray3'} />
      <Text
        fontSize={['xs', 'sm', 'md', 'md']}
        fontWeight={'semibold'}
        color={index === selectedIndex ? 'primary' : 'gray3'}
      >
        {text}
      </Text>
    </HStack>
  );

  const renderTabPanel = (TabComponent, showEditProfileButton) => (
    <Stack flexDirection={['column', 'column', 'row', 'row']} spacing={4} width={'100%'}>
      <ProfileSummary showEditProfileButton={showEditProfileButton} />
      <TabComponent />
    </Stack>
  );

  const handleTabChange = (index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (isApplicationBackgroundOpen) {
      setSelectedIndex(1);
    } else {
      setSelectedIndex(0);
    }
  }, [isApplicationBackgroundOpen]);

  return (
    <Box>
      <Tabs variant={'unstyled'} index={selectedIndex} onChange={handleTabChange}>
        <TabList width={'100%'} justifyContent={'center'} bg='foreground' height={'6vh'}>
          <Tab>{renderTabButton(ProfileIcon, profileTabText, 0)}</Tab>
          <Tab>{renderTabButton(BackgroundIcon, backgroundTabText, 1)}</Tab>
          <Tab>{renderTabButton(ChangePasswordIcon, passwordTabText, 2)}</Tab>
        </TabList>
        <TabIndicator tabIndex={selectedIndex} mt='-1.5px' height='2px' bg='primary' borderRadius='1px' />
        <TabPanels padding={[2, 2, 2, 6]}>
          <TabPanel>{selectedIndex === 0 && renderTabPanel(ProfileInfo, true)}</TabPanel>
          <TabPanel>{selectedIndex === 1 && renderTabPanel(BackgroundInfo, true)}</TabPanel>
          <TabPanel>{selectedIndex === 2 && renderTabPanel(UpdatePassword, true)}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Profile;
