import React, { useState } from "react";
import { Textarea } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { notesAtom } from "../../Atoms/notesAtom";

const CreateEditNote = () => {
  const [notes, setNotes] = useAtom(notesAtom);
  const [value, setValue] = useState(notes?.textFieldContent);

  const handleChange = (e) => {
    setValue(e.target.value);
    setNotes({ ...notes, textFieldContent: e.target.value });
  };

  return (
    <Textarea
      h="80%"
      focusBorderColor="primaryLight"
      backgroundColor={"gray1"}
      placeholder="Start typing..."
      value={value}
      onChange={handleChange}
    />
  );
};

export default CreateEditNote;
