import { Text } from '@chakra-ui/react';
import React from 'react';

export const customRenderers = (color = 'gray5') => ({
  p: (props) => (
    <Text fontSize={'sm'} color={color}>
      {props.children}
    </Text>
  ),
  h1: (props) => (
    <Text as='h1' fontSize={'sm'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  h2: (props) => (
    <Text as='h2' fontSize={'sm'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  li: (props) => (
    <Text as='li' fontSize={'sm'} color={color} ml={3}>
      {props.children}
    </Text>
  ),
  a: (props) => (
    <Text as='a' fontSize={'sm'} color={color} href={props.href}>
      {props.children}
    </Text>
  ),
});
