import { atom } from 'jotai';
import { Note } from '~/Models/notes';

type PlacementType = 'right' | 'left';

interface INotes {
  isOpen: boolean;
  placement: PlacementType;
  notes: Note[];
  notesOrder: number[];
  isCreateNoteView: boolean;
  isEditNoteView: boolean;
  textFieldContent: string;
  selectedNoteId: number | null;
}

export const notesAtom = atom<INotes>({
  isOpen: false,
  placement: 'right',
  notes: [],
  isCreateNoteView: false,
  textFieldContent: '',
  isEditNoteView: false,
  selectedNoteId: null,
  notesOrder: [],
});
