/* eslint-disable @typescript-eslint/no-shadow */
import { Box, Portal, VStack, useBreakpointValue } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { notesAtom } from '../../Atoms/notesAtom';
import { useUpdateNotesOrder } from '../../Hooks/Notes/useNotes';
import { Note } from '../../Models/notes';
import CreateEditNote from './CreateEditNote';
import NotesCard from './NotesCard';

const NotesList = () => {
  const [notes, setNotes] = useAtom(notesAtom);
  const { updateNotesOrder } = useUpdateNotesOrder();

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index !== result.source.index) {
      const newNotes = Array.from(notes?.notes);
      const [removed] = newNotes.splice(result.source.index, 1);
      newNotes.splice(result.destination.index, 0, removed);
      const newOrder = newNotes.map((note) => note?.id);

      setNotes({ ...notes, notes: newNotes, notesOrder: newOrder });

      await updateNotesOrder(newOrder);
    }
  };

  return (
    <>
      {notes?.isCreateNoteView || notes?.isEditNoteView ? (
        <Box height={isMobile ? '50vh' : 'full'}>
          <CreateEditNote />
        </Box>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='notes'>
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                overflowY='auto'
                height={isMobile ? '55vh' : 'full'}
              >
                <VStack width={'100%'} spacing={4}>
                  {notes?.notes?.map((note, index) => (
                    <Draggable key={note?.id} draggableId={String(note?.id)} index={index}>
                      {(provided, snapshot) =>
                        snapshot?.isDragging ? (
                          <Portal>
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              width={'100%'}
                              borderWidth='1px'
                              borderColor={snapshot.isDragging ? 'primaryLight' : 'transparent'}
                              borderRadius='md'
                              boxShadow={snapshot.isDragging ? 'md' : 'none'}
                              transition='background-color 0.2s ease'
                            >
                              <NotesCard key={index} note={note as Note} />
                            </Box>
                          </Portal>
                        ) : (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            width={'100%'}
                            borderWidth='1px'
                            borderColor={snapshot.isDragging ? 'primaryLight' : 'transparent'}
                            borderRadius='md'
                            boxShadow={snapshot.isDragging ? 'md' : 'none'}
                            transition='background-color 0.2s ease'
                          >
                            <NotesCard key={index} note={note as Note} />
                          </Box>
                        )
                      }
                    </Draggable>
                  ))}
                </VStack>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default NotesList;
