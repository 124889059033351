import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import '../../Styles/drawer.css';

const DrawerComponent = ({
  children,
  heading,
  isOpen,
  onClose,
  isSwipable,
  headerProps,
  contentProps,
  showCloseButton = true,
  height = ['80vh', '80vh', '80vh', 'fit-content'],
  ...props
}: any) => {
  // Placeholder for swipe detection logic
  const startY = useRef(0);
  const currentY = useRef(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    currentY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = () => {
    const distanceSwiped = currentY.current - startY.current;

    if (distanceSwiped > 200) {
      onClose();
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size='xs' {...props}>
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius={'3xl'}
          h={height}
          {...contentProps}
          onTouchStart={isSwipable ? handleTouchStart : undefined}
          onTouchMove={isSwipable ? handleTouchMove : undefined}
          onTouchEnd={isSwipable ? handleTouchEnd : undefined}
        >
          {showCloseButton && (
            <DrawerCloseButton color={'gray5'} borderRadius={'50%'} bg={'gray1'} _hover={{ bg: 'blackAlpha.100' }} />
          )}
          <Divider
            mt={4}
            w={'10%'}
            alignSelf={'center'}
            borderRadius={'xl'}
            borderColor={'gray3'}
            borderWidth={2}
            cursor={'grabbing'}
          />
          <DrawerHeader {...headerProps}>{heading}</DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
