import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';

interface PromptsIndicatorProps {
  totalDots: number;
  filledDots: number;
}

const PromptsIndicator: React.FC<PromptsIndicatorProps> = ({ totalDots, filledDots }) => {
  const dots = Array.from({ length: totalDots }, (_, i) => (i < filledDots ? 'primaryLight' : '#D9D9D9'));

  return (
    <SimpleGrid columns={totalDots} spacing={1}>
      {dots.map((color, index) => (
        <Box key={index} width='4px' height='4px' backgroundColor={color} borderRadius='50%' />
      ))}
    </SimpleGrid>
  );
};

export default PromptsIndicator;
