import { Select, SelectProps, VStack } from '@chakra-ui/react';
import React from 'react';

export interface CustomSelectProps extends SelectProps {
  options: { [key: string]: string };
  showLabel?: boolean;
  paddingTopInStyle?: number | string;
  isPlaceholderDisabled?: boolean;
}

const labelStyle = {
  position: 'absolute',
  color: '#98A2B3',
  zIndex: 100,
  fontSize: '10px',
  alignSelf: 'flex-start',
  marginLeft: '12px',
  marginTop: '3px',
};

const DropdownSelect = ({
  options,
  onChange,
  value,
  name,
  placeholder,
  w = '100%',
  showLabel = false,
  color = 'gray5',
  paddingTopInStyle = '4px',
  style,
  isPlaceholderDisabled = true,
  ...props
}: CustomSelectProps) => {
  const additionalStyles = showLabel && value !== '' && !!value ? { paddingTop: paddingTopInStyle } : {};
  return (
    <VStack>
      {showLabel && value !== '' && !!value && <span style={labelStyle as React.CSSProperties}>{name}</span>}
      <Select
        color={color}
        borderRadius='md'
        fontSize='sm'
        fontWeight='medium'
        alignSelf={'start'}
        ml={'auto'}
        w={w}
        name={name}
        value={value}
        onChange={onChange}
        focusBorderColor='primaryLight'
        style={{ ...additionalStyles, ...style }}
        {...props}
      >
        {placeholder && (
          <option value='' disabled={isPlaceholderDisabled}>
            {placeholder}
          </option>
        )}
        {Object.keys(options).map((key: any) => (
          <option key={key} value={key}>
            {options[key]}
          </option>
        ))}
      </Select>
    </VStack>
  );
};

export default DropdownSelect;
