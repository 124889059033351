import { Box, HStack, Stack, Text, VStack, chakra } from '@chakra-ui/react'; // @ts-ignore
import React, { useState } from 'react';
import { ReactComponent as _MultiplePromptIcon } from '../../../Assets/icons/multi-prompt.svg';
import { ESSAY_DRAFT_DROPDOWN_OPTIONS } from '../../../Constants/planner';
import usePlanner from '../../../Hooks/Planner/usePlanner';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import DropdownSelect from '../../../lib/UI-Components/Select';

const MultiplePromptIcon = chakra(_MultiplePromptIcon);

interface IPromptStatus {
  prompt: any;
  selectedCollegeName: string;
}

const PromptStatus: React.FC<IPromptStatus> = ({ prompt, selectedCollegeName }) => {
  const { updatePromptStatus } = usePlanner();
  const { trackEvent } = useMixpanel();
  const [status, setStatus] = useState(prompt?.completion_status);

  const handleChangePromptStatus = (value: string, supplementId: number) => {
    setStatus(value);
    updatePromptStatus(supplementId, value, prompt?.planId, prompt?.position);
    trackEvent('Prompt status changed from College Card', {
      universityName: selectedCollegeName,
    });
  };

  return (
    <Stack
      flexDirection={['column', 'column', 'column', 'row']}
      w='full'
      p='2'
      borderWidth='1px'
      borderColor='gray2'
      borderRadius='lg'
      justifyContent={'space-between'}
    >
      <VStack w={['100%', '100%', '100%', '78%']} align='left'>
        <HStack>
          <MultiplePromptIcon boxSize={6} flexShrink={0} color='contrast' cursor={'pointer'} />
          <Text fontSize='md' color='gray5' width={'100%'} noOfLines={2}>
            {prompt?.prompt}
          </Text>
        </HStack>
        {prompt?.wordCount && (
          <Text fontSize='sm' color='gray5'>
            Word Count: {prompt?.wordCount}
          </Text>
        )}
      </VStack>
      <Box w={['100%', '100%', '100%', '23%']}>
        <DropdownSelect
          onChange={(e) => handleChangePromptStatus(e.target.value, prompt?.supplement_id)}
          options={ESSAY_DRAFT_DROPDOWN_OPTIONS}
          value={status}
        />
      </Box>
    </Stack>
  );
};

export default PromptStatus;
