import { Box, Grid, GridItem, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import React from 'react';
// @ts-ignore
import robot_lottie_placeholder from '../../Assets/images/robot_lottie_placeholder.svg';
import { AUTH_LOTTIE_LINKS } from '../../Constants/auth'; // @ts-ignore
import useLottieAnimation from '../../Hooks/App/useFetchLottie';
import useSize from '../../Hooks/App/useWindowSize';

interface ITwoColumnLayoutProps {
  showIllustration?: boolean;
  children: React.ReactNode;
  imageURL?: string;
  textBelowAnimation?: string;
  reverse?: boolean;
}

const TwoColumnLayout: React.FC<ITwoColumnLayoutProps> = ({
  children,
  showIllustration = false,
  imageURL,
  textBelowAnimation = ' Building Your Personalized Experience...',
  reverse = false,
}) => {
  const [width, height] = useSize();

  const isAnimationVisible = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: width / height >= 1,
    xl: true,
  });

  const { animationData, loading, error } = useLottieAnimation(imageURL || AUTH_LOTTIE_LINKS.LOGIN);

  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', 'repeat(10, 1fr)', 'repeat(10, 1fr)', 'repeat(10, 1fr)']}
      templateRows={['repeat(8, 1fr)']}
      height={'93.2vh'}
      minHeight={'748px'}
      overflow={['auto', 'auto', 'initial', 'hidden']}
    >
      {reverse && (
        <GridItem colSpan={isAnimationVisible ? 6 : 10} rowSpan={8}>
          {children}
        </GridItem>
      )}
      {isAnimationVisible && (
        <GridItem
          colSpan={4}
          rowSpan={8}
          background={'linear-gradient(147deg, #035A41 -5.79%, #3E8A75 104.73%), #FFF;'}
          overflow={'hidden'}
          display={'flex'}
          p={2}
          flexDirection={'column'}
          justifyContent={showIllustration ? 'center' : 'flex-start'}
          alignItems={'center'}
        >
          {showIllustration ? (
            <Stack width={'35vw'} height={'70vh'} bgImage={imageURL} bgPosition={'center'} bgSize={'cover'} />
          ) : (
            <>
              <Box mt={-20}>
                {loading && <Image src={robot_lottie_placeholder} alt={'Kollegio'} />}
                {error && <Text>Error loading animation</Text>}
                {animationData && !loading && !error && <Lottie animationData={animationData} />}
              </Box>
              <Text
                color={'foreground'}
                mt={-10}
                fontWeight={'bold'}
                css={{
                  '@keyframes flash': {
                    '0%': {
                      opacity: 1,
                    },
                    '50%': {
                      opacity: 0,
                    },
                    '100%': {
                      opacity: 1,
                    },
                  },
                  animation: 'flash 2s infinite',
                }}
              >
                {textBelowAnimation}
              </Text>
            </>
          )}
        </GridItem>
      )}
      {!reverse && (
        <GridItem colSpan={isAnimationVisible ? 6 : 10} rowSpan={8}>
          {children}
        </GridItem>
      )}
    </Grid>
  );
};

export default TwoColumnLayout;
