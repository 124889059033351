import { ChevronRightIcon } from '@chakra-ui/icons'; // @ts-ignore
import {
  Box,
  Divider,
  HStack,
  IconButton,
  Image,
  Text,
  Tooltip,
  VStack,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react'; // @ts-ignore
import React, { useState } from 'react';
// @ts-ignore
import bean from './../../../Assets/icons/bean.svg'; // @ts-ignore
import { ReactComponent as _NotCompletedCheckboxIcon } from './../../../Assets/images/not-completed-checkbox.svg';
// @ts-ignore
import { ReactComponent as _EditIcon } from './../../../Assets/icons/planner-edit.svg';
// @ts-ignore
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { essayEditStateAtom } from '../../../Atoms/Essays/editorAtom';
import { PlannerAtom } from '../../../Atoms/Planner/planner';
import { HEADER_ESSAY_SELECTION, Routes } from '../../../Constants/constants';
import usePlanner from '../../../Hooks/Planner/usePlanner';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import CustomDatePicker from '../../../lib/UI-Components/DatePicker/DatePicker';
// @ts-ignore
import { ReactComponent as _CompletedCheckboxIcon } from './../../../Assets/icons/completed-checkbox.svg';
import { TASK_COMPLETION_STATES, TASK_TYPES } from './../../../Constants/planner';
import CalendarButton from './CalendarButton';
import CustomHeader from './CustomHeader';
import SimilarPrompt from './SimilarPrompt';

const EditIcon = chakra(_EditIcon);
const NotCompletedCheckboxIcon = chakra(_NotCompletedCheckboxIcon);
const CompletedCheckboxIcon = chakra(_CompletedCheckboxIcon);

interface TaskListItemProps {
  taskItemInfo: any;
}

const TaskListItem: React.FC<TaskListItemProps> = ({ taskItemInfo }) => {
  const navigate = useNavigate();
  const { trackEvent } = useMixpanel();

  const [completed, setCompleted] = useState(TASK_COMPLETION_STATES.COMPLETED === taskItemInfo.completion_state);

  const [deadline, setDeadline] = useState(new Date(taskItemInfo.deadline));

  const isMobileView = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const [transitionEffectCompleted, setTransitionEffectCompleted] = useState(true);

  const [plannerAtom, setPlannerAtom] = useAtom(PlannerAtom);
  const [, setEssayEditState] = useAtom(essayEditStateAtom);
  const { updateDeadline, getSimilarPrompts, toggleTaskCompletion } = usePlanner();

  const handleClickCheckbox = (event) => {
    event.stopPropagation();
    setTransitionEffectCompleted(false);

    if (!completed) {
      setTimeout(() => {
        setTransitionEffectCompleted(true);
      }, 500);
    } else {
      setTransitionEffectCompleted(true);
    }

    toggleTaskCompletion(taskItemInfo?.id, !completed);

    trackEvent(!completed ? 'Task Completion Marked' : 'Task Completion Unmarked');
    setCompleted(!completed);
  };

  const handleClickRow = () => {
    if (taskItemInfo?.type === TASK_TYPES.ESSAY) {
      setPlannerAtom({ ...plannerAtom, isTaskDrawerOpen: true, isTaskDrawerLoading: true });
      getSimilarPrompts(taskItemInfo);
      trackEvent('Task Details Viewed', {
        taskName: taskItemInfo?.title,
      });
    }
  };

  const handleClickEdit = (event) => {
    event.stopPropagation();
    trackEvent('Essay Opened from Task', {
      taskName: taskItemInfo?.title,
    });
    setEssayEditState(0);
    setPlannerAtom({ ...plannerAtom, isTaskDrawerOpen: false });
    navigate(Routes.Essays, {
      state: {
        headerValue: completed ? HEADER_ESSAY_SELECTION.SavedEssays : HEADER_ESSAY_SELECTION.NewEssays,
      },
    });
  };

  const handleDeadlineChange = (value, event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    trackEvent('Task Deadline Changed in Calendar', {
      taskName: taskItemInfo?.title,
    });
    setDeadline(value);
    updateDeadline(taskItemInfo, value);
  };

  const hoverStyles =
    taskItemInfo?.type === TASK_TYPES.ESSAY
      ? {
          background: transitionEffectCompleted ? '#EAECF0' : 'linear-gradient(to right, #E0ECE9, white)',
        }
      : {};

  return (
    <HStack
      width={'100%'}
      border={'2px solid'}
      borderColor={plannerAtom?.selectedTask?.id === taskItemInfo?.id ? 'primaryLight' : '#EAECF0'}
      borderBottom={plannerAtom?.selectedTask?.id === taskItemInfo?.id ? '2px solid #3E8A75' : 'none'}
      spacing={0}
      height={['10vh', '9vh', '8vh', '7.8vh']}
      role='group'
      background={transitionEffectCompleted ? 'none' : 'linear-gradient(to right, #E0ECE9, white)'}
      _hover={hoverStyles}
      cursor={taskItemInfo?.type === TASK_TYPES.ESSAY ? 'pointer' : 'default'}
      onClick={handleClickRow}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <IconButton
          aria-label='checkbox icon'
          background={'transparent'}
          _hover={{ background: 'transparent' }}
          onClick={(e) => handleClickCheckbox(e)}
          icon={completed ? <CompletedCheckboxIcon /> : <NotCompletedCheckboxIcon />}
        />
      </Box>
      <VStack width={'100%'} borderLeft={'2px solid #EAECF0'} height={'100%'}>
        <HStack justifyContent={'space-between'} width={'100%'} paddingX={4} height={'100%'}>
          <HStack spacing={3}>
            <Image
              src={taskItemInfo.image ? taskItemInfo.image : bean}
              alt={taskItemInfo.title}
              boxSize={['20px', '20px', '30px', '30px']}
              objectFit='scale-down'
              rounded='full'
              flexShrink={0}
            />
            <Text
              textDecoration={completed && transitionEffectCompleted ? 'line-through' : 'none'}
              color={completed && transitionEffectCompleted ? 'gray6' : 'primaryDark'}
              fontSize={['xs', 'xs', 'md', 'md']}
              fontWeight={400}
            >
              {taskItemInfo.title}
            </Text>
            {taskItemInfo?.similarPromptCount > 0 && <SimilarPrompt count={taskItemInfo?.similarPromptCount} />}
          </HStack>
          {!isMobileView && taskItemInfo?.type === TASK_TYPES.ESSAY && (
            <HStack display='none' _groupHover={{ display: 'flex' }}>
              <Tooltip label='Go to essay'>
                <IconButton
                  aria-label='Edit icon'
                  background={'transparent'}
                  _hover={{ background: 'gray2' }}
                  icon={<EditIcon />}
                  onClick={handleClickEdit}
                />
              </Tooltip>

              <CustomDatePicker
                onChange={handleDeadlineChange}
                title='Change Deadline'
                selected={deadline}
                renderCustomHeader={(props: any) => <CustomHeader {...props} />}
                customInput={<CalendarButton taskName={taskItemInfo?.title} />}
              />
              <Divider orientation='vertical' borderColor='gray2' borderWidth={'thin'} sx={{ height: '1rem' }} />
              <ChevronRightIcon
                boxSize={6}
                color={'primary'}
                cursor={'pointer'}
                onClick={handleClickRow}
                _hover={{
                  borderRadius: 'lg',
                  background: 'gray2',
                }}
              />
            </HStack>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};

export default TaskListItem;
