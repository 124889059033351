import { atom } from 'jotai';
import ReactQuill from 'react-quill';
interface IFormats {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  bullet: boolean;
}
export const quillRefAtom = atom<ReactQuill | null>(null);
export const editorFormats = atom<IFormats>({
  bold: false,
  italic: false,
  underline: false,
  bullet: false,
});

export const essayEditStateAtom = atom<number>(0);
