import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Text,
  Tooltip,
  VStack,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';
import { notesAtom } from '../../Atoms/notesAtom';
import CustomDrawer from '../../lib/UI-Components/CustomDrawer';
// @ts-ignore
import { ReactComponent as _DockSideRight } from './../../Assets/icons/dock-side-right.svg';
// @ts-ignore
import { ReactComponent as _DockSideLeft } from './../../Assets/icons/dock-side-left.svg';
import NotesList from './NotesList';
// @ts-ignore
import NotesEmptyView from './../../Assets/images/empty-notes-view-image.svg';
// @ts-ignore
import { AnalyticsScreenName } from '../../Constants/constants';
import { useAddNote, useGetNotes, useUpdateNote } from '../../Hooks/Notes/useNotes';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import DrawerComponent from '../../lib/UI-Components/Drawer';
import { ReactComponent as _CrossIcon } from './../../Assets/icons/cross.svg';

const DockSideRightIcon = chakra(_DockSideRight);
const DockSideLeftIcon = chakra(_DockSideLeft);
const CrossIcon = chakra(_CrossIcon);

const Notes = () => {
  const { trackEvent } = useMixpanel();
  const [notes, setNotes] = useAtom(notesAtom);
  const { updateNote } = useUpdateNote();
  const { isLoading } = useGetNotes();
  const { addNote } = useAddNote();

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const header = (
    <HStack width={'100%'} justifyContent={'space-between'}>
      <Text color={'primary'}>
        {notes?.isCreateNoteView ? 'Create New Note' : notes?.isEditNoteView ? 'Edit Note' : 'Notes'}
      </Text>
      {!isMobile && (
        <HStack>
          <Tooltip label='Toggle Drawer Position'>
            <Button
              onClick={async (e) => {
                e.stopPropagation();
                trackEvent(`Notes placement changed`, {
                  screenName: AnalyticsScreenName.ScreenNotes,
                });

                if (notes?.placement === 'right') {
                  setNotes({ ...notes, placement: 'left' });
                } else {
                  setNotes({ ...notes, placement: 'right' });
                }
              }}
              onFocus={(e) => e.preventDefault()}
              size='xs'
              background={'background'}
              borderRadius={'full'}
              _hover={{
                backgroundColor: 'green1',
              }}
            >
              {notes?.placement === 'right' ? (
                <DockSideLeftIcon
                  color={'gray4'}
                  _hover={{
                    color: 'primaryLight',
                  }}
                />
              ) : (
                <DockSideRightIcon
                  color={'gray4'}
                  _hover={{
                    color: 'primaryLight',
                  }}
                />
              )}
            </Button>
          </Tooltip>
          <Button
            onClick={() => {
              trackEvent(`Notes cross icon pressed`, {
                screenName: AnalyticsScreenName.ScreenNotes,
              });
              setNotes({
                ...notes,
                isOpen: false,
                isCreateNoteView: false,
                isEditNoteView: false,
              });
            }}
            size='xs'
            background={'background'}
            borderRadius={'full'}
            _hover={{
              backgroundColor: 'green1',
            }}
          >
            <CrossIcon
              color={'gray5'}
              _hover={{
                color: 'primaryLight',
              }}
            />
          </Button>
        </HStack>
      )}
    </HStack>
  );

  const body = (
    <>
      {notes?.notes?.length > 0 || notes?.isCreateNoteView || notes?.isEditNoteView ? (
        <NotesList />
      ) : (
        <VStack width={'100%'} height={isMobile ? '50vh' : '100%'} justifyContent={'center'} alignItems={'center'}>
          <Image src={NotesEmptyView} width={isMobile ? '50vw' : '100%'} height={'fit-content'} />
          <Text color={'gray5'} textAlign={'center'}>
            You have no notes. Add new notes by clicking on Create New Note.
          </Text>
        </VStack>
      )}
    </>
  );

  const skeleton = (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton
          key={`notes_drawer_${index}`}
          w='full'
          height='6vh'
          rounded='md'
          boxShadow='sm'
          mt={2}
          startColor='gray2'
          endColor='gray1'
        />
      ))}
    </>
  );

  const footer = (
    <>
      {notes?.isCreateNoteView || notes?.isEditNoteView ? (
        <Box width='100%' display={'flex'} justifyContent={'space-between'}>
          <Button
            width={'50%'}
            variant={'secondary'}
            fontSize={'sm'}
            onClick={() => {
              trackEvent(`Back to Notes Pressed`, {
                screenName: AnalyticsScreenName.ScreenNotes,
              });
              setNotes({
                ...notes,
                selectedNoteId: null,
                textFieldContent: '',
                isCreateNoteView: false,
                isEditNoteView: false,
              });
            }}
            marginRight={2}
          >
            Back to Notes
          </Button>
          <Button
            fontSize={'sm'}
            variant={'primaryWithoutShadow'}
            width={'50%'}
            onClick={() => {
              trackEvent(`Save Note pressed`, {
                screenName: AnalyticsScreenName.ScreenNotes,
              });

              if (notes?.isCreateNoteView) {
                addNote(notes?.textFieldContent);
              } else if (notes?.isEditNoteView) {
                updateNote({
                  noteId: notes.selectedNoteId,
                  updatedContent: notes?.textFieldContent || '',
                });
              }
            }}
          >
            Save Note
          </Button>
        </Box>
      ) : (
        <Box width='100%' display={'flex'} justifyContent={'center'} mt={isMobile ? 2 : 0}>
          <Button
            color={'primaryLight'}
            width={'100%'}
            borderRadius={'xl'}
            onClick={() => {
              trackEvent(`Create New Note button pressed`, {
                screenName: AnalyticsScreenName.ScreenNotes,
              });
              setNotes({ ...notes, isCreateNoteView: true });
            }}
            _hover={{
              backgroundColor: 'rgba(3, 90, 65, 0.08)',
            }}
            backgroundColor={'rgba(62, 138, 117, 0.16)'}
          >
            <AddIcon mr={2} /> {' Create New Note '}
          </Button>
        </Box>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <DrawerComponent
          isSwipable
          heading={header}
          isOpen={notes?.isOpen}
          onClose={() =>
            setNotes({
              ...notes,
              isOpen: false,
              isCreateNoteView: false,
              isEditNoteView: false,
            })
          }
          placement={'bottom'}
          height={['75vh', '70vh', '80vh', 'fit-content']}
          borderTopRadius={'3xl'}
        >
          {body}
          {footer}
        </DrawerComponent>
      ) : (
        <CustomDrawer
          isOpen={notes?.isOpen}
          onClose={() =>
            setNotes({
              ...notes,
              isOpen: false,
              isCreateNoteView: false,
              isEditNoteView: false,
            })
          }
          placement={notes?.placement}
          header={header}
          body={isLoading ? skeleton : body}
          footer={!isLoading && footer}
        />
      )}
    </>
  );
};

export default Notes;
