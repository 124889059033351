import metadata from 'libphonenumber-js/metadata.full.json';
import { PublicRoutes } from '../Constants/constants';

export const getGoogleOAuthURL = (signup = false, referrer: string | null = null) => {
  const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const redirectUri = signup ? `${process.env.REACT_APP_URL}/join` : `${process.env.REACT_APP_URL}/login`;
  const state = encodeURIComponent(referrer || '');

  const options = {
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    state,
    scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'].join(
      ' '
    ),
  };

  // @ts-expect-error TS(2345) FIXME: Argument of type '{ redirect_uri: string | undefin...
  const qs = new URLSearchParams(options);

  // eslint-disable-next-line no-restricted-syntax
  console.log(`here: ${rootUrl}?${qs.toString()}`);
  return `${rootUrl}?${qs.toString()}`;
};

export function storeAndFilterQueryParams(searchParams: URLSearchParams) {
  const utmParams: Record<string, string> = {};
  const referralParams: Record<string, string> = {};

  // Iterate through all search parameters
  searchParams.forEach((value: string, key: string) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    } else if (key.startsWith('referral_')) {
      referralParams[key] = value;
    }
  });

  // Store the captured parameters in localStorage
  if (Object.keys(utmParams)?.length > 0) {
    localStorage.setItem('utm_params', JSON.stringify(utmParams));
  }

  if (Object.keys(referralParams)?.length > 0) {
    localStorage.setItem('referral_params', JSON.stringify(referralParams));
  }

  const variant = searchParams.get('variant');

  if (variant) {
    localStorage.setItem('variant', variant);
  }
}

export function getStoredQueryParams() {
  const storedUtmParamsString = localStorage.getItem('utm_params');
  const storedReferralParamsString = localStorage.getItem('referral_params');
  const storedVariant = localStorage.getItem('variant');

  const utmParams = storedUtmParamsString ? JSON.parse(storedUtmParamsString) : {};
  const referralParams = storedReferralParamsString ? JSON.parse(storedReferralParamsString) : {};
  const variant = storedVariant || '';

  return { utmParams, referralParams, variant };
}

type CountryCode = keyof typeof metadata.countries;
type CountryData = (typeof metadata.countries)[CountryCode];

type CountryDataMap = Record<string, CountryData>;

export const getDialCodes = () => {
  const countries = metadata.countries as CountryDataMap;
  const dialCodesWithCountry: { [key: string]: string } = {};

  const dialCodesArray = Object.keys(countries)
    .map((country) => {
      const countryData = countries[country];

      if (!countryData) {
        return null;
      }

      dialCodesWithCountry[`+${countryData[0]}`] = country;

      return {
        [`+${countryData[0]}`]: `+${countryData[0]}`,
      };
    })
    .filter((item): item is { [key: string]: string } => item !== null)
    .map((item) => Object.entries(item)[0]) // Convert to [key, value] array
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by key (dial code)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: string });

  return { dialCodes: dialCodesArray, dialCodesWithCountry };
};

export const isPublicRoute = (pathname: string) => PublicRoutes.includes(pathname as never);
