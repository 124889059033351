import { Button, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { INTEREST_PROMPTS } from '../../Constants/first_ux.ts';

interface Section2Props {
  interest: string;
  setInterest: any;
  setRoute: any;
}

const Section2 = ({ setInterest, setRoute, interest }: Section2Props) => (
  <VStack width='100%' alignItems='start' spacing={4}>
    <Text color='primary' textAlign='start' fontSize={'xl'} fontWeight='semibold'>
      What do you most need help with?
    </Text>
    <VStack width='100%' alignItems='start' justifyContent='start' spacing={4}>
      {INTEREST_PROMPTS.map((each_interest, index) => (
        <Button
          key={index}
          onClick={() => {
            setInterest(each_interest?.value);
            setRoute(each_interest?.route);
          }}
          isActive={interest === each_interest?.value}
          textAlign={'start'}
          whiteSpace='normal'
          fontSize={['xs', 'xs', 'md', 'md', 'md']}
          variant={'first_ux'}
        >
          {each_interest?.name}
        </Button>
      ))}
    </VStack>
  </VStack>
);

export default Section2;
