import { Routes } from 'Constants/constants';
import { EnvId } from 'Models/config';
import { atom } from 'jotai';

interface Demo {
  showJoyride: boolean;
  manualTrigger: boolean;
  index: number;
  screen: Routes;
  isEditorOpen: boolean;
  isApplicationBackgroundOpen: boolean;
  isActivitiesListOpen: boolean;
  isPlannerCollegesListOpen: boolean;
  isFirstSkip: boolean;
  isNavDemoShown: boolean;
  steps: any[];
  isControlled: boolean;
}

export const demoAtom = atom<Demo>({
  showJoyride: false,
  manualTrigger: false,
  index: 0,
  screen: Routes.Default,
  isEditorOpen: false,
  isApplicationBackgroundOpen: false,
  isActivitiesListOpen: false,
  isPlannerCollegesListOpen: false,
  isFirstSkip: false,
  steps: [],
  isControlled: false,
  isNavDemoShown: false,
});

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  demoAtom.debugLabel = 'demo';
}

export const isApplicationBackgroundOpenAtom = atom(
  (get) => get(demoAtom)?.isApplicationBackgroundOpen,
  (get, set, newValue: boolean) => set(demoAtom, { ...get(demoAtom), isApplicationBackgroundOpen: newValue })
);
