import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Style/style.css";
import { Box } from "@chakra-ui/react";

interface DatePickerProps extends ReactDatePickerProps {
  selected: any;
  onChange: any;
  customInput: any;
  title: string;
  dateFormat?: any;
  showYearPicker?: boolean;
  renderCustomHeader?: any;
}

export const CustomDatePicker = ({
  selected,
  onChange,
  customInput,
  title,
  dateFormat = "MMMM d, yyyy",
  renderCustomHeader,
  ...props
}: DatePickerProps) => {
  const dateWrapper = ({ className, children }) => (
    <Box
      bg="foreground"
      px="10px"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      className={className}
    >
      {title && (
        <Box p="8px" color="gray5" alignItems="left" fontSize="sm">
          {title}
        </Box>
      )}
      <Box position="relative">{children}</Box>
    </Box>
  );

  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      dateFormat={dateFormat}
      showPopperArrow={false}
      customInput={customInput}
      onClick={(e) => e.preventDefault()}
      calendarContainer={dateWrapper}
      renderCustomHeader={renderCustomHeader}
      {...props}
    />
  );
};

export default CustomDatePicker;
