export const ACTIVITY_INPUT_HEADING =
  " Input your extracurriculars to make use of our cutting edge AI feedback trained on 1000's of applications";
export const ADD_ACTIVITY = 'Add an activity';
export const ACTIVITY_DURATION = `Enter the duration you spent on this activity,
ensure the information is accurate and can be verified if needed`;
export const ADD_ACTIVITY_TITLE = 'Add Activity';
export const ADD_ACTIVITY_MODAL_DESCRIPTION =
  'Select a category, create a title and write a description that best describes your activity';
export const BACK = 'Back';
export const DESCRIPTION = 'Description';
export const HOURS_PER_WEEK = 'Hours per week';
export const WEEKS_PER_YEAR = 'Weeks per year';
export const NEXT = 'Next';
export const ORG_DESCRIPTION = `Make sure to write the official name of the organisation,
multiple school years can be selected for one activity`;
export const ORGANISATION = 'Organisation';
export const SCHOOL_YEARS = 'Select school year(s)';
export const SELECT_CATEGORY = 'Select category';
export const TITLE = 'Title';
export const UPLOAD_ACTIVITY = 'Upload your resume or your common app activities pdf';

export enum CHART_TYPES {
  CATEGORY = 'Category',
  GRADE = 'Grade',
}

export const SPORTS_CATEGORIES = ['Athletics: Club', 'Athletics: JV/Varsity', 'Junior R.O.T.C'];

export const ART_CATEGORIES = ['Art', 'Dance', 'Music: Instrumental', 'Music: Vocal', 'Theater/Drama', 'Cultural'];

export const SERVICE_CATEGORIES = [
  'Social Justice',
  'Community Service (Volunteer)',
  'Environmental',
  'LGBT',
  'Student Govt./Politics',
  'Religious',
];
export const STEM_CATEGORIES = ['Computer/Technology', 'Robotics', 'Science/Math'];

export const COMMUNICATION_CATEGORIES = [
  'Debate/Speech',
  'Journalism/Publication',
  'Foreign Exchange',
  'Foreign Language',
];

export const ACADEMIC_CATEGORIES = ['Academic', 'Research'];

export const MISC_CATEGORIES = [
  'Work (Paid)',
  'Career Oriented',
  'Other Club/Activity',
  'Internship',
  'Family Responsibilities',
];

export const ACTIVITY_CATEGORIES = {
  Communication: COMMUNICATION_CATEGORIES,
  Academic: ACADEMIC_CATEGORIES,
  STEM: STEM_CATEGORIES,
  Misc: MISC_CATEGORIES,
  Sports: SPORTS_CATEGORIES,
  Arts: ART_CATEGORIES,
  Service: SERVICE_CATEGORIES,
};

export const ACTIVITY_GRADES = ['Grade 9', 'Grade 10', 'Grade 11', 'Grade 12', 'Postgrad', 'Continued'];

export const TOOLTIP_MESSAGES = {
  leadership: {
    positive: 'This activity does a good job displaying leadership qualities.',
    negative: 'This activity does not display leadership. Consider reframing it to better demonstrate leadership.',
  },
  numericExamples: {
    none: 'This activity does not have any concrete (numeric) examples. Consider adding some.',
    one: 'This activity only has one concrete (numeric) example. Consider adding more.',
    multiple:
      'This activity has concrete (numeric) examples. Admissions counselors love quantifiable activities. Great job!',
  },
  descriptionLength: {
    veryShort: 'This activity description is way too short. You have up to 150 characters, go wild!',
    short: 'This activity description is too short. Is there anything else you could add?',
    adequate: 'This activity description is still a bit short. Consider elaborating on your points.',
    good: `This activity description is a good length. However, you still have a few characters to spare,
    so add a bit more detail if possible.`,
    perfect: 'This activity description is the perfect length. Great job!',
  },
  titleLength: {
    short: `This activity title is a bit short. Consider lengthening it, 
      perhaps by adding your full title or responsibility.`,
    adequate: 'This title is relevant and descriptive. Great job!',
  },
};

export const OVERALL_PRESETS = {
  1: 'Can you help me identify the key themes in my activities list?',
  2: 'How can I make my activities align better with my intended major?',
  3: 'How do I prioritize my activities to make a strong impression on college applications?',
};

export const INDIVIDUAL_PRESETS = {
  1: 'How can I describe this activity to show its impact?',
  2: 'Can you help me highlight my leadership or initiative in this activity?',
  3: 'Do you think my activity is too wordy?',
};

export const ERROR_MESSAGES = {
  title: 'Title is required',
  description: 'Description is required',
  organization: 'Organization is required',
  category: 'Category is required',
  schoolYears: 'School Years is required',
  hoursPerWeek: 'Hours per week should be greater than 0 and less than 168',
  weeksPerYear: 'Weeks per year should be greater than 0 and less than 52',
};

export const CHAR_LIMIT_TEXTS = {
  title: 'Activity title should not exceed 60 characters',
  description: 'Description should not exceed 1000 characters',
  organization: 'Organization name should not exceed 100 characters',
  category: ' ',
  schoolYears: ' ',
  hoursPerWeekMin: 'Cannot enter less than 0 hours',
  hoursPerWeekMax: 'Cannot enter more hours than in a week',
  weeksPerYearMin: 'Cannot enter less than 0 weeks ',
  weeksPerYearMax: 'Cannot enter more weeks than in a year',
};

export const LEADERSHIP_TOOLTIP = {
  noActivites: 'You have no activities that demonstrate leadership, consider adding some.',
  lowActivityTooltip: `While you have some activities that exhibit leadership,
    adding more would definitely improve your application's strength - try to aim for 5 out of 10, 
    if possible.`,
  averageActivityTooltip: `While you have a fair amount of activities that exhibit leadership, 
    adding more could make your application stand out.`,
  betterActivityTooltip: `You have a solid amount of leadership activities. Good job!`,
};
export const SERVICE_TOOLTIP = {
  noServices: `Community Service is an important factor in college admissions, 
    make sure you demonstrate  commitment to your community.`,

  averageServiceTooltip: `Having at least one more activity in a category related to 
      community service would help improve your application.`,
  betterServiceTooltip: `You have a solid amount of community service oriented activities.`,
};

export const ANALYTICS_TOOLTIP = {
  categoryBreakdown: `We group similar categories together. Ideally, you should focus on one or 
  two main interests; these are called Peaks.`,
  grade: `This is the weekly average amount of hours spent on your activities for each year of highschool.
          Make sure these hours are realistic!`,
};

export const UPLOAD_ACTIVITY_TOOLTIP = `Upload an Activities file (click to see supported formats)
            for us to extract and provide feedback on your activity list.`;

export const ACTIVITY_STRENGTH_TOOLTIP = {
  activityStrength: `This is our strength rating of your activity,we look at numerous factors
  to judge the strength of an activity- it's okay not every activity needs to be a 10/10!`,
  title: 'Try to make the title as detailed as possible within the character constraint.',
  shortDescription: 'Your description is too short, consider adding more detail.',
  averageDescription: 'Your description is a good length, but consider adding more detail.',
  goodDescription: 'You provided a lot of detail here-awesome!',
};
