import { Box, Button, HStack, Input, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface Section3Props {
  study: string;
  setStudy: any;
  handleSubmission: any;
  isDisabled: boolean;
  isLoading: boolean;
}

const Section3 = ({ study, setStudy, handleSubmission, isDisabled, isLoading }: Section3Props) => (
  <>
    <VStack width='100%' alignItems='start' spacing={4}>
      <Text color='primary' textAlign='start' fontSize='xl' fontWeight='semibold'>
        What do you want to study?
      </Text>
      <HStack width='100%' alignItems='center' justifyContent='start' spacing={4}>
        <Box
          display='flex'
          width={['180px', '240px', '366px', '366px']}
          height={['40px', '40px', '56px', '56px']}
          px='16px'
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
          gap='4px'
          borderRadius='12px'
          border='1px solid'
          borderColor='gray2'
          bg='white'
          boxShadow='0px 0px 12px 0px rgba(115, 115, 115, 0.05)'
        >
          <Input
            variant='unstyled'
            placeholder='Enter text here'
            width={'100%'}
            height='100%'
            fontSize={['xs', 'xs', 'md', 'md', 'md']}
            fontWeight='500'
            lineHeight='18px'
            _placeholder={{ color: '#98A2B3' }}
            onChange={(e) => setStudy(e?.target?.value)}
          />
        </Box>
        <Button
          maxWidth={'173px'}
          onClick={() => setStudy('I am not sure')}
          isActive={study === 'I am not sure'}
          display='flex'
          flex='1 0 0'
          fontSize={['xs', 'xs', 'md', 'md', 'md']}
          variant={'first_ux'}
        >
          I am not sure
        </Button>
      </HStack>
    </VStack>

    <HStack
      width='100%'
      alignItems='start'
      justifyContent={['center', 'center', 'center', 'start', 'start']}
      paddingTop={['5vh', '5vh', '5vh', '0', '0']}
    >
      <Button
        onClick={handleSubmission}
        height={'52px'}
        width={['300px', '300px', '300px', '173px', '173px']}
        padding={['12px 67px', '22px 67px']}
        justifyContent='center'
        alignItems='center'
        flexShrink='0'
        borderRadius='16px'
        bg='contrast'
        boxShadow='0px 24px 24px -16px rgba(3, 90, 65, 0.50)'
        color='white'
        textAlign='center'
        fontSize='14px'
        fontWeight='700'
        textTransform='uppercase'
        isDisabled={isDisabled}
        _hover={{ bg: 'darkGreen' }}
        _active={{ bg: 'darkGreen2' }}
        isLoading={isLoading}
      >
        NEXT
      </Button>
    </HStack>
  </>
);

export default Section3;
