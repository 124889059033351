import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CustomModal from './Modal';

const UpdateModal = ({ isOpen, onClose, onConfirm, modalHeaderText, bodyText, btnText1, btnText2 }: any) => (
  <CustomModal
    isOpen={isOpen}
    onClose={onClose}
    size={['xs', 'sm', 'md']}
    contentProps={{
      borderRadius: '12px',
      maxWidth: '480px',
      width: '100%',
    }}
    headerProps={{
      borderBottom: '1px solid #CDD8DF',
    }}
    bodyProps={{
      borderBottom: '1px solid #CDD8DF',
      p: '20px',
    }}
    header={
      <VStack alignItems='flex-start' spacing={2} width='100%'>
        <Text color={'primaryBlue'} fontSize={'16px'} fontWeight={700}>
          {modalHeaderText}
        </Text>
      </VStack>
    }
    body={
      <Text color={'secondaryBlue'} fontSize={'16px'} fontWeight={500}>
        {bodyText}
      </Text>
    }
    footer={
      <Box w='100%' display='flex' justifyContent='flex-end'>
        <HStack spacing={4}>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            fontWeight={600}
            fontSize={'16px'}
            border='none'
            colorScheme={'primary'}
            variant='ghost'
            textDecoration='underline'
          >
            {btnText1}
          </Button>
          <Button
            fontWeight={600}
            fontSize={'16px'}
            variant={'outline'}
            color='primary'
            borderColor={'contrast'}
            borderRadius={'8px'}
            onClick={onClose}
          >
            {btnText2}
          </Button>
        </HStack>
      </Box>
    }
  />
);

export default UpdateModal;
