import { Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

const TaskDrawerSkeleton: React.FC = () => (
  <VStack width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
    {[...Array(3)].map((_, index) => (
      <Skeleton
        key={index}
        startColor='gray2'
        endColor='gray1'
        height='200px'
        borderRadius={'lg'}
        width={'100%'}
        my={2}
      />
    ))}
  </VStack>
);

export default TaskDrawerSkeleton;
