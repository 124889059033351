import { Routes } from 'Constants/constants';

export const INTAKE_PROMPTS = [
  { name: '2025', value: '2025' },
  { name: '2026', value: '2026' },
  { name: '2027', value: '2027' },
  { name: 'Other', value: 'other' },
];

export const INTEREST_PROMPTS = [
  {
    name: 'Getting feedback on my college essay',
    value: 'Getting feedback on my college essay',
    route: Routes.Essays,
  },
  { name: 'Finding colleges that fit my needs', value: 'Finding colleges that fit my needs', route: Routes.Colleges },
  {
    name: 'Brainstorming essay topics/planning my essays',
    value: 'Brainstorming essay topics/planning my essays',
    route: Routes.Essays,
  },
  {
    name: 'Building up and getting feedback on my extracurriculars',
    value: 'Building up and getting feedback on my extracurriculars',
    route: Routes.Activities,
  },
  { name: 'I am not sure', value: 'I am not sure', route: Routes.Home },
];
