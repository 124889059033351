import { EditIcon } from '@chakra-ui/icons';
import { HStack, Input, Text, Tooltip } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { userIdAtom } from '../../../Atoms/App/user';
import { AnalyticsScreenName } from '../../../Constants/constants';
import { useUpdateProfileName } from '../../../Hooks/Profile/useProfile';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';

interface ChangeNameProps {
  userName: string;
}

const ChangeName: React.FC<ChangeNameProps> = ({ userName }) => {
  const userId = useAtomValue(userIdAtom);
  const [name, setName] = useState(userName);
  const { mutate } = useUpdateProfileName();
  const [editing, setEditing] = useState(false);
  const { trackEvent } = useMixpanel();

  const handleBlur = () => {
    if (name !== null && name?.trim() !== '' && name?.trim() !== userName?.trim()) {
      mutate({
        id: userId,
        firstname: name?.split(' ')[0],
        lastname: name?.split(' ').slice(1).join(' '),
      });
    } else {
      setName(userName?.trim());
    }

    setEditing(false);
  };

  useEffect(() => {
    setName(userName);
  }, [userName]);

  return (
    <>
      {editing ? (
        <Input
          mb={2}
          p={2}
          size='md'
          bg='white'
          fontWeight='semibold'
          alignSelf={['center', 'start']}
          color='primary'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              (e.target as HTMLInputElement).blur();
            }
          }}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <HStack gap={2} w='full' justifyContent='center'>
          <Text
            color='contrast'
            fontSize='18px'
            fontWeight='semibold'
            align={['center']}
            maxW={['70vw', '50vw', '20vw', '20vw']}
            flexWrap='wrap'
          >
            {name}
          </Text>
          <Tooltip label='Edit Name' placement='bottom'>
            <EditIcon
              boxSize={4}
              cursor='pointer'
              _hover={{ bg: 'gray1', color: 'primaryLight' }}
              onClick={() => {
                setEditing(true);
                trackEvent('Edit Name', {
                  screenName: AnalyticsScreenName.ScreenProfile,
                  label: 'Click on Edit Name Button on Edit Profile',
                });
              }}
            />
          </Tooltip>
        </HStack>
      )}
    </>
  );
};

export default ChangeName;
