/* eslint-disable no-nested-ternary */
import { Alert, AlertIcon, Box, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import useLottieAnimation from '../../../Hooks/App/useFetchLottie'; // Adjust the import path accordingly

interface DemoTooltipContentProps {
  isAnimation?: boolean;
  imageSrc?: string;
  animationUrl?: string; // Changed from animationData to animationUrl
  title: string;
  content: string;
}

const DemoTooltipContent = ({
  isAnimation = false,
  imageSrc,
  animationUrl,
  title,
  content,
}: DemoTooltipContentProps) => {
  const { animationData, loading, error } = useLottieAnimation(animationUrl || '');
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imageSrc) {
      setImageLoaded(true);
    }
  }, [imageSrc]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const renderAnimation = () =>
    loading ? (
      <Box w={'auto'} h={'20vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Spinner size='lg' />
      </Box>
    ) : error ? (
      <Alert status='error'>
        <AlertIcon />
        Error loading animation.
      </Alert>
    ) : (
      <Lottie options={defaultOptions} height={'auto'} width={'auto'} />
    );

  const renderImage = () =>
    !imageLoaded ? (
      <Box w={'auto'} h={'20vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Spinner size='lg' />
      </Box>
    ) : (
      <Image src={imageSrc} w={'full'} />
    );

  return (
    <VStack w={'full'} h={'full'} gap={2} justifyContent={'center'} alignItems={'center'} mb={2}>
      <Box w={'full'} h={'full'} bg={'#f6f6f6'} p={2}>
        {isAnimation && renderAnimation()}
        {!isAnimation && renderImage()}
      </Box>
      <VStack w={'full'} px={4}>
        <Text color={'gray6'} fontWeight={'semibold'} fontSize={['xs', 'sm', 'md']} align={'start'} w={'full'}>
          {title}
        </Text>
        <Text color={'gray5'} fontSize={['xs', 'sm', 'sm']} fontWeight={'medium'} align={'start'} w={'full'}>
          {content}
        </Text>
      </VStack>
    </VStack>
  );
};

export default DemoTooltipContent;
