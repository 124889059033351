import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { FormLabel, Select } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";

const SelectFieldV2 = ({
  label,
  emptyLabel,
  options,
  labelSize = ["sm", "md", "md"],
  ...props
}: any) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isInvalid={meta.touched && meta.error} {...props}>
      {meta.value && label && (
        <FormLabel
          position="absolute"
          fontSize={"10px"}
          paddingTop={0.5}
          paddingLeft={3}
          color={meta.error ? "pink" : "gray.500"}
          zIndex={10}
        >
          {label}
        </FormLabel>
      )}
      <Select
        style={{ width: "100%", paddingTop: meta.value && label ? "12px" : "" }}
        fontSize={labelSize}
        color={"gray5"}
        fontWeight={"medium"}
        borderRadius={"xl"}
        bg="gray.50"
        focusBorderColor="primaryGreen"
        {...field}
        {...props}
      >
        <option value="">{emptyLabel}</option>
        {options.map((x: any) => (
          <option key={x} value={x} style={{ paddingTop: label ? "2px" : "" }}>
            {x}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default SelectFieldV2;
