import { Box, Grid, GridItem, Show, Spinner } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { UserCollegesAtom } from '../../Atoms/Planner/planner';
import { AnalyticsScreenName } from '../../Constants/constants';
import usePlanner from '../../Hooks/Planner/usePlanner';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import PlannerTabs from './MobileViewTabs';
import SidePanel from './SidePanel/SidePanel';
import PlannerTaskList from './TaskList/ListView';

const Planner: React.FC = () => {
  const [userCollegesAtom] = useAtom(UserCollegesAtom);
  const [areUserCollegesLoading, setAreUserCollegesLoading] = useState(false);

  useMixpanel(AnalyticsScreenName.ScreenPlanner);
  const { getCollegesForPlanner } = usePlanner();

  useEffect(() => {
    getCollegesForPlanner();
  }, []);

  useEffect(() => {
    setAreUserCollegesLoading(userCollegesAtom?.areUserCollegesLoading);
  }, [userCollegesAtom]);

  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(12, 1fr)']}
      paddingLeft={[0, 0, 0, 20]}
      paddingY={4}
      height={'92vh'}
      width={'100%'}
      className='planner-main'
    >
      <Show below='lg'>
        <PlannerTabs />
      </Show>
      <Show above='lg'>
        <GridItem colSpan={[3, 3, 3, 3]}>
          <SidePanel />
        </GridItem>
        <GridItem colSpan={[9, 9, 9, 9]} height={'100%'}>
          {areUserCollegesLoading ? (
            <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Spinner />
            </Box>
          ) : (
            <PlannerTaskList />
          )}
        </GridItem>
      </Show>
    </Grid>
  );
};

export default Planner;
