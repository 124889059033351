import axios from 'axios';

class CollegeService {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }
  async updatePriority(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/college/updatepos`, data, {
        withCredentials: true,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error) {
      console.error('Error updating college priority', error);
      throw error;
    }
  }

  async updatePriorityAll(data: any) {
    try {
      const response = await axios.patch(`${this.apiBaseUrl}/college/updatePosAll`, data, {
        withCredentials: true,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error) {
      console.error('Error updating college priority', error);
      throw error;
    }
  }

  async getColleges(id: any) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/list/${id}`);

      return response.data.data;
    } catch (error) {
      console.error('Error fetching colleges:', error);
      throw error;
    }
  }

  async removeCollege(collegeId: any, user_id: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/college/remove/${user_id}`, { college_id: collegeId });

      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error("Error updating user's college_ids", error);
      throw error;
    }
  }

  async addCollege(collegeId: any, user_id: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/college/add/${user_id}`, {
        college_id: collegeId,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error("Error updating user's college_ids", error);
    }

    return null;
  }

  async getCollegeName(collegeId: any) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/${collegeId}/name`);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    } catch (error) {
      console.error('Error fetching college name', error);
    }

    return null;
  }

  async getCollegeNames(collegeIds: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/college/names`, {
        ids: collegeIds,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    } catch (error) {
      console.error('Error fetching college names', error);
    }

    return null;
  }

  async getCollege(collegeId: any) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/fetch/${collegeId}`);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error) {
      console.error('Error fetching college', error);
    }

    return null;
  }

  async getCollegeByName(collegeName: any) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/${collegeName}`);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    } catch (error) {
      console.error('Error fetching college', error);
    }

    return null;
  }

  async getCollegePrompts(collegeId: any) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/${collegeId}/prompts`);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    } catch (error) {
      console.error('Error fetching college prompts', error);
    }

    return null;
  }

  async getAllCollegePrompts(collegeIds: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/college/promptsAll`, {
        collegeIds: collegeIds,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    } catch (error) {
      console.error('Error fetching college prompts', error);
    }

    return null;
  }

  async getFilteredColleges(filters: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/college/search`, {
        data: filters,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data.colleges;
    } catch (error) {
      console.error('Error fetching filtered colleges', error);
    }

    return null;
  }

  async fetchColleges() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/fetch`);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error) {
      console.error('Error fetching colleges', error);
    }

    return null;
  }

  async fetchMinifiedColleges() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/fetchmin`);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error) {
      console.error('Error fetching minified colleges', error);
    }

    return null;
  }
}

const collegeService = new CollegeService(`${process.env.REACT_APP_BASE_URL}`);
export default collegeService;
