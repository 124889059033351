export enum MESSAGES_TYPE {
  USER = 'user',
  BOT = 'bot',
}

export const CHATBOX_DIMENSIONS = {
  COLLAPSED: {
    WIDTH: '312px',
    HEIGHT: '522px',
  },
  EXPANDED: {
    WIDTH: '472px',
    HEIGHT: '522px',
  },
};
