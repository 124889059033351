import { Button, Divider, Stack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { userFullNameAtom } from '../../../Atoms/App/user';
import {
  backgroundAnswersAtom,
  backgroundProgressPercentageAtom,
  isErrorSavingProfileAtom,
  isProfileSavingAtom,
  profileProgressAtom,
} from '../../../Atoms/Profile/profile';
import { AnalyticsScreenName } from '../../../Constants/constants';
import useAuth from '../../../Hooks/Auth/useAuth';
import { useGetBackgroundAnswers } from '../../../Hooks/Profile/useProfile';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import AutoSave from '../../../lib/UI-Components/AutoSave';
import ProfileAvatar from '../../../lib/UI-Components/ProfileAvatar';
import ProgressDonut from '../../../lib/UI-Components/ProgressDonut'; // @ts-ignore
import ChangeName from './ChangeName';

interface IProfileSummaryProps {
  showEditProfileButton: boolean;
}

const ProfileSummary: React.FC<IProfileSummaryProps> = ({ showEditProfileButton }) => {
  const { trackEvent } = useMixpanel();
  const userFullName = useAtomValue(userFullNameAtom);
  const { logOut } = useAuth();
  const backgroundProgress = useAtomValue(backgroundProgressPercentageAtom);
  const profileProgress = useAtomValue(profileProgressAtom);
  const isProfileSaving = useAtomValue(isProfileSavingAtom);
  const isErrorSaving = useAtomValue(isErrorSavingProfileAtom);
  const { data: answers } = useGetBackgroundAnswers();
  const setBackgroundAnswers = useSetAtom(backgroundAnswersAtom);

  const dividerOrientation = useBreakpointValue<'horizontal' | 'vertical' | undefined>({
    base: 'vertical',
    sm: 'vertical',
    md: 'horizontal',
    lg: 'vertical',
  });

  useEffect(() => {
    setBackgroundAnswers(answers);
  }, [answers]);

  return (
    <VStack
      spacing={4}
      align='start'
      w={['100%', '100%', '260px', '320px']}
      h='fit-content'
      p='3'
      borderWidth='1px'
      borderRadius={['xl', 'xl']}
      bg='foreground'
      alignItems={'center'}
    >
      <ProfileAvatar username={userFullName} boxSize={[14, 20]} fontSize={['xl', '4xl']} />
      <ChangeName userName={userFullName} />
      <Stack
        flexDirection={['row', 'row', 'column', 'row']}
        width={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingX={4}
      >
        <ProgressDonut value={profileProgress} label='Profile Completed' />
        <Divider
          orientation={dividerOrientation}
          borderColor='gray2'
          borderWidth={'thin'}
          ml={1}
          sx={{ height: dividerOrientation === 'vertical' ? '2rem' : 0 }}
        />
        <ProgressDonut value={backgroundProgress} label='Background Completed' />
      </Stack>
      {showEditProfileButton && <AutoSave isSaving={isProfileSaving} isError={isErrorSaving} />}
      <Button
        variant={'logOut'}
        w='100%'
        onClick={async () => {
          await trackEvent('Logout', {
            screenName: AnalyticsScreenName.ScreenProfile,
            label: 'Click on Logout Button on Edit Profile',
          });
          logOut();
        }}
      >
        Logout
      </Button>
    </VStack>
  );
};

export default ProfileSummary;
