import {
  Text,
  Button,
  HStack,
  Box,
} from "@chakra-ui/react";
import React from "react";
import CustomModal from "../lib/UI-Components/Modal";

const WarningModal = ({
  isOpen,
  onClose,
  setIsConfirmed,
}: any) => (
  <CustomModal
    isOpen={isOpen}
    onClose={onClose}
    size={["xm", "sm", "md"]}
    backdropFilter="blur(5px)"
    header={
      <HStack justifyContent={"space-between"}>
        <Text color={"black"} fontSize={"sm"} fontWeight={"semibold"}>
          Are you sure you want to remove this college?
        </Text>
      </HStack>
    }
    body={
      <Text
        w="100%"
        align="left"
        fontWeight="medium"
        fontSize={"sm"}
        color="gray.500"
        alignSelf="center"
      >
        You can always add the college back later from the Colleges tab. We
        save your essays!
      </Text>
    }
    footer={
      <Box justifyContent={"space-between"} w={"100%"}>
        <Button
          variant='ghost'
          mr={1}
          w={"49%"}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          w={"49%"}
          variant='logOut'
          onClick={() => {
            setIsConfirmed(true);
          }}
        >
          Remove
        </Button>
      </Box>
    }
  />
);

export default WarningModal;
