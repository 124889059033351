import { Input, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const labelStyle = (isError) => ({
  position: 'absolute',
  color: isError ? 'red' : '#98A2B3',
  zIndex: 100,
  fontSize: '10px',
  alignSelf: 'flex-start',
  marginLeft: '12px',
  marginTop: '4px',
});

const CustomInput = ({
  onChange,
  isError,
  value,
  placeholder,
  label,
  w = '100%',
  showLabel = false,
  isFormDirty,
  errorMsg,
  ...props
}: any) => {
  const [hasSomeValue, setHasSomeValue] = useState(false);

  useEffect(() => {
    if (typeof value === 'string' && value?.trim() !== '') {
      setHasSomeValue(true);
    } else if (typeof value === 'number') {
      setHasSomeValue(true);
    } else {
      setHasSomeValue(false);
    }
  }, [value]);
  return (
    <VStack w={w}>
      {showLabel && (hasSomeValue || isError) && (
        <span style={labelStyle(isError && isFormDirty) as React.CSSProperties}>
          {isError && isFormDirty ? errorMsg : label}
        </span>
      )}
      <Input
        h='50px'
        placeholder={placeholder}
        focusBorderColor='primary'
        value={value ? value : ''}
        borderRadius='12px'
        backgroundColor='#F9FAFB'
        fontSize='16px'
        paddingTop={showLabel && (hasSomeValue || isError) ? '6px' : 0}
        border='1px solid rgb(226, 232, 240)'
        onChange={onChange}
        {...props}
      />
    </VStack>
  );
};

export default CustomInput;
