import { Alert, AlertIcon, Button, Checkbox, HStack, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { AnalyticsScreenName } from '../../Constants/constants';
import { KOLLEGIO_TERMS_LINKS } from '../../Constants/signup';
import useAuth from '../../Hooks/Auth/useAuth';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import CustomFormikField from '../../lib/UI-Components/CustomFormikField';

import { registerFormSchema } from '../../lib/schemas';

interface ISignUpForm {
  referrer: null | string;
  referralName: null | string;
}

const SignUpForm: React.FC<ISignUpForm> = ({ referrer, referralName }) => {
  const [isChecked, setIsChecked] = useState(false);
  // const [isEmailSubscriptionChecked, setIsEmailSubscriptionChecked] = useState(false);
  const { trackEvent } = useMixpanel();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { error: serverSideError, signUp, isLoading } = useAuth();

  const handleSubmit = (values) => {
    values.email = values.email.toLowerCase();

    if (!!referrer) {
      values.referrerCode = referrer;
    } else if (!!referralName) {
      values.referrerCode = referralName;
    }

    signUp({
      ...values,
    });
  };

  const validate = (values) => {
    const errors = {
      email: '',
      password: '',
      confirmPassword: '',
      firstname: '',
      lastname: '',
      referrerCode: '',
    };

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values?.password) {
      errors.password = 'Required';
    } else if (values.password?.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (!values?.confirmPassword) {
      errors.confirmPassword = 'Required';
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Passwords must match';
    }

    if (!values?.firstname) {
      errors.firstname = 'Required';
    }

    if (!values?.lastname) {
      errors.lastname = 'Required';
    }

    return errors;
  };

  return (
    <VStack width={['100%', '100%', '54%', '54%']} alignItems={'center'} justifyContent={'center'}>
      {serverSideError && (
        <Alert width={'100%'} status='error'>
          <AlertIcon /> {serverSideError}
        </Alert>
      )}
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
          firstname: '',
          lastname: '',
          referrerCode: '',
        }}
        validateSchema={registerFormSchema}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <VStack spacing={[4, 4, 4, 4, 5]} width='100%' justifyContent={'center'} alignItems={'center'}>
              <HStack width='100%'>
                <CustomFormikField
                  label='First Name'
                  name='firstname'
                  type='text'
                  value={values?.firstname}
                  placeholder='Enter first name'
                  errors={errors}
                  isFormDirty={isFormDirty}
                />
                <CustomFormikField
                  label='Last Name'
                  placeholder='Enter last name'
                  type='text'
                  value={values?.lastname}
                  name='lastname'
                  errors={errors}
                  isFormDirty={isFormDirty}
                />
              </HStack>
              {/* {isPhoneFieldVisible && (
                <HStack width={'100%'}>
                  <DropdownSelect
                    w='87px'
                    h='50px'
                    borderRadius='12px'
                    backgroundColor='#F9FAFB'
                    color='primary'
                    fontSize='16px'
                    textAlign='right'
                    border='1px solid rgb(226, 232, 240)'
                    p={0}
                    name='Code'
                    showLabel
                    focusBorderColor='rgb(226, 232, 240)'
                    onChange={(e) => {
                      setInputCode(e.target.value);
                      setSelectedCountry(dialCodesWithCountry[e.target.value]);
                    }}
                    options={dialCodes}
                    value={inputCode}
                  />
                  <CustomInput
                    type='tel'
                    isError={!isPhoneValid && phoneNumber !== ''}
                    isFormDirty={isFormDirty}
                    name='Phone Number'
                    placeholder='Enter phone number'
                    value={phoneNumber}
                    showLabel
                    errorMsg='Invalid phone number'
                    label='Phone Number'
                    onChange={handlePhoneNumberChange}
                  />
                </HStack>
              )} */}
              <CustomFormikField
                label='Email'
                name='email'
                type='email'
                value={values?.email}
                placeholder='Enter email'
                errors={errors}
                isFormDirty={isFormDirty}
              />
              <CustomFormikField
                label='Password'
                placeholder='Enter password'
                type='password'
                value={values?.password}
                name='password'
                errors={errors}
                isFormDirty={isFormDirty}
              />
              <CustomFormikField
                label='Confirm Password'
                placeholder='Confirm password'
                type='password'
                value={values?.confirmPassword}
                name='confirmPassword'
                errors={errors}
                isFormDirty={isFormDirty}
              />
              {!referrer && !referralName && (
                <CustomFormikField
                  label='Referral Code (if any)'
                  placeholder='Enter referral code (if any)'
                  type='text'
                  value={values?.referrerCode}
                  name='referrerCode'
                  errors={errors}
                  isFormDirty={isFormDirty}
                />
              )}
              <VStack width={'100%'} spacing={3} alignItems={'flex-start'}>
                <Checkbox onChange={(e) => setIsChecked(e.target.checked)} colorScheme='primary'>
                  <Text fontSize={['xs', 'sm', 'sm', 'xs']}>
                    By continuing, you agree to our{' '}
                    <Link href={KOLLEGIO_TERMS_LINKS.termsAndConditions} color='contrast' target='_blank'>
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href={KOLLEGIO_TERMS_LINKS.privacyPolicy} color='contrast' target='_blank'>
                      Privacy Policy.
                    </Link>
                  </Text>
                </Checkbox>
                {/* <Checkbox
                  onChange={async (e) => {
                    await trackEvent(`Subscribe to Newsletter`, {
                      screenName: AnalyticsScreenName.ScreenJoin,
                      label: 'Click Marketing Communication Checkbox',
                    });
                    setIsEmailSubscriptionChecked(e.target.checked);
                  }}
                  colorScheme='primary'
                >
                  <Text fontSize={['xs', 'sm', 'sm', 'xs']}>
                    Subscribe to our newsletter to receive the latest updates in college admissions!
                  </Text>
                </Checkbox> */}
                <Button
                  width={'100%'}
                  size={'lg'}
                  borderRadius={'xl'}
                  backgroundColor={'primary'}
                  type='submit'
                  color={'foreground'}
                  isDisabled={!isChecked}
                  boxShadow={'0px 24px 24px -16px rgba(3, 90, 65, 0.50)'}
                  _hover={{
                    backgroundColor: 'primaryLight',
                  }}
                  onClick={async () => {
                    setIsFormDirty(true);

                    if (
                      errors?.email === '' &&
                      errors?.password === '' &&
                      errors?.confirmPassword === '' &&
                      errors?.firstname === '' &&
                      errors?.lastname === ''
                    ) {
                      await trackEvent(`Create Account`, {
                        screenName: AnalyticsScreenName.ScreenJoin,
                        label: 'Click on Create Account Button on Join',
                      });

                      handleSubmit(values);
                    }
                  }}
                >
                  {isLoading ? <Spinner /> : 'Create Account'}
                </Button>
              </VStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default SignUpForm;
