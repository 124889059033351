import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface CustomHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const handlePrevClick = (e) => {
    e.stopPropagation();
    decreaseMonth();
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    increaseMonth();
  };

  return (
    <HStack width={'100%'} mt={-3} justifyContent={'space-between'} className='custom-header'>
      <Button
        onClick={handlePrevClick}
        _hover={{
          color: 'gray5',
        }}
        bgColor='foreground'
        color='primary'
        fontWeight={700}
        fontSize='lg'
        isDisabled={prevMonthButtonDisabled}
      >
        <ChevronLeftIcon boxSize={7} />
      </Button>
      <Text fontWeight={700} fontSize='md' color='gray8'>
        {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
      </Text>
      <Button
        _hover={{
          color: 'gray5',
        }}
        bgColor='foreground'
        color='primary'
        fontWeight={700}
        fontSize='lg'
        onClick={handleNextClick}
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRightIcon boxSize={7} />
      </Button>
    </HStack>
  );
};

export default CustomHeader;
