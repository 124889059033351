import { getCollegeReachTag } from 'Components/Colleges/collegeConstants';
import { College } from 'college';
import { COLLEGE_DEADLINE_TYPES } from '../Constants/constants';

/* eslint-disable @typescript-eslint/no-shadow */
const processDeadlines = (college: any) => {
  const today = new Date();

  const deadlines = [
    {
      deadline: college.rea_deadline,
      type: COLLEGE_DEADLINE_TYPES.RESTRICTIVE_EARLY_ACTION,
    },
    {
      deadline: college.ea_deadline,
      type: COLLEGE_DEADLINE_TYPES.EARLY_ACTION,
    },
    {
      deadline: college.ed_deadline,
      type: COLLEGE_DEADLINE_TYPES.EARLY_DECISION,
    },
    {
      deadline: college.ed2_deadline,
      type: COLLEGE_DEADLINE_TYPES.EARLY_DECISON_II,
    },
    {
      deadline: college.rd_deadline,
      type: COLLEGE_DEADLINE_TYPES.REGULAR_DECISON,
    },
  ]
    .map((item) => {
      const dateString = item.deadline?.trim();
      return isNaN(Date.parse(dateString)) ? null : { deadline: new Date(dateString), type: item.type };
    })
    .filter(Boolean);

  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  deadlines.sort((a, b) => a.deadline - b.deadline);

  const closestDeadline =
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    deadlines.find((item) => item.deadline > today) || null;

  return { ...college, closestDeadline };
};

export { processDeadlines };

export const formatPercentage = (value: any): string =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);

export const formatTuition = (value: any): string | null => {
  if (value && value !== 0) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  return null;
};

export const formatDate = (value: any): string => {
  const valueAsString = typeof value === 'string' ? value?.trim() : String(value)?.trim();
  const date: Date = new Date(valueAsString);

  // Check if the date created is valid.
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Extract the UTC day, month, and year from the date object.
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();

  // Assemble the date string
  const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;

  return formattedDate;
};

export const handleCollegeDataProcessing = (collegesData: College[], user: any) => {
  const collegesDataNoCommonApp = collegesData?.filter(function (college: any) {
    return college.name !== 'Common App';
  });

  const collegesWithReachTags = collegesDataNoCommonApp?.map((college: any) => ({
    ...college,
    reachTag: getCollegeReachTag(college, user),
  }));

  return collegesWithReachTags;
};

export const isUserCollege = (college: College, userColleges: College[]): boolean =>
  userColleges.some((userCollege) => userCollege?.college_id === college?.college_id);

export const shouldTruncateText = (text: string) => text?.split(' ')?.length > 85;

export const getTruncatedText = (description: string, showMore: boolean, college: College) => {
  if (showMore || !shouldTruncateText(description)) {
    return description;
  }

  return `${college?.description?.split(' ')?.slice(0, 85)?.join(' ')}...`;
};

export const filterColleges = (colleges: College[], filters: any) => {
  // Apply the filtering logic here based on the filters
  let filteredColleges = colleges;

  if (filters.search) {
    filteredColleges = filteredColleges.filter((college) =>
      college.name.toLowerCase().includes(filters.search.toLowerCase())
    );
  }

  // Apply sorting
  // filteredColleges = filteredColleges.sort((a, b) => {
  //   const order = filters.order === 'asc' ? 1 : -1;
  //   return a[filters.sort] > b[filters.sort] ? order : -order;
  // });

  // Apply reach_tag filtering if needed
  if (filters.reach_tag && filters.reach_tag !== '' && filters.reach_tag !== 'Default') {
    filteredColleges = filteredColleges?.filter((college) => college.reachTag === filters.reach_tag);
  }

  // Apply pagination
  // const start = (filters.page - 1) * filters.limit;
  // const end = start + filters.limit;
  // filteredColleges = filteredColleges.slice(start, end);

  return filteredColleges;
};
