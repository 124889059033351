import { Button, Heading, HStack, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { userFirstNameAtom } from '../../../Atoms/App/user';
import { backgroundAnswersAtom, backgroundProgressPercentageAtom } from '../../../Atoms/Profile/profile';
import { AnalyticsScreenName } from '../../../Constants/constants';
import { PROFILE_BACKGROUND_GROUPS } from '../../../Constants/profile';
import { useGetBackgroundAnswers, useGetBackgroundQuestions } from '../../../Hooks/Profile/useProfile';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import ProgressDonut from '../../../lib/UI-Components/ProgressDonut';
import BackgroundInfoModal from './BackgroundInfoModal';
import FirstQuestion from './FirstQuestion';

const BackgroundInfo = () => {
  const { trackEvent } = useMixpanel();
  const { data: questions } = useGetBackgroundQuestions();
  const { data: answers } = useGetBackgroundAnswers();
  const userFirstName = useAtomValue(userFirstNameAtom);
  const setBackgroundAnswers = useSetAtom(backgroundAnswersAtom);
  const backgroundProgress = useAtomValue(backgroundProgressPercentageAtom);

  const [backgroundEventTracked, setBackgroundEventTracked] = useState(false);

  const [groups, setGroups] = useState(PROFILE_BACKGROUND_GROUPS);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleCloseModal = () => {
    onClose();
    setSelectedGroup(null);
  };

  useEffect(() => {
    if (questions?.length > 0) {
      setGroups((prev) => {
        const groupedQuestions = prev?.map((group) => {
          const updatedQuestions = questions
            ?.filter((question) => group?.positions?.includes(question?.position))
            .map((question) => {
              const answerObj = answers?.find((answer) => answer?.question_id === question?.id) || '';

              return {
                ...question,
                answer: answerObj,
                hasAnswer: answerObj?.answer?.trim() === '' || !answerObj?.answer ? false : true,
              };
            });

          const progress = updatedQuestions?.reduce((acc: number, question) => (question.hasAnswer ? acc + 1 : acc), 0);

          return {
            ...group,
            progress: Math.ceil((progress / updatedQuestions?.length) * 100),
            questions: updatedQuestions,
          };
        });

        return groupedQuestions;
      });
    }
  }, [questions, answers]);

  useEffect(() => {
    const selectedGroupId = selectedGroup?.id;
    setSelectedGroup(groups?.find((group) => group?.id === selectedGroupId));
  }, [groups]);

  useEffect(() => {
    setBackgroundAnswers(answers);
  }, [answers]);

  useEffect(() => {
    if (backgroundProgress === 100 && !backgroundEventTracked) {
      trackEvent('Background information is complete', {
        screenName: AnalyticsScreenName.ScreenProfile,
        label: 'Background Completion Status is 100%',
      });
      setBackgroundEventTracked(true);
    }
  }, [backgroundProgress]);

  const renderGroup = (group, i) => (
    <VStack
      key={`background-group-${i}`}
      background={'foreground'}
      width={'100%'}
      borderRadius={'lg'}
      padding={4}
      gap={4}
      boxShadow={'0px 0px 12px 0px rgba(115, 115, 115, 0.05)'}
      alignItems={['center', 'flex-start']}
    >
      <Stack
        flexDirection={['column', 'row']}
        width={'100%'}
        justifyContent={['center', 'space-between']}
        alignItems={['center']}
      >
        <VStack alignItems={['center', 'flex-start']} width={'100%'}>
          <Text color={'primary'} textAlign={['center', 'left']} fontWeight={700} fontSize={['sm', 'lg']}>
            {group?.heading}
          </Text>
          <Text color={'gray4'} textAlign={['center', 'left']} fontWeight={500} fontSize={['sm', 'lg']}>
            {group?.subheading}
          </Text>
        </VStack>
        <HStack>
          <ProgressDonut value={group?.progress} label='Completed' />
        </HStack>
      </Stack>
      <Button
        size={'lg'}
        variant={'secondary'}
        onClick={() => {
          setSelectedGroup(groups[i]);
          onOpen();
        }}
      >
        Start
      </Button>
    </VStack>
  );

  return (
    <VStack width={'100%'} spacing={4} paddingX={4} alignItems={'flex-start'}>
      <Heading as='h2' size='md' noOfLines={1} color={'primary'} alignSelf={'start'}>
        Hey {userFirstName}
      </Heading>
      <Text color={'gray5'}>
        The more insights you provide, the more accurate the suggestions we can offer- fill in these questions at your
        own pace. Now is not the time to be humble!
      </Text>
      <FirstQuestion
        question={questions?.filter((q) => q?.id === 1) ? questions?.filter((q) => q?.id === 1)[0] : null}
        answer={answers?.find((answer) => answer.question_id === 1)}
      />
      <Text color={'gray4'} fontWeight={500} fontSize={'lg'}>
        Let&apos;s get to know you more! You can skip and return to any questions later.
      </Text>
      {groups.map((group, i) => renderGroup(group, i))}
      <BackgroundInfoModal isOpen={isOpen} group={selectedGroup} onClose={handleCloseModal} />
    </VStack>
  );
};

export default BackgroundInfo;
