import { WarningTwoIcon } from '@chakra-ui/icons';
import { HStack, Image, Text } from '@chakra-ui/react';
import React from 'react'; // @ts-ignore
import autoSavedSrc from './../../Assets/icons/auto-saved.svg'; // @ts-ignore
import autoSavingSrc from './../../Assets/icons/auto-saving.svg';

interface AutoSaveProps {
  isSaving: boolean;
  isError?: boolean;
}

const AutoSave: React.FC<AutoSaveProps> = ({ isSaving, isError = false }) => (
  <HStack spacing={isError ? 1 : -1}>
    {isSaving && !isError ? (
      <Image src={autoSavingSrc} alt='autosave' boxSize='24px' objectFit='scale-down' rounded='full' />
    ) : (
      !isError && <Image src={autoSavedSrc} alt='autosave' boxSize='24px' objectFit='scale-down' rounded='full' />
    )}
    {isError && !isSaving && <WarningTwoIcon color={'#FD6900'} />}
    <Text
      color={isSaving ? 'yellow' : isError ? '#FD6900' : 'primaryLight'}
      fontSize='sm'
      fontWeight='medium'
      fontStyle='italic'
    >
      {isSaving ? 'Autosaving...' : isError ? 'Not Saved' : 'Autosaved'}
    </Text>
  </HStack>
);

export default AutoSave;
