export enum ESSAY_FEATURES_TAB {
  EssayReview = 'essayReview',
  EssayPlan = 'essayPlan',
  AskKollegio = 'askKollegio',
}

export enum AI_TOOL_NAME {
  Lengthen = 'lengthen',
  Shorten = 'shorten',
  Reword = 'reword',
  Transition = 'transition',
  Merge = 'merge',
  Suggestion = 'suggestion',
}

export const AI_TOOL_LABELS = {
  [AI_TOOL_NAME.Lengthen]: 'Lengthen',
  [AI_TOOL_NAME.Shorten]: 'Shorten',
  [AI_TOOL_NAME.Reword]: 'Reword',
  [AI_TOOL_NAME.Transition]: 'Transition',
  [AI_TOOL_NAME.Merge]: 'Merge Idea',
  [AI_TOOL_NAME.Suggestion]: 'Suggestion',
};
export const AI_TOOL_MIN_WORD_LENGTH = {
  [AI_TOOL_NAME.Lengthen]: 5,
  [AI_TOOL_NAME.Shorten]: 10,
  [AI_TOOL_NAME.Reword]: 10,
  [AI_TOOL_NAME.Transition]: 15,
  [AI_TOOL_NAME.Merge]: 10,
  [AI_TOOL_NAME.Suggestion]: 5,
};

export const DEFAULT_AI_TOOL_TIPS = {
  [AI_TOOL_NAME.Lengthen]: 'Highlight a sentence or paragraph you would like to lengthen',
  [AI_TOOL_NAME.Shorten]: 'Highlight a sentence or paragraph you would like to shorten',
  [AI_TOOL_NAME.Reword]: 'Highlight a sentence or paragraph you would like to reword',
  [AI_TOOL_NAME.Transition]: 'Highlight a sentence or paragraph that you would like to integrate an idea into',
  [AI_TOOL_NAME.Merge]: 'Highlight ideas you would like to merge',
  [AI_TOOL_NAME.Suggestion]: 'Highlight a sentence or paragraph that you would like next step suggestions for',
};

export const DISABLED_AI_TOOL_TIPS = {
  [AI_TOOL_NAME.Lengthen]: `Must select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Lengthen]} words`,
  [AI_TOOL_NAME.Shorten]: `Must select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Shorten]} words`,
  [AI_TOOL_NAME.Reword]: `Must select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Reword]} words`,
  [AI_TOOL_NAME.Transition]: `Must select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Transition]} words`,
  [AI_TOOL_NAME.Merge]: `Must select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Merge]} words`,
  [AI_TOOL_NAME.Suggestion]: `Must select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Suggestion]} words`,
};

export const REWORD_TOOL_STYLES = ['Default', 'Academic', 'Casual', 'Serious'];

export const DEFAULT_HIGHLIGHT_COLOR = 'white';

export const ESSAY_DEMO = {
  brainstormTitle: 'Brainstormer set up',
  brainstormContent: `Creatively brainstorm ideas based on your
   profile and generate detailed plans on how to build your best possible essay.`,
};
