import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect } from 'react'; // @ts-ignore
import { useLocation, useNavigate } from 'react-router-dom'; // @ts-ignore
import avatar from '../../../Assets/icons/avatar.svg'; // @ts-ignore
import demo from '../../../Assets/icons/demo.svg'; // @ts-ignore
import signout from '../../../Assets/icons/signout.svg'; // @ts-ignore
import { demoAtom } from '../../../Atoms/demoAtom';
import { notesAtom } from '../../../Atoms/notesAtom';
import { AnalyticsScreenName, Routes } from '../../../Constants/constants';
import useAuth from '../../../Hooks/Auth/useAuth';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import ProfileAvatar from '../../../lib/UI-Components/ProfileAvatar';
import ReportModal from '../../Report/ReportModal';
import AddonButton from '../Common/AddonButton'; // @ts-ignore

// @ts-ignore
import { ReactComponent as _ResourcesIcon } from '../../../Assets/images/guide.svg';
// @ts-ignore
import { ReactComponent as _NotesIcon } from '../../../Assets/icons/nav-notes.svg';
// @ts-ignore
import { ReactComponent as _ReportIcon } from '../../../Assets/icons/report.svg';
import { userAtom, userFirstNameAtom } from '../../../Atoms/App/user';
import { essayEditStateAtom } from '../../../Atoms/Essays/editorAtom';
import { getDemoSteps } from '../../TourSteps';

const NotesIcon = chakra(_NotesIcon);
const ResourcesIcon = chakra(_ResourcesIcon);
const ReportIcon = chakra(_ReportIcon);

interface ProfileAndAddonsSectionProps {
  isUserOnPublicRoute: any;
  location: any;
  setLocation: any;
  areNotesSelected: any;
  setAreNotesSelected: any;
  isGuideSelected: any;
  setIsGuideSelected: any;
  // userLocation: any;
}

const ProfileAndAddonsSection = ({
  isUserOnPublicRoute,
  location,
  setLocation,
  areNotesSelected,
  setAreNotesSelected,
  isGuideSelected,
  setIsGuideSelected,
}: // userLocation,
ProfileAndAddonsSectionProps) => {
  const user = useAtomValue(userAtom);
  const [, setDemo] = useAtom(demoAtom);
  const [notes, setNotes] = useAtom(notesAtom);
  const userFirstName = useAtomValue(userFirstNameAtom);
  const essayEditState = useAtomValue(essayEditStateAtom);
  const userLocation = useLocation();

  const { trackEvent } = useMixpanel();

  const navigate = useNavigate();

  const { logOut } = useAuth();

  const { isOpen: isReportOpen, onOpen: onReportOpen, onClose: onReportClose } = useDisclosure();

  useEffect(() => {
    if (notes?.isOpen === false) {
      setAreNotesSelected(false);
    }
  }, [notes]);

  return (
    <HStack justifyContent={'right'} alignItems='center' h='100%'>
      <ReportModal isOpen={isReportOpen} onClose={onReportClose} />
      {!isUserOnPublicRoute && (
        <HStack justifyContent={'right'} spacing={0} alignItems='center'>
          <Flex align='end' gap={[1, 1, 3, 5, 5]} className={'header-resources'}>
            {location !== 'None' && (
              <>
                <AddonButton
                  tooltipContent='Add notes to stay organized and jot down important information.'
                  onClick={() => {
                    setAreNotesSelected((prev) => !prev);
                    setNotes({
                      ...notes,
                      isOpen: !notes?.isOpen,
                      isCreateNoteView: false,
                      isEditNoteView: false,
                    });
                    trackEvent('Open Notes', {
                      screenName: AnalyticsScreenName.ScreenHeader,
                      label: 'Click on Notes icon in Header',
                    });
                  }}
                  icon={
                    <NotesIcon
                      boxSize={6}
                      _hover={{ color: 'primaryLight' }}
                      color={areNotesSelected ? 'primaryLight' : ''}
                    />
                  }
                  label={
                    <Text
                      fontSize={'sm'}
                      fontWeight={'semibold'}
                      pt='-1px'
                      color={areNotesSelected ? 'primaryLight' : ''}
                      borderBottom={areNotesSelected ? '3px solid #3E8A75' : 'none'}
                      borderRadius={areNotesSelected ? '3' : 'md'}
                      display={['none', 'none', 'flex', 'flex', 'flex']}
                    >
                      Notes
                    </Text>
                  }
                />
              </>
            )}
            <AddonButton
              tooltipContent='Access a variety of helpful resources and information in this section.'
              onClick={async () => {
                await trackEvent('Open Resources', {
                  screenName: AnalyticsScreenName.ScreenHeader,
                  label: 'Click on Resources icon in Header',
                });
                setIsGuideSelected(true);
                setLocation('');
                navigate(Routes.Resources);
              }}
              icon={<ResourcesIcon boxSize={6} color={isGuideSelected ? 'primaryLight' : ''} />}
              label={
                <Text
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                  color={isGuideSelected ? 'primaryLight' : ''}
                  borderBottom={isGuideSelected ? '3px solid #3E8A75' : 'none'}
                  borderRadius={isGuideSelected ? '3' : 'none'}
                  display={['none', 'none', 'flex', 'flex', 'flex']}
                >
                  Guide
                </Text>
              }
            />
            <AddonButton
              tooltipContent='Experiencing issues? Let us know so we can improve your experience.'
              onClick={async () => {
                trackEvent('Report Problem', {
                  screenName: AnalyticsScreenName.ScreenHeader,
                  label: 'Click on Report a Problem in Header Menu',
                });
                onReportOpen();
              }}
              icon={<ReportIcon ml={[0, 0, 3, 3, 3]} boxSize={6} />}
              label={
                <Text fontSize={'sm'} fontWeight={'semibold'} display={['none', 'none', 'flex', 'flex', 'flex']}>
                  Report
                </Text>
              }
            />
          </Flex>
          <Divider
            orientation='vertical'
            borderColor='gray2'
            borderWidth={'1px'}
            sx={{ height: '2rem' }}
            ml={4}
            display={['none', 'none', 'none', 'flex', 'flex']}
          />
          <Menu>
            <MenuButton as={Button} bg='foreground' _hover={{ cursor: 'pointer' }} pr={0} pl={[1, 1, 1, 3, 3]}>
              <HStack spacing={1} className='navbar-dropdown'>
                <ProfileAvatar username={userFirstName || ''} boxSize={10} fontSize={['md', 'xl']} />
                <ChevronLeftIcon transform='rotate(-90deg)' color={'primaryLight'} />
              </HStack>
            </MenuButton>
            <MenuList zIndex={'100'}>
              <MenuGroup>
                <MenuItem
                  onClick={async () => {
                    await trackEvent('Open Student Profile', {
                      screenName: AnalyticsScreenName.ScreenHeader,
                      label: 'Click on Profile tab in Header',
                    });
                    navigate(Routes.Profile);
                    setIsGuideSelected(false);
                  }}
                  fontSize={['sm', 'sm', 'sm', 'md', 'md']}
                  color={'primaryLight'}
                  fontWeight='medium'
                >
                  <Image src={avatar} boxSize={4} mr={1} />
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    trackEvent('View Demo', {
                      screenName: AnalyticsScreenName.ScreenHeader,
                      label: 'Click on View Demo in Header Menu',
                    });
                    const demoSteps = getDemoSteps(userFirstName || '', essayEditState === 1);
                    const demoInfo = demoSteps[userLocation.pathname];

                    setDemo((prev) => ({
                      ...prev,
                      showJoyride: true,
                      manualTrigger: true,
                      screen: userLocation.pathname,
                      steps: demoInfo?.steps || [],
                      isControlled: !demoInfo?.continuous,
                    }));
                  }}
                  fontSize={['sm', 'sm', 'sm', 'md', 'md']}
                  color={'primaryLight'}
                  fontWeight='medium'
                >
                  <Image src={demo} boxSize={4} mr={1} />
                  View Demo
                </MenuItem>
                {user?.premium_user === null && (
                  <MenuItem
                    onClick={() => {
                      trackEvent('Buy Premium', {
                        screenName: AnalyticsScreenName.ScreenHeader,
                        label: 'Click on Buy Premium in Header Menu',
                      });
                    }}
                    fontSize={['sm', 'sm', 'sm', 'md', 'md']}
                    color={'primaryLight'}
                    fontWeight='medium'
                  >
                    Buy Premium
                  </MenuItem>
                )}
                <MenuItem
                  onClick={async () => {
                    await trackEvent('Log Out', {
                      screenName: AnalyticsScreenName.ScreenHeader,
                      label: 'Click on Sign Out in Header Menu',
                    });
                    logOut();
                  }}
                  fontSize={['sm', 'sm', 'sm', 'md', 'md']}
                  color={'error'}
                  fontWeight='medium'
                >
                  <Image src={signout} boxSize={4} mr={1} />
                  Sign Out
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </HStack>
      )}
      {/* {isUserOnPublicRoute && userLocation?.pathname !== Routes.Default && (
        <Button
          backgroundColor="primary"
          color="foreground"
          borderRadius={"lg"}
          size={["sm", "md", "md", "md"]}
          fontWeight={500}
          onClick={() => {
            trackEvent("Click on Get Started", {
              screenName: AnalyticsScreenName.ScreenHeader,
              label: "Click on Get Started Button in Header",
            });
            navigate(Routes.Default);
          }}
          _hover={{
            backgroundColor: "primaryLight",
          }}
        >
          Get Started
        </Button>
      )} */}
    </HStack>
  );
};

export default ProfileAndAddonsSection;
