/* eslint-disable consistent-return */

import axios from 'axios';

class Planner {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async createPlan() {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/planner/create-plan`, null, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching tasks', error);
      throw error;
    }
  }

  async getTasks() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/planner/tasks`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching tasks', error);
      throw error;
    }
  }

  async getUserCollegesForPlanner() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/planner/colleges`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching colleges:', error);
      throw error;
    }
  }

  async getDeadlinePreference(collegeId: number) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/college/deadlinePreference/${collegeId}`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching deadline preference', error);
      throw error;
    }
  }

  async updateDeadlinePreference(collegeId: number, data: any) {
    try {
      const response = await axios.patch(`${this.apiBaseUrl}/college/deadlinePreference/${collegeId}`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error updating deadline preference', error);
      throw error;
    }
  }

  async toggleTaskCompletion(taskId: number, status: boolean) {
    try {
      const response = await axios.patch(
        `${this.apiBaseUrl}/planner/tasks/${taskId}/toggle-completed`,
        { status },
        {
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error toggling task completion', error);
      throw error;
    }
  }

  async updateDeadline(taskId: number, data: any) {
    try {
      const response = await axios.patch(`${this.apiBaseUrl}/planner/tasks/${taskId}/deadline`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error updating deadline', error);
      throw error;
    }
  }

  async getCollegePrompts(collegeId: number) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/planner/college/prompts/${collegeId}`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error getting college prompts', error);
      throw error;
    }
  }

  async updatePromptStatus(data: any) {
    try {
      const response = await axios.patch(`${this.apiBaseUrl}/planner/update/status`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error updating college prompt status', error);
      throw error;
    }
  }

  async getSimilarPrompts(taskId: number) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/planner/similarPrompts/task/${taskId}`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error getting similar prompts', error);
      throw error;
    }
  }
  async getSimilarPromptsByPromptId(promptId: number) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/planner/similarPrompts/prompt/${promptId}`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error getting similar prompts', error);
      throw error;
    }
  }
}

const plannerService = new Planner(`${process.env.REACT_APP_BASE_URL}`);
export default plannerService;
