import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import React from 'react';

const CircularProgressBar = ({
  value,
  max = 100,
  size,
  color,
  thickness = '12px',
  trackColor,
  label,
  labelColor,
  fontWeight,
  fontSize = 'md',
  isShowLabel = true,
  ...props
}: any) => (
  <CircularProgress
    value={value}
    max={max}
    size={size}
    color={color}
    trackColor={trackColor}
    thickness={thickness}
    capIsRound
    {...props}
  >
    {isShowLabel ? (
      <CircularProgressLabel p={[4, 4, 4, 6]} textColor={labelColor} fontSize={fontSize} fontWeight={fontWeight}>
        {typeof label === 'string' ? `${label}` : label}
      </CircularProgressLabel>
    ) : null}
  </CircularProgress>
);

export default CircularProgressBar;
