import { ESSAY_FEATURES_TAB } from 'Constants/essay';
import { atom } from 'jotai';
import { EnvId } from 'Models/config';
import { IEssay } from '~/Models/essays';

type AITool = 'lengthen' | 'shorten' | 'reword' | 'transition' | 'merge' | 'suggestion' | null;

interface IEssayAtom {
  selectedText: string;
  selectedTextWordCount: number;
  selectedTextRange: any;
  selectedAITool: AITool;
  selectedTab: ESSAY_FEATURES_TAB | null;
  content: string;
  wordCount: number;
  aiWritingToolResponse: string[];
  isAIResponseLoading: boolean;
  file: IEssay | null;
  isDrawerOpen: boolean;
  isPromptOpen: boolean;
}

export const essayAtom = atom<IEssayAtom>({
  file: null,
  content: '',
  wordCount: 0,
  selectedText: '',
  selectedTextWordCount: 0,
  selectedTextRange: null,
  selectedAITool: null,
  selectedTab: ESSAY_FEATURES_TAB.EssayPlan,
  aiWritingToolResponse: [],
  isAIResponseLoading: false,
  isDrawerOpen: false,
  isPromptOpen: false,
});

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  essayAtom.debugLabel = 'essay';
}

interface IFormats {
  id: number;
  college_id: number;
  prompt: string;
  wordcount: string;
  isCreateNewEssayModalOpen: boolean;
}

export const isCreateNewEssayModalOpenObject = atom<IFormats>({
  id: 0,
  college_id: 1,
  prompt: `Some students have a background, 
  identity, interest, or talent that is so meaningful they believe their application
   would be incomplete without it. If this sounds like you, then please share your story.`,
  wordcount: '250 - 650',
  isCreateNewEssayModalOpen: false,
});
