import { Box, Button, chakra } from '@chakra-ui/react'; // @ts-ignore
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { NotificationDotAtom, PlannerAtom, UserCollegesAtom } from '../../../Atoms/Planner/planner';
import usePlanner from '../../../Hooks/Planner/usePlanner'; // @ts-ignore
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import { ReactComponent as _SparksIcon } from './../../../Assets/icons/spark.svg';

const SparksIcon = chakra(_SparksIcon);

const GeneratePlanButton: React.FC = () => {
  const { trackEvent } = useMixpanel();
  const [userCollegesAtom] = useAtom(UserCollegesAtom);
  const [plannerAtom] = useAtom(PlannerAtom);
  const [notificationDotAtom] = useAtom(NotificationDotAtom);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showNotificationDot, setShowNotificationDot] = useState(false);
  const [buttonText, setButtonText] = useState('Regenerate Plan');
  const [isLoading, setIsLoading] = useState(false);

  const { createPlan } = usePlanner();

  const handleButtonClick = () => {
    createPlan();
    trackEvent(buttonText === 'Generate Plan' ? 'Plan Generated' : 'Plan Regenerated');
  };

  useEffect(() => {
    plannerAtom?.tasks?.length > 0 && userCollegesAtom?.userColleges?.length > 0
      ? setButtonText('Regenerate Plan')
      : setButtonText('Generate Plan');
  }, [plannerAtom?.tasks]);

  useEffect(() => {
    if (plannerAtom?.tasks?.length === 0) {
      setShowNotificationDot(true);
    } else {
      setShowNotificationDot(notificationDotAtom);
    }
  }, [notificationDotAtom, plannerAtom]);

  useEffect(() => {
    if (userCollegesAtom?.userColleges?.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [userCollegesAtom]);

  useEffect(() => {
    if (plannerAtom?.isCreatePlanLoading || plannerAtom?.areTasksLoading || userCollegesAtom?.areUserCollegesLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [plannerAtom, userCollegesAtom]);

  return (
    <Box className='planner-generate-plan' position='relative' width='100%'>
      <Button
        width='100%'
        bg='rgba(136, 51, 255, 0.16)'
        borderColor='calendarC5'
        borderWidth='1px'
        borderStyle='solid'
        color='calendarC5'
        borderRadius='xl'
        fontSize='xs'
        fontWeight={700}
        textTransform='uppercase'
        _hover={{ bg: 'calendarC5', color: 'foreground' }}
        _disabled={{ bg: 'rgba(136, 51, 255, 0.16)', opacity: 0.5, color: 'calendarC5' }}
        gap={2}
        isDisabled={isDisabled}
        role='group'
        onClick={handleButtonClick}
      >
        {isLoading ? (
          <BeatLoader color='#8900F7' size={'10px'} />
        ) : (
          <>
            <SparksIcon
              color={'#8900F7'}
              _hover={{
                color: 'white',
              }}
              _groupHover={{ color: isDisabled ? '#8900F7' : 'white' }}
            />
            {buttonText}
          </>
        )}
      </Button>
      {showNotificationDot && !isLoading && (
        <Box
          position='absolute'
          top='-2px'
          right='-2px'
          width='12px'
          height='12px'
          bg='#8833FF'
          borderRadius='50%'
          border='2px solid #F9FAFB'
        />
      )}
    </Box>
  );
};

export default GeneratePlanButton;
