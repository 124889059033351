import { NotificationDotAtom, PlannerAtom, SelectedTaskDetails, UserCollegesAtom } from 'Atoms/Planner/planner';
import { alertAtom } from 'Atoms/alertAtom';
import { ALERT_TYPES } from 'Constants/constants';
import { TASK_COMPLETION_STATES } from 'Constants/planner';
import collegeService from 'Services/colleges';
import plannerService from 'Services/planner';
import { useAtom } from 'jotai';
import { useCallback } from 'react';

const usePlanner = () => {
  const [plannerStore, setPlannerAtom] = useAtom(PlannerAtom);
  const [userCollegesAtom, setUserCollegesAtom] = useAtom(UserCollegesAtom);
  const [, setSelectedTaskDetails] = useAtom(SelectedTaskDetails);
  const [, setNotificationDot] = useAtom(NotificationDotAtom);
  const [, setAlert] = useAtom(alertAtom);

  const onSuccess = useCallback(
    (message: string) => {
      setAlert({
        isVisible: true,
        type: ALERT_TYPES.SUCCESS,
        message,
      });
    },
    [setAlert]
  );

  const onError = useCallback(
    (message: string) => {
      setAlert({
        isVisible: true,
        type: ALERT_TYPES.ERROR,
        message,
      });
    },
    [setAlert]
  );

  const getCollegesForPlanner = useCallback(() => {
    setUserCollegesAtom({
      ...userCollegesAtom,
      areUserCollegesLoading: true,
    });
    plannerService
      .getUserCollegesForPlanner()
      .then((colleges) => {
        setUserCollegesAtom({
          ...userCollegesAtom,
          userColleges: colleges?.sort((a: any, b: any) => a.priority - b.priority),
          areUserCollegesLoading: false,
        });
      })
      .catch((error) => {
        setUserCollegesAtom({
          ...userCollegesAtom,
          areUserCollegesLoading: false,
        });
        console.error(error);
      });
  }, [setUserCollegesAtom, userCollegesAtom]);

  const getTasks = useCallback(() => {
    setPlannerAtom({ ...plannerStore, areTasksLoading: true });
    plannerService.getTasks().then((response) => {
      if (response?.tasks?.length > 0) {
        setPlannerAtom({ ...plannerStore, tasks: response?.tasks, areTasksLoading: false });
      } else {
        setPlannerAtom({ ...plannerStore, tasks: [], areTasksLoading: false });
      }
    });
  }, [plannerStore, setPlannerAtom]);

  const createPlan = useCallback(() => {
    plannerService
      .createPlan()
      .then(() => {
        getTasks();
        onSuccess('Plan generated successfully');
        setNotificationDot(false);
      })
      .catch((err) => {
        console.error('Error creating plan', err);
        onError('Plan generation failed');
      });
  }, [getTasks, onError, onSuccess]);

  const toggleTaskCompletion = useCallback(
    (taskId: number, status: boolean) => {
      plannerService
        .toggleTaskCompletion(taskId, status)
        .then(() => {
          const updatedTasks = plannerStore?.tasks?.map((task: any) => {
            if (task?.id === taskId) {
              return {
                ...task,
                completion_state: status ? TASK_COMPLETION_STATES.COMPLETED : TASK_COMPLETION_STATES.NOT_COMPLETED,
              };
            }

            return task;
          });

          setPlannerAtom({
            ...plannerStore,
            tasks: updatedTasks,
          });

          onSuccess('Task completion status updated successfully');
        })
        .catch(() => onError('Error updating task completion status'));
    },
    [onError, onSuccess, plannerStore, setPlannerAtom]
  );

  const updateDeadline = useCallback((taskItemInfo: any, value: { toISOString: () => any }) => {
    plannerService
      .updateDeadline(taskItemInfo?.id, {
        deadline: value.toISOString(),
      })
      .then(() => {
        getTasks();
        onSuccess('Deadline updated successfully');
      })
      .catch(() => onError('Error updating deadline.'));
  }, []);

  const updatePriority = useCallback(
    (updatedCards: any) => {
      const priorities = updatedCards.map((card: any) => ({
        user_college_id: card.user_college_id,
        priority: card.priority,
      }));

      collegeService
        ?.updatePriorityAll({ priorities: priorities })
        .then(() => {
          setNotificationDot(true);
          onSuccess('Priorities updated successfully');
        })
        .catch((error) => {
          onError('Error updating priorities');
          console.error('Error updating priorities', error);
        });
    },
    [onError, onSuccess, setNotificationDot]
  );

  const getDeadlinePreferenceType = useCallback((selectedCollege: any, callback: (val: string) => void) => {
    plannerService
      .getDeadlinePreference(selectedCollege?.college_id)
      .then((response) => {
        callback(response?.deadline_preference);
      })
      .catch((error) => console.error(error));
  }, []);

  const updatePreferredDeadlineType = useCallback(
    (selectedCollege: any, val: string) => {
      plannerService
        .updateDeadlinePreference(selectedCollege?.college_id, {
          deadline_preference: val,
        })
        .then(() => {
          setNotificationDot(true);
          getCollegesForPlanner();
          onSuccess('Deadline preference updated successfully');
        })
        .catch(() => onError('Error updating deadline preference'));
    },
    [onError, onSuccess, setNotificationDot, getCollegesForPlanner]
  );

  const getCollegePrompts = useCallback((selectedCollege: any, callback: (val: any[]) => void) => {
    plannerService
      .getCollegePrompts(selectedCollege?.college_id)
      .then((response) => {
        callback(response?.supplements);
      })
      .catch((error) => {
        callback([]);
        console.error(error);
      });
  }, []);

  const updatePromptStatus = useCallback(
    (promptId: number, updatedStatus: any, planId: number, position: number) => {
      plannerService
        .updatePromptStatus({
          supplementId: promptId,
          completionStatus: updatedStatus,
          planId,
          position,
        })
        .then(() => {
          getCollegesForPlanner();
          onSuccess('Status updated successfully');
        })
        .catch((error) => {
          onError('Status update failed');
          console.error(error);
        });
    },
    [getCollegesForPlanner, onError, onSuccess]
  );

  const getSimilarPrompts = useCallback(
    (taskItemInfo: any) => {
      plannerService
        .getSimilarPrompts(taskItemInfo?.id)
        .then((response) => {
          setSelectedTaskDetails(response);
          setPlannerAtom({
            ...plannerStore,
            isTaskDrawerLoading: false,
            selectedTask: taskItemInfo,
          });
        })
        .catch((error) => {
          setPlannerAtom({
            ...plannerStore,
            isTaskDrawerLoading: false,
          });
          console.error(error);
        });
    },
    [plannerStore, setPlannerAtom]
  );

  const getSimilarPromptsByPromptId = useCallback(
    (prompt: any) => {
      plannerService
        .getSimilarPromptsByPromptId(prompt?.id)
        .then((response) => {
          setSelectedTaskDetails(response);
          setPlannerAtom({
            ...plannerStore,
            isTaskDrawerLoading: false,
          });
        })
        .catch((error) => {
          console.error(error);
          setPlannerAtom({
            ...plannerStore,
            isTaskDrawerLoading: false,
          });
        });
    },
    [plannerStore, setPlannerAtom]
  );

  return {
    getCollegesForPlanner,
    getTasks,
    createPlan,
    updateDeadline,
    updatePriority,
    getCollegePrompts,
    updatePromptStatus,
    getDeadlinePreferenceType,
    updatePreferredDeadlineType,
    getSimilarPrompts,
    getSimilarPromptsByPromptId,
    toggleTaskCompletion,
  };
};

export default usePlanner;
