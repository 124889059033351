import { Box, Button, Image, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useAtom, useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { alertAtom } from '../../Atoms/alertAtom';
import { ALERT_TYPES, getScreenNameFromRoute } from '../../Constants/constants';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import SelectFieldV2 from '../Form/SelectFieldV2';
import TextFieldArea from '../Form/TextFieldArea';
import reportService from './../../Services/report';
// @ts-ignore
import { userAtom } from '../../Atoms/App/user';
import reportProblemImage from './../../Assets/images/report-problem.svg';

const Report = ({ college, onClose }: any) => {
  const user = useAtomValue(userAtom);
  const [error] = useState(null);
  const [, setAlert] = useAtom(alertAtom);
  const { trackEvent } = useMixpanel();
  const location = useLocation();
  return (
    <Formik
      initialValues={{
        type: '',
        description: '',
      }}
      onSubmit={(values, actions) => {
        onClose();
        actions.resetForm();
        reportService
          .reportProblem({
            ...values,
            email: user?.email,
            name: user?.firstname + ' ' + user?.lastname,
            location: window.location.href,
            college: college ? college : null,
          })
          .then((data) => {
            if (data.status === 'Error') {
              setAlert({
                isVisible: true,
                message: 'Report Not Submitted',
                type: ALERT_TYPES.ERROR,
              });
            } else if (data.status === 'Success') {
              setAlert({
                isVisible: true,
                message: 'Report Submitted',
                type: ALERT_TYPES.SUCCESS,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            return;
          });
      }}
    >
      <VStack as={Form} w={'100% '} color={'gray5'} spacing={4} pb={4} px={2}>
        <Image src={reportProblemImage} />
        <VStack w={'100% '} spacing={1}>
          <Text color='gray5' textAlign='left' w='100%' fontSize={'md'}>
            Type of problem
            <Text as='span' ml={1} color='red'>
              *
            </Text>
          </Text>
          <Text as='p' color='red.500'>
            {error}
          </Text>
          <SelectFieldV2
            name='type'
            emptyLabel='Select a type...'
            options={['General Feedback', 'Wrong Data', 'Broken Functionality']}
            isRequired
            borderRadius={'xl'}
          />
        </VStack>
        <VStack w={'100%'} spacing={1}>
          <Text color='gray.500' textAlign='left' w='100%' fontSize={'md'}>
            Description
          </Text>
          <Box w={'100%'}>
            <TextFieldArea name='description' placeholder='Enter description' autoComplete='off' flexDir='column' />
          </Box>
        </VStack>
        <Button
          w={'100%'}
          variant={'primaryWithoutShadow'}
          textTransform={'uppercase'}
          fontSize={'sm'}
          fontWeight={'bold'}
          type='submit'
          onClick={() =>
            trackEvent('Submit Report Form', {
              screenName: getScreenNameFromRoute(location.pathname),
              label: 'Click on Submit Button in Report a Problem Modal',
            })
          }
        >
          Submit
        </Button>
      </VStack>
    </Formik>
  );
};

export default Report;
