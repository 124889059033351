import { Button, FormControl, Stack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { userIdAtom } from '../../../Atoms/App/user';
import { AnalyticsScreenName } from '../../../Constants/constants';
import { updatePasswordSchema } from '../../../Constants/profile';
import useAuth from '../../../Hooks/Auth/useAuth';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import CustomInput from '../../../lib/UI-Components/Input';

const UpdatePassword = () => {
  const { updatePassword } = useAuth();
  const userId = useAtomValue(userIdAtom);
  const { trackEvent } = useMixpanel();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const onSubmit = (data) => {
    trackEvent('Update Password', {
      screenName: AnalyticsScreenName.ScreenProfile,
      label: 'Click on Update Password button on Edit Profile',
    });
    updatePassword(
      {
        id: userId,
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
      reset
    );
  };

  return (
    <VStack
      alignItems='flex-start'
      w='100%'
      borderWidth='1px'
      borderRadius={['xl', 'xl']}
      bg='foreground'
      padding={6}
      spacing={8}
    >
      <VStack width={'100%'} alignItems='flex-start'>
        <Text color='primary' fontSize='xl' fontWeight='bold'>
          Current Password
        </Text>
        <FormControl isInvalid={!!errors.currentPassword}>
          <Controller
            name='currentPassword'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <CustomInput
                id='currentPassword'
                type='password'
                isError={!!errors.currentPassword?.message}
                isFormDirty={!!errors.currentPassword?.message}
                w={['100%', '100%', ' 200px', '350px']}
                placeholder='Enter Current Password'
                showLabel
                errorMsg={errors.currentPassword?.message}
                label='Current Password'
                {...field}
              />
            )}
          />
        </FormControl>
      </VStack>
      <VStack width={'100%'} alignItems='flex-start'>
        <Text color='primary' fontSize='xl' fontWeight='bold'>
          New Password
        </Text>
        <Stack
          flexDirection={['column', 'row']}
          width={'100%'}
          justifyContent={['center', 'flex-start']}
          spacing={[4, 4, 1, 4]}
        >
          <FormControl w={['initial', 'initial', 'initial', 'fit-content']}>
            <Controller
              name='newPassword'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <CustomInput
                  id='newPassword'
                  type='password'
                  isError={!!errors?.newPassword?.message}
                  w={['100%', '100%', ' 200px', '350px']}
                  isFormDirty={!!errors?.newPassword?.message}
                  placeholder='Enter New Password'
                  showLabel
                  errorMsg={errors?.newPassword?.message}
                  label='New Password'
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name='confirmPassword'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <CustomInput
                  id='confirmPassword'
                  type='password'
                  isError={!!errors.confirmPassword?.message}
                  isFormDirty={!!errors.confirmPassword?.message}
                  placeholder='Confirm New Password'
                  showLabel
                  w={['100%', '100%', ' 200px', '350px']}
                  errorMsg={errors.confirmPassword?.message}
                  label='Current Password'
                  {...field}
                />
              )}
            />
          </FormControl>
        </Stack>
      </VStack>
      <Button variant={'primaryWithoutShadow'} size={'lg'} onClick={handleSubmit(onSubmit)}>
        Update Password
      </Button>
    </VStack>
  );
};

export default UpdatePassword;
