import { College } from 'college';
import { atom } from 'jotai';
import { EnvId } from 'Models/config';
import { Task } from 'planner';

interface IPlannerStore {
  isTaskDrawerOpen: boolean;
  tasks: Task[];
  selectedTask: Task | null;
  areTasksLoading: boolean;
  isCreatePlanLoading: boolean;
  isTaskDrawerLoading: boolean;
}

interface IUserColleges {
  userColleges: College[];
  areUserCollegesLoading: boolean;
}

export const PlannerAtom = atom<IPlannerStore>({
  isTaskDrawerOpen: false,
  selectedTask: null,
  tasks: [],
  areTasksLoading: false,
  isCreatePlanLoading: false,
  isTaskDrawerLoading: false,
});

export const UserCollegesAtom = atom<IUserColleges>({
  userColleges: [],
  areUserCollegesLoading: false,
});

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  UserCollegesAtom.debugLabel = 'userColleges';
}

export const NotificationDotAtom = atom<boolean>(false);

export const SelectedTaskDetails = atom<any>({});
