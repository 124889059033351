import { IconButton, Tooltip, chakra } from '@chakra-ui/react'; // @ts-ignore
import React from 'react';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import { ReactComponent as _CalendarIcon } from './../../../Assets/icons/planner-calendar.svg';

const CalendarIcon = chakra(_CalendarIcon);

interface CustomInputProps {
  value?: string | Date;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  taskName?: string;
}

const CalendarButton = React.forwardRef<HTMLButtonElement, CustomInputProps>(({ value, onClick, taskName }, ref) => {
  const { trackEvent } = useMixpanel();
  return (
    <Tooltip label='Change deadline date'>
      <IconButton
        className='custom-input'
        key={`calendar-button-${value}`}
        ref={ref}
        aria-label='calendar icon'
        background={'transparent'}
        _hover={{ background: 'gray2' }}
        icon={<CalendarIcon />}
        onClick={(e) => {
          e.stopPropagation();
          trackEvent('Task Calendar Opened', {
            taskName: taskName,
          });
          onClick && onClick(e);
        }}
      />
    </Tooltip>
  );
});

export default CalendarButton;
