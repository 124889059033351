import React from "react";
import { VStack } from "@chakra-ui/react";
import ShortListedColleges from "./ShortListedColleges";
import GeneratePlanButton from "./GeneratePlanButton";

const SidePanel: React.FC = () => (
  <VStack spacing={4} height={"100%"} width="100%">
    <GeneratePlanButton />
    <ShortListedColleges />
  </VStack>
);

export default SidePanel;
