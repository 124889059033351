import React from "react";
import { useStyleConfig } from "@chakra-ui/react";


const CustomDivider = (props: any) => {
    const { size, variant, ...rest } = props;
    const styles = useStyleConfig("Divider", { size, variant });

    const {
        borderColor,
        borderWidth,
        borderStyle,
        ...validStyles
    } = styles;

    const modifiedStyles = {
        ...validStyles,
        bordercolor: borderColor,
        borderwidth: borderWidth,
        borderstyle: borderStyle,
    };

    return <hr {...modifiedStyles} {...rest} />;
};

export default CustomDivider;
