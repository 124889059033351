import { Spinner, Stack, VStack } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { PlannerAtom, UserCollegesAtom } from '../../../Atoms/Planner/planner';
import usePlanner from '../../../Hooks/Planner/usePlanner';
import { findClosestEvent, groupTasksByDate } from './../../../Utils/common';
import EmptyPlannerView from './EmptyPlannerView';
import NoTasksView from './NoTasksView';
import PlannerListItem from './PlannerListItem';
import TaskDrawer from './TaskDrawer/TaskDrawer';

const PlannerTaskList: React.FC = () => {
  const [plannerStore] = useAtom(PlannerAtom);
  const [tasks, setTasks] = useState<any>([]);
  const [areTasksLoading, setAreTasksLoading] = useState(false);
  const userCollegesAtom = useAtomValue(UserCollegesAtom);
  const plannerTasks = plannerStore?.tasks ?? [];

  const { getTasks } = usePlanner();

  const sortedTasks = plannerTasks.sort((a, b) => {
    const dateA = new Date(a.deadline).getTime();
    const dateB = new Date(b.deadline).getTime();
    return dateA - dateB;
  });

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    if (plannerStore?.tasks?.length > 0) {
      setTasks(findClosestEvent(groupTasksByDate(sortedTasks)));
    } else {
      setTasks([]);
    }

    setAreTasksLoading(plannerStore?.areTasksLoading);
  }, [plannerStore]);

  const emptyStateView = () => (userCollegesAtom?.userColleges?.length === 0 ? <EmptyPlannerView /> : <NoTasksView />);

  const loadedState = () => (
    <>
      {tasks?.length > 0
        ? tasks?.map((task: any) => <PlannerListItem key={task.date} plannerItemInfo={task} />)
        : emptyStateView()}
    </>
  );

  return (
    <VStack
      paddingLeft={[0, 0, 0, 4]}
      paddingRight={[0, 0, 0, 12]}
      spacing={0}
      height={['100%', '100%', '100%', '88vh']}
      overflowY={'auto'}
      width={'100%'}
      className='planner-smart-plan'
    >
      {areTasksLoading || userCollegesAtom?.areUserCollegesLoading ? (
        <Stack width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Stack>
      ) : (
        loadedState()
      )}
      <TaskDrawer />
    </VStack>
  );
};

export default PlannerTaskList;
