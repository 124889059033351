import { MinusIcon } from '@chakra-ui/icons'; // @ts-ignore
import {
  Button,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { userAtom } from '../../../Atoms/App/user';
import { NotificationDotAtom, PlannerAtom, UserCollegesAtom } from '../../../Atoms/Planner/planner';
import { alertAtom } from '../../../Atoms/alertAtom';
import { ALERT_TYPES } from '../../../Constants/constants';
import usePlanner from '../../../Hooks/Planner/usePlanner';
import WarningModal from '../../../Modals/WarningModal';
import collegeService from '../../../Services/colleges';
import { formatDate } from '../../../Utils/colleges';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import DrawerComponent from '../../../lib/UI-Components/Drawer';
import CustomModal from '../../../lib/UI-Components/Modal'; // @ts-ignore
import bean from './../../../Assets/icons/bean.svg'; // @ts-ignore
import noPrompt from './../../../Assets/images/no-prompt.svg'; // @ts-ignore
import PromptsSkeleton from './PromptsSkeleton';
import PromptStatus from './PromptsStatus';

const CollegeDraftsDetails = ({ isOpen, onClose, selectedCollege }: any) => {
  const isMobileView = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const { isOpen: isWarningOpen, onOpen: onWarningOpen, onClose: onWarningClose } = useDisclosure();

  const { trackEvent } = useMixpanel();
  const [, setAlert] = useAtom(alertAtom);
  const [userCollegesAtom, setUserCollegesAtom] = useAtom(UserCollegesAtom);
  const [, setNotificationDotAtom] = useAtom(NotificationDotAtom);
  const [plannerAtom] = useAtom(PlannerAtom);

  const [selectedDeadlineType, setSelectedDeadlineType] = useState('rd');
  const [prompts, setPrompts] = useState<any[]>([]);
  const [arePromptsLoading, setArePromptsLoading] = useState(false);
  const user = useAtomValue(userAtom);

  const generatePlanText = 'Click on Generate Plan to create task and view supplement essays for this college.';
  const noPromptText = 'There are no supplemental essays detected for this college';

  const [isConfirmedCollegeRemoval, setIsConfirmedCollegeRemoval] = useState(false);

  const { getDeadlinePreferenceType, updatePreferredDeadlineType, getCollegePrompts } = usePlanner();

  useEffect(() => {
    if (isConfirmedCollegeRemoval) {
      removeCollege();
      onWarningClose();
      setTimeout(() => {
        onClose();
      }, 300);
    }
  }, [isConfirmedCollegeRemoval]);

  useEffect(() => {
    setArePromptsLoading(true);
    getDeadlinePreferenceType(selectedCollege, (val) => setSelectedDeadlineType(val));
    getCollegePrompts(selectedCollege, (supplements) => {
      setPrompts(supplements);
      setArePromptsLoading(false);
    });
  }, [selectedCollege]);

  const updateDeadlineTypeHandler = (val) => {
    setSelectedDeadlineType(val);
    updatePreferredDeadlineType(selectedCollege, val);
    trackEvent('University deadline changed', {
      universityName: selectedCollege?.name,
    });
  };

  const deadlineDetails = () => (
    <RadioGroup onChange={updateDeadlineTypeHandler} defaultValue='all' size='sm' value={selectedDeadlineType}>
      <VStack justifyContent={'space-between'} flexWrap={'wrap'} align='left'>
        {selectedCollege?.ed_deadline && (
          <Radio defaultChecked value='ed' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Early Decision:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.ed_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.rd_deadline && (
          <Radio value='rd' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Regular Decision:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.rd_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.ea_deadline && (
          <Radio value='ea' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Early Action:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.ea_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.rea_deadline && (
          <Radio value='rea' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Early Restrictive Action:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.rea_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.ed2_deadline && (
          <Radio value='ed2' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Early Decision II:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.ed2_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.ea2_deadline && (
          <Radio value='ed2' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Early Action II:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.ea2_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.roll_deadline && (
          <Radio value='ed2' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Rolling Deadline:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.roll_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
        {selectedCollege?.transfer_deadline && (
          <Radio value='ed2' colorScheme='green'>
            <HStack>
              <Text fontSize='sm' fontWeight='medium' color='gray5'>
                Transfer Deadline:
              </Text>
              <Text fontSize='sm' fontWeight='bold' color='gray5'>
                {formatDate(selectedCollege?.transfer_deadline)}
              </Text>
            </HStack>
          </Radio>
        )}
      </VStack>
    </RadioGroup>
  );

  const handleNoPromptView = () => (
    <VStack justifyContent={'space-between'} flexWrap={'wrap'} align='center'>
      <Image src={noPrompt} boxSize='299px' objectFit='scale-down' />
      <Text color={'gray5'} fontWeight={500}>
        {plannerAtom?.tasks?.length > 0 ? noPromptText : generatePlanText}
      </Text>
    </VStack>
  );

  const header = (
    <HStack justifyContent={'space-between'}>
      <Text color={'black'} fontSize={'lg'} fontWeight={'semibold'}>
        Essay Drafts
      </Text>
    </HStack>
  );

  const body = (
    <VStack gap={2}>
      <Stack
        flexDirection={['column', 'column', 'column', 'row']}
        gap={[4, 4, 4, 2]}
        w='full'
        alignItems={['flex-start', 'flex-start', 'flex-start', 'initial']}
        justifyContent='space-between'
      >
        <HStack>
          <Image
            src={selectedCollege?.image_url ? selectedCollege?.image_url : bean}
            boxSize='36px'
            bg={'background'}
            objectFit='scale-down'
            rounded='full'
          />
          <Text color={'black'} fontSize={'md'} fontWeight={'semibold'}>
            {selectedCollege?.name}
          </Text>
        </HStack>
        <VStack>{deadlineDetails()}</VStack>
      </Stack>
      <VStack w='100%' height={'50vh'} overflowY={'auto'} spacing={4} paddingX={2}>
        {arePromptsLoading ? (
          <PromptsSkeleton />
        ) : (
          <>
            {prompts?.length === 0
              ? handleNoPromptView()
              : prompts?.map((prompt) => (
                  <PromptStatus
                    key={`essay_draft_${prompt?.supplement_id}`}
                    prompt={prompt}
                    selectedCollegeName={selectedCollege?.name}
                  />
                ))}
          </>
        )}
      </VStack>
      <WarningModal isOpen={isWarningOpen} onClose={onWarningClose} setIsConfirmed={setIsConfirmedCollegeRemoval} />
    </VStack>
  );

  const removeCollege = () => {
    if (isConfirmedCollegeRemoval) {
      trackEvent('College Removed from List via Planner Pop-Up)', {
        universityName: selectedCollege?.name,
      });

      collegeService
        .removeCollege(selectedCollege?.college_id, user?.id)
        .then(() => {
          const filteredColleges = userCollegesAtom?.userColleges?.filter(
            (c) => c?.college_id !== selectedCollege?.college_id
          );

          setUserCollegesAtom({
            ...userCollegesAtom,
            userColleges: filteredColleges,
          });

          setNotificationDotAtom(true);

          setAlert({
            isVisible: true,
            type: ALERT_TYPES.SUCCESS,
            message: 'College removed successfully',
          });
          onClose();
        })
        .catch(() =>
          setAlert({
            isVisible: true,
            type: ALERT_TYPES.ERROR,
            message: 'Error removing college',
          })
        );
    }
  };

  const footer = (
    <HStack
      justifyContent='flex-end'
      w='full'
      paddingY={isMobileView ? 3 : 2}
      paddingX={2}
      {...(isMobileView && {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: 'white',
        borderTop: '1px solid #e2e8f0',
      })}
    >
      <Button variant='logOut' onClick={() => onWarningOpen()}>
        <MinusIcon mr={2} />
        Remove College from List
      </Button>
    </HStack>
  );

  return (
    <>
      {isMobileView ? (
        <DrawerComponent isSwipable heading={header} isOpen={isOpen} onClose={onClose} placement={'bottom'}>
          {body}
          {footer}
        </DrawerComponent>
      ) : (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          size={['2xl', '3xl', '4xl']}
          backdropFilter='blur(5px)'
          header={header}
          body={body}
          footer={footer}
        />
      )}
    </>
  );
};

export default CollegeDraftsDetails;
