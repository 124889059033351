import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { TASK_TYPES } from '../../../Constants/planner';
import { formatDate } from '../../../Utils/colleges';
import { getMonthName, getWeekday } from '../../../Utils/common';
import TaskListItem from './TaskListItem';

interface PlannerListItemProps {
  plannerItemInfo: any;
}

const PlannerListItem: React.FC<PlannerListItemProps> = ({ plannerItemInfo }) => {
  const scrollRef = useRef<any>(null);
  const deadline = plannerItemInfo?.tasks[0]?.deadline;
  const plannerType = plannerItemInfo?.tasks[0]?.type;
  let day;
  let month;
  let weekday;

  if (plannerType === TASK_TYPES.COLLEGE) {
    const formattedDateString = formatDate(deadline);
    const [monthAndDay] = formattedDateString.split(', ');
    const [monthPart, dayPart] = monthAndDay.split(' ');

    day = dayPart;
    month = monthPart;
    weekday = getWeekday(deadline);
  } else {
    const date = new Date(deadline);
    day = date.getDate();
    month = getMonthName(deadline);
    weekday = getWeekday(deadline);
  }

  useEffect(() => {
    if (scrollRef.current && plannerItemInfo?.isTodayOrClosestPossible) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <HStack height={'fit-content'} width={'full'} spacing={0} alignItems='stretch'>
      {plannerItemInfo?.isTodayOrClosestPossible && <div ref={scrollRef}></div>}
      <Stack
        flexDirection={['column', 'column', 'column', 'row']}
        width={'28%'}
        borderTop={'2px solid #EAECF0'}
        flex={1}
        alignItems={['center', 'center', 'center', plannerItemInfo?.tasks?.length > 1 ? 'flex-start' : 'center']}
        justifyContent={['center', 'center', 'center', 'space-evenly']}
        spacing={[0, 0, 0, 2]}
        paddingX={[0, 0, 0, 4]}
        paddingY={[0, 0, 0, plannerItemInfo?.isTodayOrClosestPossible && plannerItemInfo?.tasks?.length === 1 ? 0 : 2]}
        borderRight={'none'}
      >
        {plannerItemInfo?.isTodayOrClosestPossible ? (
          <Box
            backgroundColor={'primary'}
            borderRadius={'full'}
            width={['32px', '32px', '32px', '45px']}
            height={['32px', '32px', '32px', '45px']}
            maxHeight={'48px'}
            paddingX={[
              2,
              4,
              4,
              plannerItemInfo?.isTodayOrClosestPossible && plannerItemInfo?.tasks?.length > 1 ? 6 : 2,
            ]}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Text color={'white'} fontWeight={600} fontSize={['xs', 'xs', 'xs', 'xl']}>
              {day}
            </Text>
          </Box>
        ) : (
          <Box width={['100%', '100%', '100%', '20%']} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Text color={'gray5'} fontWeight={600} fontSize={['xs', 'xs', 'xs', 'xl']} noOfLines={1}>
              {day}
            </Text>
          </Box>
        )}
        <Stack
          width={'80%'}
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text
            textAlign={['center', 'center', 'center', 'left']}
            mt={plannerItemInfo?.isTodayOrClosestPossible && plannerItemInfo?.tasks?.length > 1 ? 4 : 1}
            color={plannerItemInfo?.isTodayOrClosestPossible ? 'primary' : 'gray5'}
            fontWeight={plannerItemInfo?.isTodayOrClosestPossible ? 700 : 600}
            fontSize={['xs', 'xs', 'xs', 'sm']}
          >
            {`${month}, ${weekday}`}
          </Text>
        </Stack>
      </Stack>
      <VStack width={'72%'} height={'100%'} spacing={0}>
        {plannerItemInfo?.tasks?.map((taskInfo) => (
          <TaskListItem taskItemInfo={taskInfo} key={`task-list-item-${taskInfo?.id}`} />
        ))}
      </VStack>
    </HStack>
  );
};

export default PlannerListItem;
