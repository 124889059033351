import { Box, Checkbox, FormControl, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { userAtom } from '../../../Atoms/App/user';
import { isErrorSavingProfileAtom, isProfileSavingAtom, profileProgressAtom } from '../../../Atoms/Profile/profile';
import { AnalyticsScreenName, ETHNICITIES, GENDERS, INCOME_LEVELS } from '../../../Constants/constants';
import { YEARS, profileValidationSchema } from '../../../Constants/profile';
import { useUpdateProfileInfo } from '../../../Hooks/Profile/useProfile';
import { COUNTRIES, STATE_NAMES, convert } from '../../../Utils/common';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import CustomInput from '../../../lib/UI-Components/Input';
import GPAConversionInfoTip from './GPAConversionInfoTip';
import ProfileDropdownSelect from './ProfileDropdownSelect';

const ProfileInfo = () => {
  const user = useAtomValue(userAtom);
  const { trackEvent } = useMixpanel();
  const profileProgress = useAtomValue(profileProgressAtom);

  const [profileEventTracked, setProfileEventTracked] = useState(false);
  const [valuesInitialized, setValuesInitialized] = useState(false);

  const {
    control,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileValidationSchema) as any,
    defaultValues: {
      id: user?.id || 0,
      country: user?.country || '',
      state: user?.state || '',
      income: user?.income || '',
      ethnicity: user?.ethnicity || '',
      gender: user?.gender || '',
      gpa: user?.gpa,
      satmath: user?.standardized_tests ? user?.satmath ?? null : null,
      satenglish: user?.standardized_tests ? user?.satenglish ?? null : null,
      act: user?.standardized_tests ? user?.act ?? null : null,
      highschool: user?.highschool || '',
      classrank: user?.classrank ?? null,
      gradyear: user?.gradyear,
      standardized_tests: user?.standardized_tests ?? false,
    },
  });

  const { mutate } = useUpdateProfileInfo();
  const setIsProfileSaving = useSetAtom(isProfileSavingAtom);
  const setIsErrorSaving = useSetAtom(isErrorSavingProfileAtom);
  const formValues = watch();
  const selectedCountry = watch('country');
  const standardizedTests = watch('standardized_tests');
  const previousValues = useRef<any>(formValues);

  const autoSave = useCallback(
    debounce((data: any) => {
      if (Object.entries(errors)?.length === 0) {
        setIsProfileSaving(true);
        mutate(data);
      } else {
        setIsProfileSaving(false);
      }
    }, 1000),
    [errors]
  );

  const onChangeNumberInput = (key: any, field: any, e: any) => {
    if (e.target.value !== '') {
      field.onChange(e);
      trigger(key);
    } else {
      field.onChange({ ...e, target: { value: null } });
    }
  };

  useEffect(() => {
    if (user && !valuesInitialized) {
      // @ts-ignore
      setValue('id', user?.id || 0); // @ts-ignore
      setValue('country', user?.country || ''); // @ts-ignore
      setValue('state', user?.state || ''); // @ts-ignore
      setValue('income', user?.income || ''); // @ts-ignore
      setValue('ethnicity', user?.ethnicity || ''); // @ts-ignore
      setValue('gender', user?.gender || ''); // @ts-ignore
      setValue('gpa', user?.gpa); // @ts-ignore
      setValue('satmath', user?.standardized_tests ? user?.satmath || null : null); // @ts-ignore
      setValue('satenglish', user?.standardized_tests ? user?.satenglish || null : null); // @ts-ignore
      setValue('act', user?.standardized_tests ? user?.act || null : null); // @ts-ignore
      setValue('highschool', user?.highschool || ''); // @ts-ignore
      setValue('classrank', user?.classrank || null); // @ts-ignore
      setValue('gradyear', user?.gradyear); // @ts-ignore
      setValue('standardized_tests', user?.standardized_tests ?? false); // @ts-ignore
      setValuesInitialized(true);
    }
  }, [user]);

  useEffect(() => {
    if (JSON.stringify(formValues) !== JSON.stringify(previousValues.current)) {
      autoSave(formValues);
      previousValues.current = formValues;
    }

    setIsErrorSaving(!(Object.entries(errors)?.length === 0));
  }, [formValues, errors]);

  useEffect(() => {
    if (profileProgress === 100 && !profileEventTracked) {
      trackEvent('Profile information is complete', {
        screenName: AnalyticsScreenName.ScreenProfile,
        label: 'Profile Completion Status is 100%',
      });
      setProfileEventTracked(true);
    }
  }, [profileProgress]);

  return (
    <form
      style={{
        width: '100%',
      }}
    >
      <VStack align='start' w='100%' borderWidth='1px' borderRadius={['xl', 'xl']} bg='foreground' padding={6}>
        <Text color='primary' fontSize='lg' fontWeight='bold'>
          Personal Information
        </Text>
        <Grid
          templateRows={['repeat(4, 1fr)', 'repeat(2, 1fr)']}
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={4}
          width={'100%'}
        >
          <GridItem rowSpan={1} colSpan={1}>
            <Controller
              name='country'
              control={control}
              render={({ field }) => (
                <ProfileDropdownSelect
                  options={convert(COUNTRIES)}
                  showLabel
                  name='Country'
                  placeholder='Select Country'
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </GridItem>
          {selectedCountry === 'United States' && (
            <GridItem rowSpan={1} colSpan={1}>
              <Controller
                name='state'
                control={control}
                render={({ field }) => (
                  <ProfileDropdownSelect
                    options={convert(STATE_NAMES)}
                    placeholder='Select State'
                    showLabel
                    name='State'
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </GridItem>
          )}

          <GridItem rowSpan={1} colSpan={1}>
            <Controller
              name='income'
              control={control}
              render={({ field }) => (
                <ProfileDropdownSelect
                  options={convert(INCOME_LEVELS)}
                  showLabel
                  placeholder='Select Parental Income Level'
                  name='Parental Income Level'
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <Controller
              name='ethnicity'
              control={control}
              render={({ field }) => (
                <ProfileDropdownSelect
                  options={convert(ETHNICITIES)}
                  showLabel
                  placeholder='Select Ethnicity'
                  name='Ethnicity'
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <Controller
              name='gender'
              control={control}
              render={({ field }) => (
                <ProfileDropdownSelect
                  options={convert(GENDERS)}
                  showLabel
                  placeholder='Select Gender'
                  name='Gender'
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </GridItem>
        </Grid>
        <HStack>
          <Text color='primary' fontSize='lg' fontWeight='bold'>
            Academics
          </Text>
          <GPAConversionInfoTip />
        </HStack>
        <Grid
          templateRows={['repeat(4, 1fr)', 'repeat(2, 1fr)']}
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={4}
          width={'100%'}
        >
          <GridItem rowSpan={1} colSpan={1}>
            <FormControl isInvalid={!!errors.gpa}>
              <Controller
                name='gpa'
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder='Weighted GPA (out of 5.0)'
                    showLabel
                    errorMsg={errors?.gpa?.message}
                    name='gpa'
                    isError={!!errors?.gpa}
                    isFormDirty={!!errors.gpa}
                    label='Weighted GPA'
                    type='number'
                    value={field?.value}
                    onChange={(e) => onChangeNumberInput('gpa', field, e)}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <FormControl isInvalid={!!errors.satmath}>
              <Controller
                name='satmath'
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder='SAT Math Score'
                    showLabel
                    errorMsg={errors?.satmath?.message}
                    name='satmath'
                    isError={!!errors?.satmath}
                    isFormDirty={!!errors.satmath}
                    label='SAT Math Score'
                    type='number'
                    value={field.value}
                    isDisabled={!standardizedTests}
                    onChange={(e) => onChangeNumberInput('satmath', field, e)}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <FormControl isInvalid={!!errors.satenglish}>
              <Controller
                name='satenglish'
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder='SAT English Score'
                    showLabel
                    errorMsg={errors?.satenglish?.message}
                    name='satenglish'
                    isError={!!errors?.satenglish}
                    isFormDirty={!!errors.satenglish}
                    label='SAT English Score'
                    type='number'
                    value={field.value}
                    isDisabled={!standardizedTests}
                    onChange={(e) => onChangeNumberInput('satenglish', field, e)}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <FormControl isInvalid={!!errors.act}>
              <Controller
                name='act'
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder='ACT Score'
                    showLabel
                    errorMsg={errors?.act?.message}
                    name='act'
                    isError={!!errors?.act}
                    isFormDirty={!!errors.act}
                    label='ACT Score'
                    type='number'
                    isDisabled={!standardizedTests}
                    value={field.value}
                    onChange={(e) => onChangeNumberInput('act', field, e)}
                  />
                )}
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Box alignSelf={['flex-start', 'flex-end']} mt={'-5'}>
          <Controller
            name='standardized_tests'
            control={control}
            render={({ field }) => (
              <Checkbox
                color='gray.500'
                isChecked={!field.value}
                onChange={(e) => {
                  // @ts-ignore
                  setValue('satmath', null);
                  // @ts-ignore
                  setValue('satenglish', null);
                  // @ts-ignore
                  setValue('act', null);

                  e.target.checked = !e.target.checked;
                  field.onChange(e);
                }}
                mt={4}
              >
                I have not taken any standardized tests
              </Checkbox>
            )}
          />
        </Box>
        <Text color='primary' fontSize='lg' fontWeight='bold'>
          High School
        </Text>
        <Grid
          templateRows={['repeat(4, 1fr)', 'repeat(2, 1fr)']}
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={4}
          width={'100%'}
        >
          <GridItem rowSpan={1} colSpan={1}>
            <FormControl isInvalid={!!errors.highschool}>
              <Controller
                name='highschool'
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder='High School Name'
                    showLabel
                    errorMsg={errors?.highschool?.message}
                    name='act'
                    isError={!!errors?.highschool}
                    isFormDirty={!!errors.highschool}
                    label='High School Name'
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      trigger('highschool');
                    }}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <FormControl isInvalid={!!errors.classrank}>
              <Controller
                name='classrank'
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder='Class Rank'
                    showLabel
                    errorMsg={errors?.classrank?.message}
                    name='classrank'
                    isError={!!errors?.classrank}
                    isFormDirty={!!errors.classrank}
                    label='Class Rank'
                    type='number'
                    value={field.value}
                    onChange={(e) => onChangeNumberInput('classrank', field, e)}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}>
            <Controller
              name='gradyear'
              control={control}
              render={({ field }) => (
                <ProfileDropdownSelect
                  options={convert(YEARS)}
                  showLabel
                  placeholder='Enter Graduation Year'
                  name='Graduation Year'
                  value={field.value ?? undefined}
                  onChange={(e) => onChangeNumberInput('gradyear', field, e)}
                />
              )}
            />
          </GridItem>
        </Grid>
      </VStack>
    </form>
  );
};

export default ProfileInfo;
